import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.css'
})
export class DeleteModalComponent {
  @Input() titleLocalisationString: string = "";

  constructor(private modal: NgbActiveModal) {
  }


  accept() {
    this.modal.close(true);
  }


  close() {
    this.modal.close(false);
  }
}
