import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AiService} from "../../../../@shared/services/ai.service";
import {
  AssistantGroupResponse,
  UserEntityRelationWithUserDataResponse
} from "../../../../@shared/models/response.module";
import {CompanyService} from "../../../../@shared/services/company.service";
import {_COR_AII_21, _COR_AII_23} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrl: './share.component.css'
})
export class ShareComponent {
  @Input() assistantGroup: AssistantGroupResponse;
  options: UserEntityRelationWithUserDataResponse[] = [];
  searchTerm: string = '';
  selectedOption: UserEntityRelationWithUserDataResponse | null
  usersWithRelation: UserEntityRelationWithUserDataResponse[] = [];
  selectedAccessLevel: number = 99;
  constructor(private modal: NgbActiveModal,
              private aiService: AiService,
              private companyService: CompanyService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.companyService.getCompanyEmployees().subscribe((data) => {
      this.options = data;
      for (let option of this.options) {
        option.full_name = option.first_name + ' ' + option.last_name;
      }
      for (let relation of this.assistantGroup.access_relations) {
        this.usersWithRelation.push(this.getUserData(relation.user_id)!)
      }
    })
  }

  filteredOptions(): UserEntityRelationWithUserDataResponse[] {
    return this.options.filter(option =>
      option.full_name!.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectOption(option: UserEntityRelationWithUserDataResponse): void {
    this.selectedOption = option;
    this.searchTerm = ''; // Clear search term after selection
  }

  getUserData(user_id: string){
    return this.options.find((option) => option.user_id == user_id)
  }

  addRelation() {
    if(this.selectedAccessLevel != 99 && this.selectedOption){
      let payload: _COR_AII_21 = {
        assistant_group_id: this.assistantGroup.assistant_group_id,
        user_id: this.selectedOption.user_id,
        access_level_id: this.selectedAccessLevel
      }
      this.aiService.addAssistantGroupAccessRelation(payload).subscribe((data) => {
        this.assistantGroup.access_relations.push(data);
        this.selectedOption = null;
        this.selectedAccessLevel = 99;
        this.toastService.showSuccess('toast.assistantDetails.share.success', 'toast.success.title.success')
      })
    }
  }

  removeRelation(relation_id: number) {
    let payload: _COR_AII_23 = {
      relation_id: relation_id
    }
    this.aiService.deleteAssistantGroupAccessRelation(payload).subscribe((data) => {
      this.assistantGroup.access_relations = this.assistantGroup.access_relations.filter((relation) => relation.relation_id != relation_id);
      this.toastService.showSuccess('toast.assistantDetails.share.remove', 'toast.success.title.success')
    })
  }
  close() {
    this.modal.close();
  }
}
