<div class="home-main">
  <div class="row content-main d-flex justify-content-center align-items-center">
    <div class="col-xl-12 col-sm-12 d-flex justify-content-center">
      <div class="container-1">
        <div class="row">
          <div class="col-xl-12 col-sm-12 d-flex logo-page">
            <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 200px">
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-sm-12 d-flex align-items-center justify-content-start">
            <h4 class="text-center mb-0 ms-3" style="color: #707070">{{ "home.subHeader" | translate }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row feature-list d-flex align-items-center justify-content-center">
    <div class="col-xl-4 col-sm-12">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box1.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 450px">{{ "home.box1.description" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12 with-border">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box2.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 450px">{{ "home.box2.description" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box3.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 450px">{{ "home.box3.description" | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
