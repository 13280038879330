import {Component, OnInit} from '@angular/core';
import {TemplateResponse} from "../../@shared/models/response.module";
import {TemplateService} from "../../@shared/services/template.service";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateTemplateComponent} from "./components/create-template/create-template.component";
import {EditTemplateComponent} from "./components/edit-template/edit-template.component";
import {_COR_TMP_6, _COR_TMP_8} from "../../@shared/models/input.module";
import {BehaviorSubject, Observable} from "rxjs";
import Popper from "popper.js";
import Behavior = Popper.Behavior;
import {distinctUntilChanged} from "rxjs/operators";
import {AccessTokenDecodeModel} from "../../@core/services/auth.service";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {ToastService} from "../../@core/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateTemplateAiComponent} from "./components/create-template-ai/create-template-ai.component";

@Component({
  selector: 'app-question-templates',
  templateUrl: './question-templates.component.html',
  styleUrl: './question-templates.component.css'
})
export class QuestionTemplatesComponent implements OnInit{
  templates: TemplateResponse[] = [];
  loading: boolean = false;
  searchForm: FormGroup;
  page: number = 1;
  limit: number = 8;
  totalItems: number = 0; // This should be fetched from the backend if available
  totalPages: number = 0; // This should be fetched from the backend if available
  searchString: string = '';
  search: boolean = false;
  displayedPages: (number | string)[] = [];



  constructor(private templateService: TemplateService,
              private modal: NgbModal,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.searchForm = new FormGroup({
      searchString: new FormControl('', [Validators.maxLength(200)])
    })
    this.getAllTemplates();
    this.templateService.templatesUpdated.subscribe((res ) => {
      if (res == true) {
        let payload: _COR_TMP_6 = {
          paginate: 1,
          page: this.page,
          limit: this.limit,
          search_string: this.searchString,
          search_string_columns: ["template_name"],
        }
        this.templateService.getAllTemplates(payload).subscribe((templates) => {
          this.templates = templates.data;
        });
        this.templateService.templatesUpdatedSubject.next(false);
      }
    })
  }

  getAllTemplates() {
    this.loading = true;
    let payload: _COR_TMP_6 = {
      paginate: 1,
      page: this.page,
      limit: this.limit,
      search_string: this.searchString,
      search_string_columns: ["template_name"],
    }
    this.templateService.getAllTemplates(payload).subscribe((templates) => {
      this.templates = templates.data;
      this.page = templates.page;
      this.totalPages = templates.total_pages;
      this.loading = false;
      this.updatePagination();
    });
  }

  createNewTemplate() {
    let modalRef = this.modal.open(CreateTemplateComponent, {size: 'lg', centered: true, keyboard: false})
    modalRef.result.then((result) => {
      if (result) {
        let modalRef = this.modal.open(EditTemplateComponent, {size: 'lg', centered: true, keyboard: false})
        modalRef.componentInstance.template = result;
      }
    })
  }

  createNewTemplateWithAi() {
    let modalRef = this.modal.open(CreateTemplateAiComponent, {size: 'lg', centered: true, keyboard: false})
    modalRef.result.then((result) => {
      if (result) {
        let modalRef = this.modal.open(EditTemplateComponent, {size: 'lg', centered: true, keyboard: false})
        modalRef.componentInstance.template = result;
      }
    })
  }

  editTemplate(template: TemplateResponse) {
    let modalRef = this.modal.open(EditTemplateComponent, {size: 'lg', centered: true, keyboard: false})
    modalRef.componentInstance.template = template;
  }

  deleteTemplate(template: TemplateResponse) {
    let modalRef = this.modal.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "templates.deleteTemplate.title";
    modalRef.result.then((result) => {
      if (result == true) {
        let params: _COR_TMP_8 = {
          template_id: template.template_id
        }
        this.templateService.deleteTemplate(params).subscribe((res) => {
          if (res) {
            this.templateService.templatesUpdatedSubject.next(true);
            this.toastService.showSuccess('toast.templateDeleted.success', 'toast.success.title.success');
          }
        })
      }
    })
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.getAllTemplates();
  }

  onSearch() {
    this.searchString = this.searchForm.get('searchString')!.value;
    this.getAllTemplates();
  }

  toggelSearch() {
    this.search = !this.search;
    if (!this.search) {
      this.searchString = '';
      this.searchForm.patchValue({searchString: ''});
      this.getAllTemplates();
    }
  }


  updatePagination(): void {
    if (this.totalPages <= 6) {
      this.displayedPages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      this.displayedPages = this.getCondensedPagination();
    }
  }

  getCondensedPagination(): (number | string)[] {
    const firstPages = [1, 2, 3];  // First few pages
    const lastPages = [this.totalPages - 2, this.totalPages - 1, this.totalPages];  // Last few pages

    if (this.totalPages <= 6) {
      // If there are fewer than or equal to 6 pages, show all of them
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    if (this.page <= 3) {
      // If you're on the first few pages, show 1, 2, 3, ... and last pages
      return [...firstPages, '...', ...lastPages];
    } else if (this.page >= this.totalPages - 2) {
      // If you're on the last few pages, show first pages, ..., and last pages
      return [...firstPages, '...', ...lastPages];
    } else {
      // If you're in the middle (like page 3), show a range around the current page
      return [
        this.page - 1,
        this.page,
        this.page + 1,
        '...',
        this.totalPages - 2,
        this.totalPages - 1,
        this.totalPages,
      ];
    }
  }

  selectPage(page: number | string): void {
    if (page === '...') {
      // If the ellipsis is clicked, move to the next or previous page range
      if (this.page < this.totalPages / 2) {
        // Clicking on the first ellipsis: move right by 1 page
        this.page = Math.min(this.page + 1, this.totalPages - 1);
      } else {
        // Clicking on the second ellipsis: move left by 1 page
        this.page = Math.max(this.page - 1, 4);
      }
    } else if (typeof page === 'number') {
      this.page = page;
    }

    this.updatePagination();
    this.onPageChange(this.page); // Notify the change of page
  }


  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
