<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.createAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="createAssistantForm">
      <div class="row">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.assistantName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="assistant_name"
              placeholder="{{'assistants.createAssistant.assistantName' | translate}}"
            >
          </div>
          <span *ngIf="createAssistantForm.get('assistant_name')?.invalid && createAssistantForm.get('assistant_name')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.reference.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="reference"
              placeholder="{{'assistants.createAssistant.reference.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.description.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="assistant_group_description"
              placeholder="{{'assistants.createAssistant.description.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold" for="flexSwitchCheckDefault">{{ "assistants.createAssistant.private.label" | translate }}</label>
          </div>
        </div>
      </div>

      <div class="row" style="padding-top: 40px">
        <div class="d-flex justify-content-center">
          <label class="pb-1" style="font-weight: bold">{{"assistants.createAssistant.file.label" | translate}}</label>
        </div>
        <div class="col-8 dropzone">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
          <i class="fa fa-regular fa-upload fa-2x pb-2"></i>
          <h3>Drag and drop files here</h3>
          <h3>or</h3>
          <label for="fileDropRef">Browse for files</label>
        </div>
        <div class="row pt-3" *ngFor="let file of selectedFiles">
          <div class="col-12 d-flex ms-5 align-items-center">
            <i class="fa fa-file-pdf fa-2x"></i>
            <h5 class="m-0">{{file.name}}</h5>
          </div>
        </div>
      </div>
<!--      <div class="row pt-3">-->
<!--        <div class="col-8">-->
<!--          <label class="pb-1" style="font-weight: bold">{{"assistants.createAssistant.file.label" | translate}}</label>-->
<!--          <input type="file" (change)="onFileSelected($event)" accept="application/pdf" />-->
<!--        </div>-->
<!--      </div>-->
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createAssistant()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>

