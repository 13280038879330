import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  CitationResponse,
  GroupedCitation,
  ReportResponse,
  ThreadResponse
} from "../../../../@shared/models/response.module";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {formatTimeDMYHHMM, getInitials} from "../../../../@core/utils/utils.service";
import {_COR_AII_5} from "../../../../@shared/models/input.module";
import {AiService} from "../../../../@shared/services/ai.service";

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrl: './view-report.component.css'
})
export class ViewReportComponent {
  @Input() report: ReportResponse;
  loading: boolean = false;
  constructor(private modal: NgbActiveModal,
              private aiService: AiService) { }
  thread: ThreadResponse;

  ngOnInit(): void {
    this.getThread();
  }

  groupCitationsByFileId(citations: CitationResponse[]): GroupedCitation[] {
    const groupedCitations: { [key: string]: GroupedCitation } = {};

    citations.forEach((citation: CitationResponse) => {
      if (!groupedCitations[citation.cited_file_id]) {
        groupedCitations[citation.cited_file_id] = {
          cited_file_id: citation.cited_file_id,
          cited_file_name: citation.cited_file_name,
          indexes: []  // Initialize an empty array for indexes
        };
      }
      // Push citation indexes for the same file
      groupedCitations[citation.cited_file_id].indexes.push(citation.index);
    });

    // Convert the object to an array
    return Object.values(groupedCitations);
  }

  generatePDF() {
    this.loading = true;
    const element = document.getElementById('pdf-content');
    if (!element) return;

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 10; // initial Y position
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${this.report.report_name}.pdf`);
      this.loading = false;
    });
  }

  close() {
    this.modal.close();
  }


  getThread() {
    let params: _COR_AII_5 = {
      thread_id: this.report.thread_id,
      qna: 0,
      report: 1
    };
    this.aiService.getThreads(params).subscribe((response) => {
      this.thread = response.data[0];
      this.thread.messages.forEach((message) => {
        if (message && message.citations) {
          // Group citations for each message
          message.groupedCitations = this.groupCitationsByFileId(message.citations);
        }
      });
    });
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;

}
