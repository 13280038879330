<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"profile.changePassword.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend urna erat, nec maximus arcu lobortis sed. Duis in lorem gravida, aliquam nisi a, molestie ex. Quisque congue quam sit amet vehicula dapibus. </p>
      </div>
    </div>
    <form [formGroup]="passwordForm" (ngSubmit)="submit()">
      <div class="row spacing-30">
        <div class="col-8">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="oldPassword">{{"profile.changePassword.currentPassword" | translate}}</label>
            <input type="password" class="form-control input-class w-100" id="oldPassword" formControlName="oldPassword">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="newPassword">{{"profile.changePassword.newPassword" | translate}}</label>
            <input type="password" class="form-control input-class w-100" id="newPassword" formControlName="newPassword">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="confirmPassword">{{"profile.changePassword.confirmPassword" | translate}}</label>
            <input type="password" class="form-control input-class w-100" id="confirmPassword" formControlName="confirmPassword">
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end spacing-30">
        <div class="col-12">
          <button type="submit" class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">{{"profile.changePassword.submit" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
