<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"companySettings.wordBank.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-20" *ngFor="let word of wordBank; let index = index">
      <div class="test p-2" style="border: 1px solid #495055; border-radius: 8px">
        <div class="row cursor-pointer" [class.spacing-30]="isSecondRowVisible">
          <div class="col-6 d-flex align-items-center">
            <div class="w-100">
              <label class="f-16 f-bold label-class spacing-15" *ngIf="expandedIndex === index">{{'companySettings.wordBank.word.label' | translate}}</label>
              <input
                class="form-control flex-grow-1 input-class"
                type="text"
                placeholder="{{'companySettings.wordBank.word.placeholder' | translate}}"
                [(ngModel)]="word.word"
                (change)="onChange()"
                [title]="word.word"
              >
            </div>
          </div>
          <!-- Add an expand/collapse icon in the first row -->
          <div class="col-6 d-flex align-items-center justify-content-end" (click)="toggleSecondRow(index)" >
            <i
              class="fa"
              [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
              style="cursor: pointer; color: white">
            </i>
          </div>
        </div>

        <div class="row ">
          <div class="col-11">
            <div class="row spacing-15 pt-2" *ngIf="expandedIndex === index">
              <label class="f-16 f-bold label-class spacing-15">{{'companySettings.wordBank.wordExplanation.label' | translate}}</label>
              <div class="col-11">
                <textarea
                  class="form-control input-class-large"
                  rows="5"
                  placeholder="{{'companySettings.wordBank.wordExplanation.Placeholder' | translate}}"
                  [(ngModel)]="word.explanation"
                  (change)="onChange()"
                  [title]="word.explanation">
                </textarea>
              </div>
            </div>
          </div>
          <div class="col-1 d-flex align-items-center" *ngIf="expandedIndex === index">
            <div class="col-1 d-flex align-items-center">
              <i class="fa-regular fa-bucket cursor-pointer" (click)="deleteWordFromWordBank(word)"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fa fa-plus me-2" style="color: #ED622E"></i>
      <div class="btn ps-0" style="color: #ED622E" (click)="addNewWord()" >{{"companySettings.wordBank.addWordBtn" | translate }}</div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="saveWordBank()">
      {{ "companySettings.wordBank.saveBtn" | translate }}
    </div>
  </div>
</div>
