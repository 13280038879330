<div class="modal-content" style="max-height: 1000px; overflow: auto">
  <div class="modal-header p-0" style="border-bottom: none">
    <div
      class="d-flex justify-content-between w-100 spacing-30"
      style="margin-top: 35px; margin-left: 35px"
    >
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">
        Report - {{ report.report_name }}
      </h4>
      <div>
        <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="generatePDF()">
          <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Export to Pdf
        </div>
        <div class="btn" (click)="close()">
          <i class="fa fa-x" style="color: #909699"></i>
        </div>
      </div>
    </div>
  </div>
  <div id="pdf-content" class="modal-body spacing-30" style="margin-left: 16px; margin-right: 16px; background-color: white; border-radius: 8px" *ngIf="thread">
    <h1>{{report.report_name}}</h1>
    <p style="color: #6a6a6a"><strong>Assistant:</strong> {{ report.assistant.assistant_name }} - {{report.assistant.assistant_type_name}}</p>
    <p style="color: #6a6a6a"><strong>Question Set:</strong> {{ report.template_name }}</p>
    <p style="margin-bottom: 60px; color: #6a6a6a"><strong>Created At:</strong> {{ formatTimeDMYHHMM(report.created_at)}}</p>

    <div *ngFor="let message of thread.messages; let i = index" class="message-container" style="margin-bottom: 30px;">
      <!-- User Message -->
      <div *ngIf="message.role == 'user'" style="margin-bottom: 10px;">
        <h3>{{ message.content }}</h3>
      </div>

      <!-- Assistant Message -->
      <div *ngIf="message.role == 'assistant'" style="margin-bottom: 40px;">
        <markdown class="" data="{{ message.content }}"></markdown>
        <div *ngFor="let citation of message.groupedCitations">
          <a class="cursor-pointer" style="color: #909699; text-decoration: none">
            [{{ citation.indexes.join('] [') }}] {{ citation.cited_file_name }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 32px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="generatePDF()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Export to Pdf
    </div>
  </div>
</div>
