import {Component, ElementRef, ViewChild} from '@angular/core';
import jsPDF from "jspdf";
import Quill from "quill";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrl: './create-document.component.css'
})
export class CreateDocumentComponent {
  fileName: string = '';  // Stores the name of the file
  editorContent: string = '';  // Stores the content of the editor

  @ViewChild('parentdiv', { static: false }) parentdiv!: ElementRef;  // Access the parent div

  constructor(private modal: NgbActiveModal) {}

  ngAfterViewInit() {
    // Ensure the ViewChild is available after the view has been initialized
    console.log('parentdiv:', this.parentdiv);
  }

  exportToPDF() {
    const editor = document.querySelector('.ql-editor');
    if (editor) {
      // Temporarily set the styles for PDF export
      const originalColor = (editor as HTMLElement).style.color;
      (editor as HTMLElement).style.color = 'black';
      (editor as HTMLElement).style.background = 'white';

      html2canvas(<HTMLElement>editor).then(canvas => {
        const pdf = new jsPDF('p', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
        const imgWidth = canvasWidth * ratio;
        const imgHeight = canvasHeight * ratio;

        const xOffset = (pdfWidth - imgWidth) / 2;
        const yOffset = 20;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);

        const pdfBlob = pdf.output('blob');
        const file = new File([pdfBlob], `${this.fileName || 'file'}.pdf`, { type: 'application/pdf' });

        // Close the modal and return the file
        this.modal.close({ fileName: this.fileName || 'file', file: file });
      }).catch(error => {
        console.error('Error generating PDF:', error);
        (editor as HTMLElement).style.color = originalColor;
      });
    } else {
      console.error('Editor element is not available');
    }
  }

  close() {
    this.modal.close();
  }
}
