<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">Create Report</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 16px; margin-right: 16px">
    <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
      <div class="col-10 p-0">
        <p style="color: #909699">Here you can create a report based on all questions in a question set. The report can then be view in the portal or exported as PDF</p>
      </div>
    </div>
    <form [formGroup]="createReportForm">
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
        <div class="col-8 p-0">
          <label class="f-16 f-bold label-class spacing-15">Report name</label>
          <div class="">
            <div class="input-group">
              <input
                type="text"
                class="form-control input-class"
                formControlName="report_name"
                placeholder="Enter report name"
              >
            </div>
            <span *ngIf="createReportForm.get('report_name')?.invalid && createReportForm.get('report_name')?.touched"
                  class="text-danger">{{ "global.requiredField" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
        <div class="col p-0">
          <label class="f-16 f-bold label-class spacing-15">Assistant type</label>
          <select class="form-select input-class"
                  aria-label="Default select example"
                  formControlName="assistant_id"
          >
            <option value="" selected>Select assistant type</option>
            <option *ngFor="let assistant of assistantGroup.assistants" [value]="assistant.assistant_id">
              {{assistant.assistant_type_name}}
            </option>
          </select>
          <span *ngIf="createReportForm.get('assistant_id')?.invalid && createReportForm.get('assistant_id')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px" *ngIf="templates">
        <div class="col p-0">
          <label class="f-16 f-bold label-class spacing-15">Question set</label>
          <select class="form-select input-class"
                  aria-label="Default select example"
                  formControlName="template_id"
          >
            <option value="" selected>Select question set</option>
            <option *ngFor="let template of templates" [value]="template.template_id">
              {{template.template_name}}
            </option>
          </select>
          <span *ngIf="createReportForm.get('template_id')?.invalid && createReportForm.get('template_id')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
    </form>
    <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
      <div class="col spacing-30 ps-0">
        <label class="f-16 f-bold label-class spacing-15">Files</label>
        <div class="row spacing-15" *ngFor="let file of selectedFiles">
          <div class="col-10">
            <div class="row">
              <div class="col-10">
                <span style="color: #909699">{{ file.file_name }}</span>
              </div>
              <div class="col-2">
                <i class="fa fa-x" (click)="removeFileFromSelectedFiles(file)" style="color: #909699"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!viewMoreFiles">
          <div class="col-10">
            <div class="btn ps-0" style="color: #ED622E" (click)="viewMoreFiles = !viewMoreFiles">Add more files</div>
          </div>
        </div>
        <div class="row pt-2" *ngIf="viewMoreFiles">
          <div class="col-9">
            <label class="f-16 f-bold label-class spacing-15">Search for assistant</label>
            <div class="input-group d-flex">
              <input
                type="text"
                class="form-control input-class"
                [(ngModel)]="searchString"
                placeholder="Assistant name or reference"
              >
            </div>
          </div>
          <div class="col-3 d-flex align-items-end">
            <div class="btn btn-primary m-0" style="background-color: #5e656c; border-color: #5e656c" (click)="getAllAssistantGroups()">
              <span *ngIf="!searchingForAssistants">Search</span>
              <span class="spinner-border spinner-border-sm" role="status" *ngIf="searchingForAssistants"></span>
            </div>
          </div>
        </div>
        <div class="row pt-2" style="max-height: 250px; overflow-y: auto" *ngIf="viewMoreFiles && !searchingForAssistants && searchString.length > 0">
          <div class="col-12">
            <div class="row spacing-15" *ngFor="let assistant of assistantsResults; let i = index">
              <div class="col-12">
                <!-- Main Row (Clickable) -->
                <div class="row" (click)="toggleAssistant(i)" style="cursor: pointer">
                  <div class="col-10">
                    <span style="color: #909699" *ngIf="assistant.reference">{{ assistant.reference }} - {{ assistant.assistant_group_name }}</span>
                    <span style="color: #909699" *ngIf="!assistant.reference">{{ assistant.assistant_group_name }}</span>
                  </div>
                  <div class="col-2">
                    <i class="fa" [class.fa-chevron-down]="expandedAssistant === i" [class.fa-chevron-right]="expandedAssistant !== i" style="color: #5e656c"></i>
                  </div>
                </div>

                <!-- Indented Row (Shown Conditionally) -->
                <div class="row" *ngIf="expandedAssistant === i" style="margin-left: 20px; margin-top: 10px; color: #6c757d;">
                  <div class="col-12">
                    <div class="row spacing-15" *ngFor="let file of assistant.assistants[0].files">
                      <div class="col-1">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" (change)="addFileToSelectedFiles(file)" [checked]="selectedFiles.includes(file)">
                      </div>
                      <div class="col-9">
                        <span style="color: #909699">{{ file.file_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 32px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createReport()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>
