import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AssistantGroupResponse, FileResponse, TemplateResponse} from "../../../../@shared/models/response.module";
import {TemplateService} from "../../../../@shared/services/template.service";
import {_COR_AII_17, _COR_AII_40, _COR_TMP_6} from "../../../../@shared/models/input.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {PaginationResponse} from "../../../../@shared/models/global/response-wrapper.service";

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.css'
})
export class CreateReportComponent {
  @Input() selectedFiles: FileResponse[] = [];
  assistantGroup: AssistantGroupResponse;
  createReportForm: FormGroup
  templates: TemplateResponse[] = [];
  loading: boolean = false;
  viewMoreFiles: boolean = false;
  searchString: string = '';
  assistantsResults: AssistantGroupResponse[] = [];
  searchingForAssistants: boolean = false;
  expandedAssistant: number | null = null; // Tracks the currently expanded assistant


  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private aiService: AiService) {
    this.createReportForm = new FormGroup({
      report_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_id: new FormControl('', [Validators.maxLength(200)]),
      template_id: new FormControl('', [Validators.maxLength(200)])
    });
  }

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates() {
    let params: _COR_TMP_6 = {}
    this.templateService.getAllTemplates(params).subscribe((res) => {
      this.templates = res.data;
    })
  }

  createReport() {
    this.loading = true;
    let payload: _COR_AII_40 = {
      report_name: this.createReportForm.get('report_name')!.value,
      assistant_id: this.createReportForm.get('assistant_id')!.value,
      template_id: this.createReportForm.get('template_id')!.value,
      file_ids: this.selectedFiles.map(file => file.ai_file_id)
    }
    this.aiService.generateReport(payload).subscribe((res: any) => {
      this.loading = false;
      this.modal.close(true);
    })
  }

  close() {
    this.modal.close();
  }

  addFileToSelectedFiles(file: FileResponse) {
    if (this.selectedFiles.includes(file)) {
      this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
    }
    else {
      this.selectedFiles.push(file);
    }
  }

  removeFileFromSelectedFiles(file: FileResponse) {
    this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
  }

  getAllAssistantGroups() {
    this.searchingForAssistants = true;
    let params: _COR_AII_17 = {
      paginate: 0,
      search_string: this.searchString,
      search_string_columns: ["reference", "assistant_group_name"],
    }
    this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantsResults = data.data;
      this.searchingForAssistants = false;
    })
  }

  toggleAssistant(index: number): void {
    this.expandedAssistant = this.expandedAssistant === index ? null : index;
  }
}
