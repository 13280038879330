<div class="container-fluid h-100">
  <div class="row d-flex spacing-30">
    <div class="col d-flex align-items-center gap-26 p-0">
      <div class="header-button header d-flex ps-3 pe-3 align-items-center cursor-pointer" (click)="collapsed = !collapsed">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <p class="m-0 label-class f-18 fw-semibold">Dialogues</p>
          </div>
          <div>
            <i class="fa-regular fa-pen-to-square fa-lg cursor-pointer me-2" style="color: #ED622E" (click)="createNewThread()"></i>
            <i class="far fa-lg" style="color: white" [ngClass]="!collapsed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>
        </div>
      </div>
      <div class="header-box d-flex justify-content-between align-items-center" *ngIf="selectedThread">
        <p class="m-0 label-class f-18 fw-semibold">{{selectedThread.topic}} ( {{formatTimeDMYHHMM(selectedThread.created_at)}} )</p>
<!--        <button class="archive-btn" (click)="deleteThread(selectedThread)">Archive</button>-->
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-stretch">
    <!-- Collapsible Sidebar -->
    <div id="sidebar" class="bg-dark p-0 custom-sidebar pt-2"
         [ngClass]="{'col-md-2 col-lg-2': !collapsed, 'd-none': collapsed}"
         style="background-color: #0D0F11; overflow-y: auto;"
         *ngIf="threads"
    >
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item text-white cursor-pointer"
          *ngFor="let thread of threads"
        >
          <div class="d-flex justify-content-between align-items-center" (click)="changeThread(thread)">
            <a href="javascript:void(0)" class="text-white text-decoration-none d-flex align-items-center w-100">
              <p class="mb-0 d-inline-block" *ngIf="thread.thread_id != selectedThread.thread_id">
                {{ thread.topic }}
              </p>
              <p class="mb-0 d-inline-block" *ngIf="thread.thread_id == selectedThread.thread_id" style="color: #ED622E">
                {{ thread.topic }}
              </p>
              <i class="fa fa-regular fa-xmark ms-auto" (click)="deleteThread(thread)"></i>
            </a>
          </div>
        </li>
      </ul>
    </div>



    <!-- Main Content -->
    <div class="col" [ngClass]="{'custom-offset': !collapsed, '': collapsed}" style="overflow-y: auto;">
      <div class="container-fluid pe-0">
        <div class="row d-flex justify-content-center">
          <div class="col-12 pe-0 ms-0">
            <div class="test card" id="chat2" style="border-radius: 15px;">
              <div class="card-body p-0 ms-0" *ngIf="selectedThread" #scrollContainer>
                <div class="row" style="max-width: 70%">
                  <div class="col-1 d-flex flex-shrink-0 ps-0 ms-2" style="width: 50px">
                    <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                  </div>
                  <div class="col p-0">
                    <div class="row spacing-6">
                      <div class="col">
                        <p class="m-0 label-class">Personal Assistant</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="small p-2 mb-1 message">
                          I am your Personal Assistant. Here you can ask about anything, and all your dialogues here are private to you. I can for example translate text, generate suggestions for text or emails, rewrite a text, make at text longer/shorter, make at text more formal or less formal, format tables with text or numbers, or just ask me to explain difficult concepts or terms. The possibilitis are many, so feel free to explore them. If you want to analyze a file with information, you should use the Master Assistant function and create an assistant there.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of selectedThread.messages">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="row spacing-30" style="max-width: 70%">
                      <div class="col-1 d-flex flex-shrink-0 ps-0 ms-2" style="width: 50px">
                        <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                      </div>
                      <div class="col p-0">
                        <div class="row spacing-6">
                          <div class="col d-flex justify-content-between">
                            <p class="m-0 label-class">Personal Assistant</p>
                            <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="small message">
                              <markdown class="f-16" data="{{message.content}}"></markdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div class="" style="max-width: 70%">
                        <div class="row p-0 m-0">
                          <div class="col p-0 m-0">
                            <div class="row spacing-6 p-0 m-0">
                              <div class="col d-flex justify-content-between">
                                <p class="m-0 label-class">You</p>
                                <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                              </div>
                            </div>
                            <div class="row p-0 m-0">
                              <div class="col p-0 m-0">
                                <div class="small message-user">
                                  <markdown class="f-16" data="{{message.content}}"></markdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pb-3 pt-2 px-0" *ngIf="selectedThread">
                <div class="" *ngIf="selectedFileAttachement" style="left: 0!important;">
                  <div class="rounded-2 d-flex p-2 align-items-center mb-2 justify-content-start" style="background-color: #202020; color: white">
                    <i class="fa fa-regular fa-file me-2"></i>
                    <p class="m-0 me-2" style="color: white;">{{selectedFileAttachement.name}}</p>
                    <i class="fa fa-regular fa-xmark" (click)="removeAttachment()"></i>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                    <textarea
                      #messageTextarea
                      class="form-control input-class"
                      id="exampleFormControlTextarea1"
                      [(ngModel)]="messageInput"
                      placeholder="{{ 'threads.viewThread.input.placeholder' | translate }}"
                      (input)="autoResize($event)"
                      (keydown)="handleKeyDown($event)"
                      style="resize: none; border: 1px solid #495055; padding-right: 100px;
"
                      rows="3"
                      [disabled]="loading"
                    ></textarea>

                    <!-- Icons container -->
                    <div class="icons-container">
<!--                      <a class="ms-1 text-muted" (click)="fileInput.click()">-->
<!--                        <i class="fas fa-paperclip fa-lg" style="color: white"></i>-->
<!--                      </a>-->
<!--                      <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">-->
                      <a class="ms-3" (click)="addNewMessageToThread()">
                        <div class="btn send-btn">Send</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


