<div class="container-fluid h-100">
  <div class="row d-flex spacing-30 w-100">
    <div class="col d-flex align-items-center gap-26 p-0 m-0">
      <div class="header-button header d-flex align-items-center ps-3 pe-3 cursor-pointer" (click)="collapsed = !collapsed">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <p class="m-0 label-class f-18 fw-semibold">{{ "threads.viewThread.expandSidebar" | translate }}</p>
          </div>
          <div>
            <i class="far fa-lg" style="color: white" [ngClass]="!collapsed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>
        </div>
      </div>
      <div class="header-box d-flex justify-content-between align-items-center" style="min-width: 0; gap: 10px;">
        <p
          class="m-0 label-class f-18 fw-semibold"
          style="
      flex: 1 1 0; /* Allow shrinking and growing */
      min-width: 0; /* Prevent overflow */
      max-width: calc(100% - 220px); /* Adjust the width based on available space */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;"
          *ngIf="thread"
        >
          {{ thread.assistant.assistant_name }} - {{ thread.topic }} ( {{ formatTimeDMYHHMM(thread.created_at) }} )
        </p>
        <div class="d-flex align-items-center" style="min-width: 0; gap: 10px;">
          <div class="ps-2 me-2" style="min-width: 0; flex-shrink: 1;" *ngIf="thread">
            <select
              class="form-select input-class cursor-pointer"
              style="height: 47px; box-shadow: none; border: 1px solid #495055; flex-shrink: 1; width: 100%;"
              aria-label="Default select example"
              [(ngModel)]="selectedPdfFileToView"
              (change)="onChangeFileSelect($event)"
            >
              <option value="9999" selected>{{ 'threads.viewThread.selectPdf.placeholder' | translate }}</option>
              <option *ngFor="let file of thread.assistant.files" [value]="file.file_id">
                {{ file.file_name }}
              </option>
            </select>
          </div>
          <button
            class="archive-btn"
            style="flex-shrink: 0;"
            *ngIf="thread && viewPdf"
            (click)="toggleViewPdf()"
          >
            <i class="fa fa-x fa-lg" style="color: white"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-stretch" *ngIf="thread">
    <!-- Collapsible Sidebar -->
    <div id="sidebar" class="bg-dark p-0 custom-sidebar pt-2"
         [ngClass]="{'col-md-2 col-lg-2': !collapsed, 'd-none': collapsed}"
         style="background-color: #0D0F11; overflow-y: auto; max-height: 85%">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item text-white cursor-pointer"
          *ngFor="let template of templates"
          (click)="toggleSubmenu(template.template_id)"
        >
          <div class="d-flex justify-content-between align-items-center">
            <a href="javascript:void(0)" class="text-white text-decoration-none">
              {{ template.template_name }}
            </a>
            <i class="fas" [ngClass]="submenuState[template.template_id] ? 'fa-caret-up' : 'fa-caret-down'"></i>
          </div>

          <!-- Nested Submenu for Questions -->
          <div *ngIf="submenuState[template.template_id]" class="mt-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-white cursor-pointer submenu-item"
                  style="border: 1px solid #ED622E; border-radius: 5px; margin: 5px;"
                  [class.disabled]="isDisabled"
                  [style.pointer-events]="isDisabled ? 'none' : 'auto'"
                  *ngFor="let question of template.questions"
                  (click)="addNewTemplateMessageToThread(question); $event.stopPropagation();">
                {{ question.display_name }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="col" [ngClass]="{'custom-offset pt-2': !collapsed, '': collapsed}" style="overflow-y: auto;">
      <div class="container-fluid">
        <div class="row d-flex justify-content-center w-100">
          <div class="col-md-12 col-lg-12 col-xl-12" *ngIf="!viewPdf">
            <div class="test card" id="chat2" style="border-radius: 15px; flex-grow: 1; padding-bottom: 0;">
              <div class="card-body" *ngIf="thread" #scrollContainer>
                <div class="row spacing-30" style="max-width: 70%">
                  <div class="col-1 d-flex flex-shrink-0 ps-0" style="width: 50px;margin-right: 15px">
                    <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                  </div>
                  <div class="col p-0">
                    <div class="row spacing-6">
                      <div class="col">
                        <p class="m-0 label-class">{{ thread.assistant.assistant_type_name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="small p-2 mb-1 message">
                          Welcome to your new dialogue. You can start the conversation by asking a question in the input below or select a question set from the dropdown menu above.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of thread.messages; let i = index">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="row spacing-30" style="max-width: 70%">
                      <div class="col-1 d-flex flex-shrink-0 ps-0" style="width: 50px;margin-right: 15px">
                        <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                      </div>
                      <div class="col p-0" style="position: relative;">
                        <div class="row spacing-6">
                          <div class="col d-flex justify-content-between">
                            <p class="m-0 label-class">{{ thread.assistant.assistant_type_name }}</p>
                            <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="small message">
                              <markdown class="f-16" data="{{message.content}}"></markdown>
                              <div *ngFor="let citation of message.groupedCitations">
                                <a class="cursor-pointer" style="color: #909699; text-decoration: none" (click)="openCitedFile(citation)">
                                  [{{citation.indexes.join('] [')}}] {{citation.cited_file_name}}
                                </a>
                              </div>
                              <!-- Buttons container -->
                              <div class="buttons-container justify-content-start" [ngClass]="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1 ? 'button-box-all' : 'button-box-single'">
                                <div class="copy-btn d-flex p-0 cursor-pointer" (click)="copyMessage(message.content)">
                                  <i class="fa-solid fa-copy fa-lg" style="color: white;" title="Copy"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="getMoreInformation(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-memo-circle-info fa-lg" style="color: white;" title="Get more information"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="showAnswerAsBulletPointList(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-list-ul fa-lg" style="color: white;" title="Answer as bullet point list"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="showAnswerAsTable(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-table fa-lg" style="color: white;" title="Answer as a table"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div class="" style="max-width: 70%">
                        <div class="row p-0 m-0">
                          <div class="col p-0 m-0">
                            <div class="row spacing-6 p-0 m-0">
                              <div class="col d-flex justify-content-between">
                                <p class="m-0 label-class">You</p>
                                <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                              </div>
                            </div>
                            <div class="row p-0 m-0">
                              <div class="col p-0 m-0">
                                <div class="small message-user">
                                  <markdown class="f-16" data="{{message.content}}"></markdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pt-2 px-0" *ngIf="thread">
                <div class="" *ngIf="selectedFileAttachement" style="left: 0!important;">
                  <div class="rounded-2 d-flex p-2 align-items-center mb-2 justify-content-start" style="background-color: #202020; color: white">
                    <i class="fa fa-regular fa-file me-2"></i>
                    <p class="m-0 me-2" style="color: white;">{{selectedFileAttachement.name}}</p>
                    <i class="fa fa-regular fa-xmark" (click)="removeAttachment()"></i>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                    <textarea
                      #messageTextarea
                      class="form-control input-class"
                      id="exampleFormControlTextarea1"
                      [(ngModel)]="messageInput"
                      placeholder="{{ 'threads.viewThread.input.placeholder' | translate }}"
                      (input)="autoResize($event)"
                      (keydown)="handleKeyDown($event)"
                      style="resize: none; border: 1px solid #495055;
"
                      rows="3"
                      [disabled]="loading"
                    ></textarea>

                    <!-- Icons container -->
                    <div class="icons-container">
                      <a class="ms-1 text-muted" (click)="fileInput.click()">
                        <i class="fas fa-paperclip fa-lg cursor-pointer" style="color: white"></i>
                      </a>
                      <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                      <a class="ms-3" (click)="addNewMessageToThread()">
                        <div class="btn send-btn cursor-pointer">Send</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6" *ngIf="viewPdf">
            <div class="card" id="chat2">
              <div class="card-body" *ngIf="thread" #scrollContainer>
                <div class="row spacing-30" style="max-width: 70%">
                  <div class="col-1 d-flex flex-shrink-0 ps-0" style="width: 50px;margin-right: 15px">
                    <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                  </div>
                  <div class="col p-0">
                    <div class="row spacing-6">
                      <div class="col">
                        <p class="m-0 label-class">{{ thread.assistant.assistant_type_name }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="small p-2 mb-1 message">
                          Welcome to your new dialogue. You can start the conversation by asking a question in the input below or select a question set from the dropdown menu above.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of thread.messages; let i = index">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="row spacing-30" style="max-width: 85%">
                      <div class="col-1 d-flex flex-shrink-0 ps-0" style="width: 50px;margin-right: 15px">
                        <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 40px;">
                      </div>
                      <div class="col p-0" style="position: relative;">
                        <div class="row spacing-6">
                          <div class="col d-flex justify-content-between">
                            <p class="m-0 label-class">{{ thread.assistant.assistant_type_name }}</p>
                            <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="small message">
                              <markdown class="f-16" data="{{message.content}}"></markdown>
                              <div *ngFor="let citation of message.groupedCitations">
                                <a class="cursor-pointer" style="color: #909699; text-decoration: none" (click)="openCitedFile(citation)">
                                  [{{citation.indexes.join('] [')}}] {{citation.cited_file_name}}
                                </a>
                              </div>
                              <!-- Buttons container -->
                              <div class="buttons-container" [ngClass]="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1 ? 'button-box-all' : 'button-box-single'">
                                <div class="copy-btn d-flex p-0 cursor-pointer" (click)="copyMessage(message.content)">
                                  <i class="fa-solid fa-copy fa-lg" style="color: white;" title="Copy"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="getMoreInformation(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-memo-circle-info fa-lg" style="color: white;" title="Get more information"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="showAnswerAsBulletPointList(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-list-ul fa-lg" style="color: white;" title="Answer as bullet point list"></i>
                                </div>
                                <div class="copy-btn d-flex p-0 cursor-pointer"
                                     (click)="showAnswerAsTable(thread.messages[i - 1].content)"
                                     *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1">
                                  <i class="fa-solid fa-table fa-lg" style="color: white;" title="Answer as a table"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div class="" style="max-width: 70%">
                        <div class="row p-0 m-0">
                          <div class="col p-0 m-0">
                            <div class="row spacing-6 p-0 m-0">
                              <div class="col d-flex justify-content-between">
                                <p class="m-0 label-class">You</p>
                                <p class="m-0" style="color: #495055">{{formatTimeDMYHHMM(message.created_at!)}}</p>
                              </div>
                            </div>
                            <div class="row p-0 m-0">
                              <div class="col p-0 m-0">
                                <div class="small message-user">
                                  <markdown class="f-16" data="{{message.content}}"></markdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pt-2 px-0" *ngIf="thread">
                <div class="" *ngIf="selectedFileAttachement" style="left: 0!important;">
                  <div class="rounded-2 d-flex p-2 align-items-center mb-2 justify-content-start" style="background-color: #202020; color: white">
                    <i class="fa fa-regular fa-file me-2"></i>
                    <p class="m-0 me-2" style="color: white;">{{selectedFileAttachement.name}}</p>
                    <i class="fa fa-regular fa-xmark" (click)="removeAttachment()"></i>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                    <textarea
                      #messageTextarea
                      class="form-control input-class"
                      id="exampleFormControlTextarea1"
                      [(ngModel)]="messageInput"
                      placeholder="{{ 'threads.viewThread.input.placeholder' | translate }}"
                      (input)="autoResize($event)"
                      (keydown)="handleKeyDown($event)"
                      style="resize: none; border: 1px solid #495055;"
                      rows="3"
                      [disabled]="loading"
                    ></textarea>

                    <!-- Icons container -->
                    <div class="icons-container">
                      <a class="ms-1 text-muted" (click)="fileInput.click()">
                        <i class="fas fa-paperclip fa-lg cursor-pointer" style="color: white"></i>
                      </a>
                      <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                      <a class="ms-3" (click)="addNewMessageToThread()">
                        <div class="btn send-btn cursor-pointer">Send</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 p-0" *ngIf="viewPdf && thread">
            <div class="h-100">
              <app-view-pdf [thread]="thread" [file_id]="selectedPdfFileToView" *ngIf="selectedPdfFileToView != 9999"></app-view-pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


