<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"profile.changeLanguage.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row pt-3 pb-3 mt-0">
      <div class="col-8">
        <label class="f-16 f-bold">{{ "profile.changeLanguage.label" | translate }}</label>
        <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedLanguage" (change)="changeLanguage()">
          <option value="no">{{"global.norwegian" | translate}}</option>
          <option value="en">{{"global.english" | translate}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
