import {Component, Input} from '@angular/core';
import {UserEntityRelationWithUserDataResponse} from "../../../../@shared/models/response.module";
import {StorageService} from "../../../../@core/services/storage.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {UserService} from "../../../../@core/services/user.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {USM_ENT_2} from "../../../../@shared/models/input.module";

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrl: './edit-employee.component.css'
})
export class EditEmployeeComponent {
  @Input() user: UserEntityRelationWithUserDataResponse;
  selectedRoleId: number;
  constructor(private modal: NgbActiveModal,
              private userService: UserService,
              private toastService: ToastService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    this.selectedRoleId = this.user.role_id;
  }

  editEmployeeRole() {
    let payload: USM_ENT_2 = {
      user_id: this.user.user_id,
      role_id: this.selectedRoleId,
      entity_id: this.storageService.getSelectedCompanyId(),
      component_id: this.user.component_access_id
    }
    this.userService.editEmployeeRole(payload).subscribe(() => {
      this.toastService.showSuccess('toast.settings.updateEmployeeRole.success', 'toast.success.title.success');
    })
  }

  close() {
    this.modal.close();
  }
}
