import { Component } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrl: './release-notes.component.css'
})
export class ReleaseNotesComponent {

  constructor(private modal: NgbActiveModal) {
  }

  close() {
    this.modal.close();
  }
}
