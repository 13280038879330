import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FileResponse,
  QnaAssistantResponse,
  TemplateResponse,
  ThreadResponse, UserResponse
} from "../../@shared/models/response.module";
import {AiService} from "../../@shared/services/ai.service";
import {ActivatedRoute} from "@angular/router";
import {
  _COR_AII_1,
  _COR_AII_10, _COR_AII_11,
  _COR_AII_12,
  _COR_AII_13,
  _COR_AII_14, _COR_AII_16, _COR_AII_3,
  _COR_AII_5, _COR_AII_6, _COR_AII_8, _COR_TMP_6
} from "../../@shared/models/input.module";
import {formatTimeDMYHHMM, getInitials} from "../../@core/utils/utils.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UploadFileComponent} from "./components/upload-file/upload-file.component";
import {TemplateService} from "../../@shared/services/template.service";
import {firstValueFrom, forkJoin} from "rxjs";
import {UserService} from "../../@core/services/user.service";
import {CompareComponent} from "../threads/components/view-thread/components/compare/compare.component";

@Component({
  selector: 'app-qna',
  templateUrl: './qna.component.html',
  styleUrl: './qna.component.css'
})
export class QnaComponent implements OnInit {
  thread: ThreadResponse;
  qnaAssistants: QnaAssistantResponse[] = [];
  messageInput: string = "";
  loading: boolean = false;
  selectedFileAttachement: File | null = null;
  selectedAssistant: QnaAssistantResponse;
  selectedFile: File;
  initDone: boolean = false;
  templates: TemplateResponse[] = [];
  selectedTemplateId: number = 9999;
  pageLoading: boolean = false;
  file: FileResponse
  user: UserResponse
  initialsKeys: string[];
  initials: string;


  constructor(private aiService: AiService,
              private templateService: TemplateService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private userService: UserService) {
  }

  ngOnInit() {
    // this.init();
    // this.userService.getUser().subscribe((data) => {
    //   this.user = data.data;
    //   if (!this.user.profile_image_url) {
    //     this.initialsKeys = [this.user.first_name, this.user.last_name];
    //     this.initials = getInitials(this.initialsKeys)
    //   }
    // })
  }
}
//
//   // ngOnDestroy() {
//   //   this.destroy()
//   // }
//
//   init(){
//     this.getTemplates();
//     this.getQnaAssistants();
//   }
//
//   getTemplates() {
//     let params: _COR_TMP_6 = {}
//     this.templateService.getAllTemplates(params).subscribe((data) => {
//       this.templates = data.data;
//     })
//   }
//
//   getQnaAssistants() {
//     let params: _COR_AII_16 = {}
//     this.aiService.getQnaAssistants(params).subscribe((data) => {
//       this.qnaAssistants = data;
//       this.selectedAssistant = this.qnaAssistants[0];
//     })
//   }
//
//   createThread() {
//     let params: _COR_AII_6 = {
//       assistant_id: this.selectedAssistant.assistant_id,
//       qna: 1
//     }
//     this.aiService.createThread(params).subscribe((data: ThreadResponse) => {
//       this.thread = data;
//     })
//   }
//
//   destroy(){
//     this.deleteAssistant()
//     this.deleteThread()
//     this.deleteFile()
//   }
//
//   deleteAssistant() {
//     let payload: _COR_AII_3 = {
//       assistant_id: this.selectedAssistant.assistant_id
//     }
//     this.aiService.deleteAssistant(payload).subscribe((data) => {})
//   }
//
//   deleteThread() {
//     let params: _COR_AII_8 = {
//       thread_id: this.thread.thread_id
//     }
//     this.aiService.deleteThread(params).subscribe((data) => {
//       // this.deleteAssistant()
//     })
//   }
//
//   deleteFile() {
//     let params: _COR_AII_11 = {
//       file_id: this.file.file_id
//     }
//     this.aiService.deleteFile(params).subscribe((data) => {})
//   }
//
//   // deleteAssistant() {
//   //   let params: _COR_AII_3 = {
//   //     assistant_id: this.selectedAssistant.assistant_id
//   //   }
//   //   this.aiService.deleteAssistant(params).subscribe((data) => {})
//   // }
//
//
//   openFileModal() {
//     let modalRef = this.modalService.open(UploadFileComponent, { centered: true, size: 'lg', keyboard: false});
//     modalRef.result.then((result: File) => {
//       this.pageLoading = true;
//       if (result) {
//         this.selectedFile = result;
//         this.addInitMessageToThread(this.selectedFile)
//       }
//       else {
//
//       }
//     })
//   }
//
//   addTemplateQuestionsToThread() {
//     if (this.selectedTemplateId !== 9999) {
//       this.loading = true;
//       this.processQuestions().then(() => {
//         this.loading = false;
//       });
//     }
//   }
//
//   async processQuestions() {
//     let template = this.templates.find((template) => template.template_id == this.selectedTemplateId);
//     if (template && template.questions.length > 0) {
//       for (let question of template.questions){
//         let params: _COR_AII_14 = {
//           thread_id: this.thread.thread_id,
//           input: question.question,
//           display_message: 1
//         };
//
//         try {
//           // Wait for the addMessageToThread observable to complete and return the response
//           const res = await firstValueFrom(this.aiService.addMessageToThread(params));
//           this.thread.messages.push(res);
//
//           // Wait for runThread to finish before proceeding
//           await this.runThread();
//         } catch (error) {
//           console.error('Error processing question:', error);
//         }
//       }
//     }
//   }
//
//   // ----------------- Chat methods -----------------
//   async runThread(): Promise<void> {
//     this.loading = true;
//
//     try {
//       let params: _COR_AII_12 = {
//         thread_id: this.thread.thread_id
//       };
//
//       // Wait for runThread to complete
//       await firstValueFrom(this.aiService.runThread(params)); // Use toPromise() if on RxJS 6.x
//
//       let messageParams: _COR_AII_13 = {
//         thread_id: this.thread.thread_id
//       };
//
//       // Wait for getMessages to complete
//       this.thread.messages = await firstValueFrom(this.aiService.getMessages(messageParams)); // Use toPromise() if on RxJS 6.x
//
//     } catch (error) {
//       console.error('Error in runThread:', error);
//     } finally {
//       this.loading = false;
//     }
//   }
//
//   addInitMessageToThread(file: File) {
//     let payload: _COR_AII_1 = {
//       assistant_name: 'Quick assistant',
//       assistant_type_id: '0'
//     }
//     this.aiService.createAssistant(payload).subscribe((data) => {
//       this.selectedAssistant = data;
//       let params: _COR_AII_6 = {
//         assistant_id: this.selectedAssistant.assistant_id,
//         qna: 1
//       }
//       this.aiService.createThread(params).subscribe((data: ThreadResponse) => {
//         this.thread = data;
//         let file_params: _COR_AII_10 = {
//           file: this.selectedFile
//         }
//         this.aiService.uploadFile(file_params).subscribe((res) => {
//           this.file= res;
//           let message_params: _COR_AII_14 = {
//             thread_id: this.thread.thread_id,
//             input: "Answer my upcoming questions based on this file:",
//             file_id: res.ai_file_id,
//             display_message: 0
//           }
//           this.aiService.addMessageToThread(message_params).subscribe((res) => {
//             this.thread.messages.push(res);
//             this.messageInput = "";
//             this.selectedFileAttachement = null;
//             if (this.qnaAssistants && this.thread && res)
//               this.pageLoading = false;
//             this.initDone = true;
//           })
//         })
//       })
//     })
//   }
//
//   addNewMessageToThread() {
//     if (this.selectedFileAttachement) {
//       let file_params: _COR_AII_10 = {
//         file: this.selectedFileAttachement
//       }
//       this.aiService.uploadFile(file_params).subscribe((res) => {
//         let message_params: _COR_AII_14 = {
//           thread_id: this.thread.thread_id,
//           input: this.messageInput,
//           file_id: res.ai_file_id,
//           display_message: 1
//         }
//         this.aiService.addMessageToThread(message_params).subscribe((res) => {
//           this.runThread();
//           this.thread.messages.push(res);
//           this.messageInput = "";
//           this.selectedFileAttachement = null;
//         })
//       })
//     }
//     else {
//       let params: _COR_AII_14 = {
//         thread_id: this.thread.thread_id,
//         input: this.messageInput,
//         display_message: 1
//       }
//       this.aiService.addMessageToThread(params).subscribe((res) => {
//         this.runThread();
//         this.thread.messages.push(res);
//         this.messageInput = "";
//       })
//     }
//   }
//
//   onFileSelected(event: any) {
//     const file: File = event.target.files[0];
//     this.selectedFileAttachement = file;
//     if (file) {
//       // Your logic to handle the file upload
//     }
//   }
//
//   createAssistant(){
//     let payload: _COR_AII_1 = {
//       assistant_name: 'Quick assistant',
//       assistant_type_id: '0'
//     }
//     this.aiService.createAssistant(payload).subscribe((data) => {
//
//     })
//   }
//
//   compareDocuments() {
//     let modalRef = this.modalService.open(CompareComponent, {size: 'lg', centered: true, keyboard: false});
//     modalRef.componentInstance.thread = this.thread;
//     modalRef.result.then((result) => {
//       if (result) {
//         this.thread.messages.push(result)
//         this.runThread();
//       }
//     })
//   }
//
//   protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
// }
