import {Component, ElementRef, ViewChild} from '@angular/core';
import {AiService} from "../../@shared/services/ai.service";
import {StorageService} from "../../@core/services/storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
  AssistantResponse,
  PersonalAssistantThreadResponse, ThreadResponse,
  UserResponse
} from "../../@shared/models/response.module";
import {
  _COR_AII_10,
  _COR_AII_14,
  _COR_AII_16,
  _COR_AII_38,
  _COR_AII_5,
  _COR_AII_6, _COR_AII_8,
} from "../../@shared/models/input.module";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../@core/services/toast.service";
import {EndpointService} from "../../@shared/endpoints.service";
import {
  CreatePersonalAssistantThreadComponent
} from "./components/create-personal-assistant-thread/create-personal-assistant-thread.component";

@Component({
  selector: 'app-personal-assistant',
  templateUrl: './personal-assistant.component.html',
  styleUrl: './personal-assistant.component.css'
})
export class PersonalAssistantComponent {
  assistant: AssistantResponse
  threads: ThreadResponse[] = [];
  selectedThread: ThreadResponse;
  messageInput: string = "";
  loading: boolean = false;
  user: UserResponse;
  initialsKeys: string[];
  initials: string;
  isImprovingQuestion: boolean = false;
  collapsed = true;
  submenuState: { [key: number]: boolean } = {};
  init: boolean = true
  improvedQuestion: boolean = false;
  selectedFileAttachement: File | null = null;

  constructor(private aiService: AiService,
              private storageService: StorageService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private endpointService: EndpointService) {
  }

  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;


  ngOnInit(): void {
    this.getQnaAssistants();
    this.user = this.storageService.getUser();

    setTimeout(() => {
      this.init = false
    }, 2000)
  }

  ngAfterViewChecked() {
    if(this.init) {
      this.scrollToBottom()
    }
  }

  getQnaAssistants() {
    let params: _COR_AII_16 = {}
    this.aiService.getQnaAssistants(params).subscribe((data) => {
      this.assistant = data;
      this.getThreads();
    })
  }

  getThreads(): void {
    this.loading = true;
    let params: _COR_AII_5 = {
      paginate: 0,
      qna: 1
    }

    this.aiService.getThreads(params).subscribe((data) => {
      if (data.data.length > 0) {
        this.threads = data.data;
        this.threads.sort((a, b) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();});
        this.changeThread(this.threads[0]);
        this.collapsed = false;
        this.loading = false;
      }
      else {
        this.createFirstThread();
      }
    })
  }

  getThread(): void {
    this.loading = true;
    let params: _COR_AII_5 = {
      thread_id: this.selectedThread.thread_id,
      paginate: 0,
      qna: 1
    }

    this.aiService.getThreads(params).subscribe((data) => {
      this.selectedThread = data.data[0];
      this.loading = false;
    })
  }

  changeThread(thread: ThreadResponse): void {
    this.selectedThread = thread;
    this.init = true;
    setTimeout(() => {
      this.init = false
    }, 500)
  }

  createFirstThread() {
    let params: _COR_AII_6 = {
      assistant_id: this.assistant.assistant_id,
      assistant_model_id: this.assistant.assistant_model_id,
      qna: 1,
      topic: 'General'
    }
    this.aiService.createThread(params).subscribe((data: ThreadResponse) => {
      this.threads.push(data);
      this.selectedThread = data;
      this.loading = false;
    })
  }

  createNewThread() {
    let modalRef = this.modalService.open(CreatePersonalAssistantThreadComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.assistant = this.assistant;
    modalRef.result.then((data: ThreadResponse) => {
      this.threads.push(data);
      this.threads.sort((a, b) => { return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();});
      this.changeThread(data);
    })
  }

  improveQuestion(input_question: string) {
    this.addNewMessageToThread();
  }

  addNewMessageToThread() {
    this.improvedQuestion = false;
    this.loading = true;
    if (this.selectedFileAttachement) {
      let file_params: _COR_AII_10 = {
        file: this.selectedFileAttachement
      }
      this.aiService.uploadFile(file_params).subscribe((res) => {
        let message_params: _COR_AII_14 = {
          thread_id: this.selectedThread.thread_id,
          input: this.messageInput,
          file_id: res.ai_file_id,
          display_message: 1
        }
        this.aiService.addMessageToThread(message_params).subscribe((res) => {
          this.loading = false;
          this.runThread();
          this.selectedThread.messages.push(res);
          this.messageInput = "";
          this.selectedFileAttachement = null;
        })
      })
    }
    else {
      let params: _COR_AII_14 = {
        thread_id: this.selectedThread.thread_id,
        input: this.messageInput,
        display_message: 1
      }
      this.aiService.addMessageToThread(params).subscribe((res) => {
        this.loading = false
        this.runThread();
        this.selectedThread.messages.push(res);
        this.messageInput = "";
        setTimeout(() => {
          this.scrollToBottom()
        }, 500)
        // this.scrollToBottom()
      })
    }
  }

  runThread() {
    // this.loading = true;
    let params: _COR_AII_38 = {
      thread_id: this.selectedThread.thread_id
    }

    this.aiService.runAssistantThreadWithStreaming(params).subscribe({
      next: (chunk: string) => {
        if (!this.selectedThread.messages.find((m) => m.message_id === "test")) {
          this.selectedThread.messages.push({
            message_id: "test",
            content: "",
            role: "assistant",
            display_message: 1
          })
        }
        this.selectedThread.messages.find((m) => m.message_id === "test")!.content += chunk;
        console.log('Received chunk', chunk);
        this.scrollToBottom();
        // this.responseText += chunk; // Append each chunk to responseText
      },
      error: (error) => {
        console.error('Error receiving streamed response', error);
      },
      complete: () => {
        console.log('Streaming complete');
        this.getThread();
        this.loading = false;
      }
    })
  }

  toggleSubmenu(templateId: number): void {
    this.submenuState[templateId] = !this.submenuState[templateId];
  }

  copyMessage(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Message copied to clipboard!');
      this.toastService.showSuccess('toast.success.massageCopiedToClipboard', 'toast.success.title.success')
    }).catch(err => {
      console.error('Failed to copy message: ', err);
    });
  }

  deleteThread(thread: ThreadResponse) {
    let params: _COR_AII_8 = {
      thread_id: thread.thread_id
    }

    this.aiService.deleteThread(params).subscribe(() => {
      this.threads = this.threads.filter((t) => t.thread_id !== thread.thread_id);
      if (this.threads.length > 0) {
        this.changeThread(this.threads[0]);
      }
      else {
        this.createFirstThread();
      }
    })
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.selectedFileAttachement = file;
    if (file) {
      // Your logic to handle the file upload
    }
  }

  private scrollToBottom(): void {
    try {
      if (this.scrollContainer){
        const scrollContainer = this.scrollContainer.nativeElement;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents creating a new line
      this.improveQuestion(this.messageInput); // Calls your function to handle Enter key
    }
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset the height to auto to shrink back when necessary
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on the content
  }

  removeAttachment(): void {
    this.selectedFileAttachement = null;
  }
}
