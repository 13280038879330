<ngx-extended-pdf-viewer
  [src]="selectedFileUrl" *ngIf="selectedFileUrl"
  [showBookModeButton]="false"
  [showDrawEditor]="false"
  [showHandToolButton]="true"
  [showPrintButton]="false"
  [showOpenFileButton]="false"
  [showDownloadButton]="false"
  [showSecondaryToolbarButton]="false"
  [showRotateButton]="true"
  [textLayer]="true"
>
</ngx-extended-pdf-viewer>
