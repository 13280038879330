import { Component } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {UserResponse} from "../../@shared/models/response.module";
import {StorageService} from "../../@core/services/storage.service";
import {UserService} from "../../@core/services/user.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangeLanguageComponent} from "./components/change-language/change-language.component";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {
  ChangePreferredAssistantComponent
} from "./components/change-preferred-assistant/change-preferred-assistant.component";
import {EditProfileComponent} from "./components/edit-profile/edit-profile.component";
import {getInitials} from "../../@core/utils/utils.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  employeeForm: FormGroup;
  userData: UserResponse;
  initialsKeys: string[];
  initials: string;


  constructor(private storageService: StorageService,
              private userService: UserService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((data) => {
      this.userData = data.data;
      if (!this.userData.profile_image_url) {
        this.initialsKeys = [this.userData.first_name, this.userData.last_name];
        this.initials = getInitials(this.initialsKeys)
      }
    });
  }

  changeLanguage() {
    let modalRef = this.modalService.open(ChangeLanguageComponent, {size: 'lg', centered: true, keyboard: false});
  }

  changePreferredAssistantType() {
    let modalRef = this.modalService.open(ChangePreferredAssistantComponent, {size: 'lg', centered: true, keyboard: false});
  }

  changePassword() {
    let modalRef = this.modalService.open(ChangePasswordComponent, {size: 'lg', centered: true, keyboard: false});
  }

  editProfile() {
    let modalRef = this.modalService.open(EditProfileComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.user = this.userData;
    modalRef.result.then((result) => {
      if (result) {
        this.userData = result;
      }
    })
  }
}
