import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AiService} from "../../../../@shared/services/ai.service";
import {AssistantGroupResponse, AssistantResponse, ThreadResponse} from "../../../../@shared/models/response.module";
import {_COR_AII_5, _COR_AII_6} from "../../../../@shared/models/input.module";
import {Router} from "@angular/router";
import {UserService} from "../../../../@core/services/user.service";
import {formatTimeDMYHHMM} from "../../../../@core/utils/utils.service";

@Component({
  selector: 'app-choose-assistant',
  templateUrl: './choose-assistant.component.html',
  styleUrl: './choose-assistant.component.css'
})
export class ChooseAssistantComponent {
  @Input() assistantGroup: AssistantGroupResponse;
  threads: ThreadResponse[] = [];


  constructor(private modal: NgbActiveModal,
              private aiService: AiService,
              private router: Router,
              private userService: UserService) {}

  ngOnInit() {
    this.getRecentThreads()
  }
  createAndGoToThread(assistant: AssistantResponse) {
    let params: _COR_AII_6 = {
      assistant_id: assistant.assistant_id,
      qna: 0
    }
    this.aiService.createThread(params).subscribe((data) => {
      this.modal.close();
      this.router.navigate(['/threads/view/', data.thread_id]);
    })
  }

  goToThread(thread: ThreadResponse) {
    this.modal.close();
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  getRecentThreads() {
    this.userService.getUserOptions().subscribe((data) => {
      let payload: _COR_AII_5 = {
        qna: 0,
        assistant_id: this.assistantGroup.assistants.find((assistant) => assistant.assistant_type_id = data.preferred_assistant_type_id)!.assistant_id
      }
      this.aiService.getThreads(payload).subscribe((res) => {
        this.threads = res.data;
        if (this.threads.length == 0) {
          this.createAndGoToThread(this.assistantGroup.assistants.find((assistant) => assistant.assistant_model_id = data.preferred_assistant_type_id)!)
        }
      })
    })
  }


  close() {
    this.modal.close();
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
