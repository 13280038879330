import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AssistantGroupResponse, TemplateResponse} from "../../../../@shared/models/response.module";
import {TemplateService} from "../../../../@shared/services/template.service";
import {_COR_AII_40, _COR_TMP_6} from "../../../../@shared/models/input.module";
import {AiService} from "../../../../@shared/services/ai.service";

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.css'
})
export class CreateReportComponent {
  assistantGroup: AssistantGroupResponse;
  createReportForm: FormGroup
  templates: TemplateResponse[] = [];
  loading: boolean = false;


  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private aiService: AiService) {
    this.createReportForm = new FormGroup({
      report_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_id: new FormControl('', [Validators.maxLength(200)]),
      template_id: new FormControl('', [Validators.maxLength(200)])
    });
  }

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates() {
    let params: _COR_TMP_6 = {}
    this.templateService.getAllTemplates(params).subscribe((res) => {
      this.templates = res.data;
    })
  }

  createReport() {
    this.loading = true;
    let payload: _COR_AII_40 = {
      report_name: this.createReportForm.get('report_name')!.value,
      assistant_id: this.createReportForm.get('assistant_id')!.value,
      template_id: this.createReportForm.get('template_id')!.value,
    }
    this.aiService.generateReport(payload).subscribe((res: any) => {
      this.loading = false;
      this.modal.close(true);
    })
  }

  close() {
    this.modal.close();
  }
}
