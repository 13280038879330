<app-card
  [titleLocalisationStrong]="'assistants.title'"
  [footerWithButton]="false"
>
  <div class="row spacing-30">
    <div class="col-10">
      <p style="color: #909699">Here you can see the assistants that are available for you. You can also create new assistants here. By uploading files in pdf format to your assistant, your assistant will make it easy for you to extract data and information from your documents.  Each assistant has a name, a reference and a description field. It is possible make an assistant public, which means it is accessible to everyone in your organisation, or private, which means it is only visible to you. </p>
    </div>
  </div>
  <div class="row mb-2 me-1 d-flex justify-content-end">
    <div class="col pe-0 d-flex justify-content-between spacing-30">
      <div class="">
        <form [formGroup]="searchForm" (focusout)="onSearch()" (change)="onSearch()">
          <div class="input-group">
            <input
              type="text"
              class="form-control f-14"
              formControlName="searchString"
              style="background-color: #202020; border-color: #A7A7A7; width: 330px; color: white; height: 40px;"
              placeholder="{{'assistants.searchString.placeholder' | translate}}"
            >
          </div>
        </form>
      </div>
      <button class="btn btn-primary f-14" (click)="createAssistant()">{{"assistants.createAssistantBtn" | translate}}</button>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <table class="table rounded-table table-hover">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="f-12">{{"assistants.table.reference" | translate}}</th>
          <th scope="col" class="f-12">{{"assistants.table.assistantName" | translate}}</th>
          <th scope="col" class="f-12">{{"assistants.table.description" | translate}}</th>
          <th scope="col" class="f-12">{{"assistants.table.private" | translate}}</th>
          <th scope="col" class="f-12">{{"assistants.table.createdBy" | translate}}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="assistantGroups.length === 0">
          <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
            {{ "assistants.noAssistants" | translate }}
          </td>
        </tr>
        <tr *ngFor="let assistantGroup of assistantGroups" class="table-row-hover">
          <td class="cursor-pointer">
            <div class="ps-3">
              <i class="fa fa-star fa-lg me-2" style="color: #ED622E" *ngIf="userFavoritesIds.includes(assistantGroup.assistant_group_id)" (click)="removeFromFavorites(assistantGroup)"></i>
              <i class="fa fa-regular fa-star fa-lg me-2" *ngIf="!userFavoritesIds.includes(assistantGroup.assistant_group_id)" (click)="addToFavorites(assistantGroup)"></i>
            </div>
          </td>
          <td class="cursor-pointer f-14" (click)="goToAssistantDetails(assistantGroup)">{{ assistantGroup.reference }}</td>
          <td class="cursor-pointer f-14" (click)="goToAssistantDetails(assistantGroup)">{{ assistantGroup.assistant_group_name }}</td>
          <td class="cursor-pointer f-14" (click)="goToAssistantDetails(assistantGroup)">{{ assistantGroup.assistant_group_description }}</td>
          <td class="cursor-pointer f-14" (click)="goToAssistantDetails(assistantGroup)">
<!--            <span class=" f-14" style="color: white" *ngIf="assistantGroup.private"><i class="fa fa-regular fa-lock me-2" *ngIf="assistantGroup.private"></i>{{ "assistants.table.label.private" | translate }}</span>-->
<!--            <span class=" f-14" style="color: white" *ngIf="!assistantGroup.private"><i class="fa fa-regular fa-lock-open me-2" *ngIf="!assistantGroup.private"></i>{{ "assistants.table.label.public" | translate }}</span>-->
            <span class="label private f-14" *ngIf="assistantGroup.private">{{ "assistants.table.label.private" | translate }}</span>
            <span class="label public f-14" *ngIf="!assistantGroup.private">{{ "assistants.table.label.public" | translate }}</span>
          </td>
          <td class="cursor-pointer" (click)="goToAssistantDetails(assistantGroup)">
            <div class="profile-container">
              <img
                *ngIf="assistantGroup.created_by?.profile_image_url"
                [src]="assistantGroup.created_by?.profile_image_url"
                alt="Profile Image"
                class="profile-image"
              />
              <span class="f-14">{{ assistantGroup.created_by?.first_name }} {{ assistantGroup.created_by?.last_name }}</span>
            </div>
          </td>
          <td>
            <div class="pe-3">
              <i class="fa-regular fa-trash-can fa-lg" (click)="deleteAssistantGroup(assistantGroup)"></i>
              <i class="fa-regular fa-pen fa-lg ms-3" (click)="goToAssistantDetails(assistantGroup)"></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="row d-flex justify-content-center">
        <div class="pagination col d-flex justify-content-center">
          <button *ngFor="let page1 of displayedPages"
                  (click)="selectPage(page1)"
                  [class.active]="page1 === page"
                  class="pagination-button me-2"
          >
            {{ page1 }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer"  *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white"></div>
    </div>
  </div>
</app-card>
