<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"threads.createThread.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="createThreadForm">
      <div class="row pt-3" *ngIf="!details">
        <div class="col-8">
          <label class="f-16 f-bold">{{"threads.createThread.assistantLabel" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="assistant_group_id"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'threads.createThread.assistantLabel' | translate}}</option>
            <option *ngFor="let group of assistantsGroups" [value]="group.assistant_group_id">
              ({{ group.reference }}) {{ group.assistant_group_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"threads.createThread.assistantRoleLabel" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="assistant_id">
            <option value="" selected>{{'threads.createThread.assistantPlaceholder' | translate}}</option>
            <option *ngFor="let assistant of filteredAssistants" [value]="assistant.assistant_id">
              {{ assistant.assistant_type_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"threads.createThread.topicLabel" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="topic"
              placeholder="{{'threads.createThread.topic' | translate}}"
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createThread()">
      {{ "assistants.createAssistant.createBtn" | translate }}</div>
  </div>
</div>

