import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "../../../../@core/services/user.service";
import {USM_USR_16, USM_USR_18, USM_USR_3} from "../../../../@shared/models/input.module";
import {UserResponse} from "../../../../@shared/models/response.module";
import {ToastService} from "../../../../@core/services/toast.service";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.css'
})
export class EditProfileComponent {
  @Input() user: UserResponse;
  editUser: FormGroup;
  selectedFile: File;
  editImage: boolean = false;

  constructor(private modal: NgbActiveModal,
              private userService: UserService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.editUser = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.maxLength(11)])
    });

    this.editUser.patchValue({
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      phone: this.user.phone
      });
    }

  updateProfileImage(){

  }

  onEdit(){
    this.editImage = !this.editImage;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    let params: USM_USR_16 = {
      image: this.selectedFile
    }
    this.userService.updateProfileImage(params).subscribe((data) => {
      this.user = data;
      this.toastService.showSuccess('toast.success.profile.profileImage', 'toast.success.title.success')
    })
  }

  submit(){
    let params: USM_USR_3 = {
      user_id: this.user.user_id,
      first_name: this.editUser.value.firstName,
      last_name: this.editUser.value.lastName,
      phone: this.editUser.value.phone,
      email: this.user.email
    }
    this.userService.updateUser(params).subscribe((data) => {
      this.user = data;
      this.toastService.showSuccess('toast.success.profile', 'toast.success.title.success')
      this.modal.close(this.user);
    })
  }

  close() {
    this.modal.close();
  }
}
