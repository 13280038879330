import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../@core/services/storage.service';
import { UserService } from '../../../@core/services/user.service';
import { UserResponse } from '../../models/response.module';

declare var _chatlio: any;

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.css']
})
export class ChatWidgetComponent implements OnInit, OnDestroy {

  constructor (
    private userService: UserService,
    private storageService: StorageService,
    private translate: TranslateService
  ) {}

  userData: UserResponse;

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.userData = user.data;
      this.initializeChatlio();
    });
  }

  ngOnDestroy() {
    this.removeChatlioListener();
  }

  initializeChatlio() {
    this.chatlioReadyListener = this.chatlioReadyListener.bind(this);
    window.addEventListener('chatlio.ready', this.chatlioReadyListener);
  }

  removeChatlioListener() {
    window.removeEventListener('chatlio.ready', this.chatlioReadyListener);
  }

  chatlioReadyListener(event: Event): void {
    _chatlio.identify(this.userData.user_id, {
      name: `${this.userData.full_name}`,
      email: this.userData.email,
      company: `${this.storageService.getSelectedCompany().entity_name} (${this.storageService.getSelectedCompany().entity_id})`,
      phone: this.userData.phone
    });
    _chatlio.configure({
      "off":false,
      "maxVisitorInactiveMins":3,
      "noAnswerWithEmail": this.translate.instant('chat-widget.noAnswerWithEmail'),
      "noAnswerWithoutEmail": this.translate.instant('chat-widget.noAnswerWithoutEmail'),
      "status":"online",
      "hideWhenOffline":false,
      "showAvatarPreChat":false,
      "showTeamAvatarPreChat":false,
      "newMsgNotifications":true,
      "playSound":true,
      "dialogflowOn":false,
      "watsonconvOn":false,
      "activeAgentAvatar":"",
      "forwardSlackUserName":true,
      "widgetAlignment":"bottomRight",
      "titleColor":"#0D0F11",
      "titleFontColor":"#fff",
      "onlineTitle": this.translate.instant('chat-widget.onlineTitle'),
      "offlineTitle":this.translate.instant('chat-widget.offlineTitle'),
      "agentLabel":this.translate.instant('chat-widget.agentLabel'),
      "browserSideAuthorLabel":"",
      "onlineMessagePlaceholder":this.translate.instant('chat-widget.onlineMessagePlaceholder'),
      "whiteLabel":true,
      "trackPresence":false,
      "offlineGreeting":this.translate.instant('chat-widget.offlineGreeting'),
      "offlineEmailPlaceholder":this.translate.instant('chat-widget.offlineEmailPlaceholder'),
      "offlineMessagePlaceholder":this.translate.instant('chat-widget.offlineMessagePlaceholder'),
      "offlineNamePlaceholder":this.translate.instant('chat-widget.offlineNamePlaceholder'),
      "offlineSendButton":this.translate.instant('chat-widget.offlineSendButton'),
      "offlineThankYouMessage":this.translate.instant('chat-widget.offlineThankYouMessage'),
      "requireInfo":false,
      "requireInfoGreeting":this.translate.instant('chat-widget.requireInfoGreeting'),
      "requireInfoSubmitBtn":this.translate.instant('chat-widget.requireInfoSubmitBtn'),
      "requestScreenshotText":this.translate.instant('chat-widget.requestScreenshotText'),
      "requestScreenshotAllowLabel":this.translate.instant('chat-widget.requestScreenshotAllowLabel'),
      "requestScreenshotDeclineLabel":this.translate.instant('chat-widget.requestScreenshotDeclineLabel'),
      "teamAvatarOn":false,
      "collapsedMode":"button",
      "defaultOkBtnLabel":this.translate.instant('chat-widget.defaultOkBtnLabel'),
      "defaultSendBtnLabel":this.translate.instant('chat-widget.defaultSendBtnLabel'),
      "defaultCancelBtnLabel":this.translate.instant('chat-widget.defaultCancelBtnLabel'),
      "defaultYesBtnLabel":this.translate.instant('chat-widget.defaultYesBtnLabel'),
      "defaultNoBtnLabel":this.translate.instant('chat-widget.defaultNoBtnLabel'),
      "showConversationEnd":true,
      "showConversationRating":true,
      "showTranscriptSendOpt":true,
      "clearMessagesOnEnd":false,
      "conversationEndLabel":this.translate.instant('chat-widget.conversationEndLabel'),
      "conversationRatingLabel":this.translate.instant('chat-widget.conversationRatingLabel'),
      "conversationRatingThankYou":this.translate.instant('chat-widget.conversationRatingThankYou'),
      "conversationRatingPlaceholder":this.translate.instant('chat-widget.conversationRatingPlaceholder'),
      "conversationEndTranscriptPlaceholder":this.translate.instant('chat-widget.conversationEndTranscriptPlaceholder'),
      "conversationEndConfirmationQuestion":this.translate.instant('chat-widget.conversationEndConfirmationQuestion'),
      "conversationTranscriptSentThankYou":this.translate.instant('chat-widget.conversationTranscriptSentThankYou'),
      "conversationEndMenuLinkLabel":this.translate.instant('chat-widget.conversationEndMenuLinkLabel'),
      "blacklistedUrls":"",
      "triggerJson":"",
      "useSgmt":false,
      "autoResponseMessage": this.translate.instant('chat-widget.autoResponseMessage')
   });
  }
}
