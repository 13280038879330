import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionAnswerTypeResponse, QuestionResponse} from "../../../../@shared/models/response.module";
import {_COR_TMP_9, COR_AII_29, COR_TMP_4} from "../../../../@shared/models/input.module";
import {question} from "../create-template/create-template.component";
import {TemplateService} from "../../../../@shared/services/template.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {AiService} from "../../../../@shared/services/ai.service";
import {StorageService} from "../../../../@core/services/storage.service";
import {tr} from "date-fns/locale";

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrl: './question.component.css'
})
export class QuestionComponent implements OnInit {
  @Input() question!: QuestionResponse;
  @Input() questionAnswerTypes: QuestionAnswerTypeResponse[] = [];
  @Output() questionChange = new EventEmitter<QuestionResponse>();
  @Output() questionDelete = new EventEmitter<number>();
  isImprovingQuestion: boolean = false;
  isSecondRowVisible: boolean = false; // Variable to control visibility of second row
  isContextVisible: boolean = false;    // New variable




  constructor(private templateService: TemplateService,
              private toastService: ToastService,
              private aiService: AiService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    if (this.question.question.length == 0){
      this.isSecondRowVisible = true
    }
  }

  onChange() {
    if (this.question.context.length == 0) {
      this.isContextVisible = false;
    }
    this.questionChange.emit(this.question);
  }

  toggleSecondRow() {
    if (this.question.context && this.question.context.length > 0) {
      this.isContextVisible = true;
    }
    this.isSecondRowVisible = !this.isSecondRowVisible;
  }

  toggleContextVisibility() {
    this.isContextVisible = !this.isContextVisible;
  }

  deleteQuestion() {
    let payload: _COR_TMP_9 = {
      question_id: this.question.question_id
    }

    this.templateService.deleteQuestion(payload).subscribe(() => {
      this.templateService.templatesUpdatedSubject.next(true);
      this.questionDelete.emit(this.question.question_id);
      this.toastService.showSuccess('toast.questionDeleted.success', 'toast.success.title.success');
    })
  }

  improveQuestion(question: QuestionResponse) {
    // Show spinner while improving question
    this.isImprovingQuestion = true;
    console.log('isImprovingQuestion', this.isImprovingQuestion);

    let params: COR_AII_29 = {
      company_id: this.storageService.getSelectedCompanyId(),
      question: question.question
    };

    this.aiService.improveQuestion(params).subscribe((res) => {
      this.question.question = res.data.improved_question;
      this.onChange();
      this.isImprovingQuestion = false; // Hide the spinner after response
    });
  }
}
