<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"assistants.details.addDocumentsModal.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 16px; margin-right: 16px">
    <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
      <div class="col-10 p-0">
        <p style="color: #909699">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend urna erat, nec maximus arcu lobortis sed. Duis in lorem gravida, aliquam nisi a, molestie ex. Quisque congue quam sit amet vehicula dapibus. </p>
      </div>
    </div>
    <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
      <div class="col-8 dropzone" style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 40px;">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        <div style="display: flex; flex-direction: column; align-items: center;">
          <i class="fa fa-regular fa-cloud-arrow-up fa-2x pb-2" style="color: #ED622E;"></i>
          <div style="display: flex; justify-content: center; align-items: center;">
            <p class="fw-semibold f-16 me-1" style="color: #ED622E; margin: 0;">Click to upload</p>
            <p class="f-16" style="color: #909699; margin: 0;">or drag and drop</p>
          </div>
          <p class="f-16" style="color: #909699; margin: 0;">pdf, PDF, docx</p>
        </div>
      </div>
      <div class="row pt-3" *ngFor="let file of selectedFiles">
        <div class="col-12 d-flex ms-2 align-items-center">
          <i style="color: #909699" class="fa fa-file-pdf fa-2x me-2"></i>
          <h5 style="color: #909699"  class="m-0">{{file.name}}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" (click)="uploadFilesSequentially()">
      <div *ngIf="uploading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
        {{ "assistants.details.addDocumentsModal.saveBtn" | translate }}
    </div>
  </div>
</div>
