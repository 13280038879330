import {ApplicationRef, Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
import {SwUpdate} from "@angular/service-worker";
import {concat, first, interval} from "rxjs";
import {AuthService} from "./@core/services/auth.service";
import {StorageService} from "./@core/services/storage.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'aiemployee-portal';
  isLoggedIn: boolean = false
  currentPath = '';

  constructor(private translate: TranslateService,
              private swUpdate: SwUpdate,
              private appRef: ApplicationRef,
              private authService: AuthService,
              private storageService: StorageService,
              private router: Router ) {
    this.authService.init();
    this.authService.isAuthenticated.subscribe((data) => {
      this.isLoggedIn = data
    })
  }


  ngOnInit(): void {
    this.translate.setDefaultLang('en');

    if (environment.production) {
      this.checkUpdates()
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentPath = event.url;
    });
  }

  checkUpdates() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable));
    const everySixHours$ = interval(10 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.swUpdate.checkForUpdate();
        if (updateFound) {
          if (confirm(this.translate.instant("applicationUpdateMessage"))) {
            document.location.reload();
          }
        } else {
        }
      } catch (err) {
      }
    });
  }
}
