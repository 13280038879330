import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  @Input() imagePath? = ""
  @Input() header = ""
  @Input() description? = ""
  @Input() body? = ""
}
