<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">Additional data</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can add data to the assistant in a structured way. <br><br> If you create a report from a question set, all questions that the assistant is not able to answer will be added here as a form automatically.</p>
      </div>
    </div>
    <div *ngIf="templateMissingDataFields">
      <div class="row pb-2" *ngFor="let field of templateMissingDataFields; let i = index">
        <div class="col-8">
          <!-- Clickable Display Name -->
          <div class="d-flex justify-content-between align-items-center">
            <label class="f-16 f-bold label-class spacing-15 m-0" (click)="toggleField(i)" style="cursor: pointer;">
              {{ field.question.display_name }}
            </label>
            <div class="d-flex align-items-center">
              <div *ngIf="field.value.length === 0" class="px-2 me-2" style="">
                <span class="f-12" style="color: #ED622E">Missing data</span>
              </div>
              <i class="fa fa-chevron-down cursor-pointer" *ngIf="expandedIndex !== i" style="color: #ED622E" (click)="toggleField(i)"></i>
              <i class="fa fa-chevron-up cursor-pointer" *ngIf="expandedIndex === i" style="color: #ED622E" (click)="toggleField(i)"></i>
            </div>
          </div>

          <!-- Expanded Content: Only the selected row expands -->
          <div *ngIf="expandedIndex === i">
            <p class="f-12" style="color: #909699" *ngIf="field.question.question != field.question.display_name">{{ field.question.question }}</p>
            <div class="spacing-30">
            <textarea
              class="form-control input-class w-100 h-100"
              rows="5"
              placeholder="Answer"
              [(ngModel)]="field.value"
            ></textarea>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center" *ngIf="expandedIndex === i">
          <i class="fa fa-trash-can fa-lg cursor-pointer" style="color: white" (click)="removeField(field)"></i>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!showAddField">
      <div class="d-flex align-items-center">
        <i class="fa fa-plus me-2" style="color: #ED622E"></i>
        <div class="btn ps-0" style="color: #ED622E"  (click)="showAddField = !showAddField">Add field</div>
      </div>
    </div>
    <div class="row pt-3" *ngIf="showAddField">
      <hr style="color: #909699">
      <div class="col-8">
        <div class="row">
          <label class="f-16 f-bold label-class spacing-15">Topic</label>
          <div class="spacing-30">
            <div class="input-group">
              <input
                type="text"
                class="form-control input-class"
                [(ngModel)]="addFieldQuestion"
                placeholder="Topic description"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <label class="f-16 f-bold label-class spacing-15">Data</label>
          <div class="spacing-30">
              <textarea
                class="form-control input-class w-100 h-100"
                rows="5"
                placeholder="Data to be added to the assistant"
                [(ngModel)]="addFieldValue"
              ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <div class="btn btn-primary me-2" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="showAddField = !showAddField">
              Cancel
            </div>
            <button class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="addField()" [disabled]="!(addFieldQuestion.length > 0 && addFieldValue.length > 0)">
              Add field
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" *ngIf="!showAddField" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="downloadSupplementaryData()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      Save
    </div>
  </div>
</div>

