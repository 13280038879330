import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AssistantGroupResponse, AssistantModelResponse,
  QuestionAnswerTypeResponse
} from "../../../../@shared/models/response.module";
import {question} from "../../../question-templates/components/create-template/create-template.component";
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {_COR_AII_1, _COR_AII_10, _COR_AII_18} from "../../../../@shared/models/input.module";
import { firstValueFrom } from 'rxjs';
import {ToastService} from "../../../../@core/services/toast.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-assistant',
  templateUrl: './create-assistant.component.html',
  styleUrl: './create-assistant.component.css'
})
export class CreateAssistantComponent {
  createAssistantForm: FormGroup
  assistantTypes: AssistantModelResponse[] = [];
  selectedFiles: File[] = [];
  loading: boolean = false;

  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService,
              private router: Router) {
    this.createAssistantForm = new FormGroup({
      assistant_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_type_id: new FormControl('', [Validators.maxLength(200)]),
      reference: new FormControl('', [Validators.maxLength(200)]),
      assistant_group_description: new FormControl('', [Validators.maxLength(200)]),
      private: new FormControl(false),
    });
  }

  ngOnInit() {
    this.getAssistantTypes()
  }

  createAssistant() {
    this.loading = true;
    let payload: _COR_AII_18 = {
      assistant_group_name: this.createAssistantForm.get('assistant_name')!.value,
      reference: this.createAssistantForm.get('reference')!.value,
      assistant_group_description: this.createAssistantForm.get('assistant_group_description')!.value,
      private: this.createAssistantForm.get('private')!.value,
    }

    this.aiService.createAssistantGroup(payload).subscribe((res) => {
      this.toastService.showSuccess('toast.createAssistantGroup.success', 'toast.success.title.success')
      if (this.selectedFiles) {
        this.uploadFilesSequentially(res.assistant_group_id);
      }
      else {
        this.aiService.assistantsUpdatedSubject.next(true);
        this.loading = false;
        this.modal.close();
        this.router.navigate(['/assistants/details/' + res.assistant_group_id]);
      }
    })
  }

  getAssistantTypes() {
    this.aiService.getAssistantModels().subscribe((data: AssistantModelResponse[]) => {
      this.assistantTypes = data;
    })
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      for (let file of Array.from(files)) {
        this.selectedFiles!.push(file);
      }
    }
  }

  removeFile() {
    this.selectedFiles = [];
  }


  close() {
    this.modal.close();
  }


  async uploadFilesSequentially(assistantId: string) {
    this.loading = true;
    let params: _COR_AII_10 = {
      assistant_group_id: assistantId,
      file: this.selectedFiles[0]
    };
    this.aiService.uploadFile(params).subscribe((res) => {
      let files = this.selectedFiles!.slice(1);
      setTimeout(() => {
        for (let file of this.selectedFiles.slice(1)!) {
          let params: _COR_AII_10 = {
            assistant_group_id: assistantId,
            file: file
          };
          this.aiService.uploadFile(params).subscribe();
        }
        this.loading = false;
        this.toastService.showSuccess('toast.success.title.success', 'toast.uploadFile.success');
        this.modal.close(true);
        this.router.navigate(['/assistants/details/' + assistantId]);
      }, 2000)
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  goToAssistantDetails(assistantGroup: AssistantGroupResponse) {
    this.router.navigate([`/assistants/details/${assistantGroup.assistant_group_id}`]);
  }
}
