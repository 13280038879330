<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">Create file </h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can create a new file for your assistant, with new information you would like the assistant to know and answer questions about. The information added here will only be available for this assistant.</p>
      </div>
    </div>
    <div class="row">
      <label class="f-16 f-bold label-class spacing-15">File name</label>
      <div class="spacing-30">
        <div class="input-group">
          <input
            type="text"
            class="form-control input-class"
            [(ngModel)]="fileName"
            placeholder="File Name"
            style="box-shadow: none"
          >
        </div>
      </div>
    </div>
    <div class="row spacing-30">
      <div class="document-editor #parentdiv">
        <quill-editor [(ngModel)]="editorContent"></quill-editor>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="exportToPDF()">
      {{ "assistants.details.createDocument.save" | translate }}
    </div>
  </div>
</div>
