<div class="h-100">
  <div class="wrapper" *ngIf="isLoggedIn">
    <div class="main-layout">
      <app-sidebar></app-sidebar>
      <div class="content-page">
        <div class="content">
          <router-outlet></router-outlet>
          <app-chat-widget></app-chat-widget>
        </div>
      </div>
    </div>
  </div>

  <div class="h-100" *ngIf="!isLoggedIn || currentPath === '/home' || currentPath === '/auth/forgot-password'">
    <router-outlet></router-outlet>
  </div>
</div>
