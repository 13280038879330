<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"dashboard.releaseNotes.title" | translate}} (v1.1.0)</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Release v1.1.0 have improvements and bug fixes and a new feature for master assistants. You can now generate a report and export it to pdf based on a question set.</p>
      </div>
    </div>
    <div class="row spacing-30">
      <div class="col-8">
        <h4 class="f-16 f-bold label-class spacing-15">{{"dashboard.releaseNotes.updateLabel" | translate}}</h4>
      </div>
    </div>
    <div class="row spacing-20">
      <div class="col-10">
        <div class="col-10 d-flex align-items-center">
          <i class="fa fa-circle me-4" style="color: #ED622E; top: 5px"></i>
          <span class="m-0" style="color: #909699">Generate reports based on question sets</span>
        </div>
      </div>
    </div>
    <div class="row spacing-30">
      <div class="col-10">
        <div class="col-10 d-flex align-items-center">
          <i class="fa fa-circle me-4" style="color: #ED622E; top: 5px"></i>
          <span class="m-0" style="color: #909699">Improvements and bug fixes</span>
        </div>
      </div>
    </div>
<!--    <div class="row spacing-20">-->
<!--      <div class="col-10">-->
<!--        <div class="col-10 d-flex align-items-center">-->
<!--          <i class="fa fa-circle me-4" style="color: #ED622E; top: 5px"></i>-->
<!--          <span class="m-0" style="color: #909699">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend urna erat</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row spacing-30">-->
<!--      <div class="col-10">-->
<!--        <div class="col-10 d-flex align-items-center">-->
<!--          <i class="fa fa-circle me-4" style="color: #ED622E; top: 5px"></i>-->
<!--          <span class="m-0" style="color: #909699">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend urna erat</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
