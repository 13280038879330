<app-card
  [titleLocalisationStrong]="'templates.templateList.title'"
  [footerWithButton]="false"
>
  <div class="row spacing-30">
    <div class="col-10">
      <p style="color: #909699">A Question Set is a group of ready made questions that are available to your. Each question has a display name, which explains what the question is about and the full question text. You can also add a context field to let the assistant better understand the context for your question, and in this way the assistant can generate better answers. Question Sets can help save time when you have similar projects or customers, and where you often need to ask the same questions to extract the same information from different assistants and dialogues. </p>
    </div>
  </div>
  <div class="row mb-2 me-1 d-flex justify-content-end">
    <div class="col pe-0 d-flex justify-content-between spacing-30">
      <div class="">
        <form [formGroup]="searchForm" (focusout)="onSearch()" (change)="onSearch()">
          <div class="input-group">
            <input
              type="text"
              class="form-control f-14"
              formControlName="searchString"
              style="background-color: #202020; border-color: #A7A7A7; width: 330px; color: white; height: 40px;"
              placeholder="{{'assistants.searchString.placeholder' | translate}}"
            >
          </div>
        </form>
      </div>
      <button class="btn btn-primary f-14" (click)="createNewTemplate()">{{"templates.templateList.createTemplateBtn" | translate}}</button>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <table class="table rounded-table table-hover">
        <thead>
        <tr>
          <th scope="col" class="f-12 ps-3">{{ "templates.table.templateName" | translate }}</th>
          <th scope="col" class="f-12">{{ "templates.table.templateDescription" | translate }}</th>
          <th scope="col" class="f-12">{{"assistants.table.private" | translate}}</th>
          <th scope="col" class="f-12">{{"assistants.table.createdBy" | translate}}</th>
          <th scope="col" class="pe-3"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="templates.length === 0">
          <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
            {{ "templates.noTemplates" | translate }}
          </td>
        </tr>
        <tr *ngFor="let template of templates">
          <td class="cursor-pointer f-14" (click)="editTemplate(template)">
            <div class="ps-3">
              {{ template.template_name }}
            </div>
          </td>
          <td class="cursor-pointer f-14" (click)="editTemplate(template)">{{ template.description }}</td>
          <td class="cursor-pointer f-14" (click)="editTemplate(template)">
            <span class="label private f-14" *ngIf="template.private">{{ "assistants.table.label.private" | translate }}</span>
            <span class="label public f-14" *ngIf="!template.private">{{ "assistants.table.label.public" | translate }}</span>
          </td>
          <td class="cursor-pointer" (click)="editTemplate(template)">
            <div class="profile-container">
              <img
                *ngIf="template.created_by?.profile_image_url"
                [src]="template.created_by?.profile_image_url"
                alt="Profile Image"
                class="profile-image"
              />
              <span class="f-14">{{ template.created_by?.first_name }} {{ template.created_by?.last_name }}</span>
            </div>
          </td>
          <td class="cursor-pointer">
            <div class="pe-3 d-flex justify-content-end">
              <i class="fa-regular fa-trash-can fa-lg" (click)="deleteTemplate(template)"></i>
              <i class="fa-regular fa-pen fa-lg ms-3" (click)="editTemplate(template)"></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="row d-flex justify-content-center">
        <div class="pagination col d-flex justify-content-center">
          <button *ngFor="let page1 of displayedPages"
                  (click)="selectPage(page1)"
                  [class.active]="page1 === page"
                  class="pagination-button me-2"
          >
            {{ page1 }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2"  *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white"></div>
    </div>
  </div>
</app-card>
