import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../@core/services/user.service";
import {USM_USR_18} from "../../../../@shared/models/input.module";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent {
  passwordForm: FormGroup;

  constructor(private modal: NgbActiveModal,
              private userService: UserService) {
  }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
  }

  submit(){
    if(this.passwordForm.valid){
      if(this.passwordForm.value.newPassword === this.passwordForm.value.confirmPassword){
        let payload: USM_USR_18 = {
          old_password: this.passwordForm.value.oldPassword,
          new_password: this.passwordForm.value.newPassword
        }
        this.userService.resetUserPasswordWithOldPassword(payload).subscribe(res => {
          this.modal.close();
        })
      }
    }
  }

  close() {
    this.modal.close();
  }
}
