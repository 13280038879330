import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {QuestionAnswerTypeResponse} from "../../../../@shared/models/response.module";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../../../@shared/services/template.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {_COR_AII_54, _COR_TMP_1} from "../../../../@shared/models/input.module";
import {question} from "../create-template/create-template.component";
import {AiService} from "../../../../@shared/services/ai.service";

@Component({
  selector: 'app-create-template-ai',
  templateUrl: './create-template-ai.component.html',
  styleUrl: './create-template-ai.component.css'
})
export class CreateTemplateAiComponent {
  createTemplateForm: FormGroup
  questionAnswerTypes: QuestionAnswerTypeResponse[] = [];
  questions: question[] = [];
  loading: boolean = false;

  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private aiService: AiService,
              private toastService: ToastService) {
    this.createTemplateForm = new FormGroup({
      templateName: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      templateDescription: new FormControl('', [Validators.maxLength(200)]),
      ai_instruction: new FormControl('', [Validators.maxLength(200)]),
    });
  }

  ngOnInit() {
    this.templateService.getQuestionAnswerTypes().subscribe((questionAnswerTypes: QuestionAnswerTypeResponse[]) => {
      this.questionAnswerTypes = questionAnswerTypes;
    });
  }

  close() {
    this.modal.close();
  }

  createTemplate() {
    this.loading = true;
    let payload: _COR_AII_54 = {
      template_name: this.createTemplateForm.get('templateName')?.value,
      template_description: this.createTemplateForm.get('templateDescription')?.value,
      ai_instruction: this.createTemplateForm.get('ai_instruction')?.value
    }
    this.aiService.autoGenerateTemplateWithAi(payload).subscribe((res) => {
      if (res) {
        this.templateService.templatesUpdatedSubject.next(true);
        this.toastService.showSuccess('toast.templateCreated.success', 'toast.success.title.success');
        this.loading = false;
      }
      this.modal.close(res);
    })
  }
}
