import {Component, Input} from '@angular/core';
import {AssistantGroupResponse, FileResponse, ThreadResponse} from "../../models/response.module";
import {_COR_AII_17, _COR_AII_49, _COR_AII_50} from "../../models/input.module";
import {PaginationResponse} from "../../models/global/response-wrapper.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../services/template.service";
import {AiService} from "../../services/ai.service";

@Component({
  selector: 'app-view-thread-files',
  templateUrl: './view-thread-files.component.html',
  styleUrl: './view-thread-files.component.css'
})
export class ViewThreadFilesComponent {
  @Input() thread: ThreadResponse;
  viewMoreFiles: boolean = false;
  searchString: string = '';
  assistantsResults: AssistantGroupResponse[] = [];
  searchingForAssistants: boolean = false;
  expandedAssistant: number | null = null; // Tracks the currently expanded assistant
  selectedFiles: FileResponse[] = [];

  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private aiService: AiService) {
  }

  close() {
    this.modal.close();
  }

  addFileToSelectedFiles(file: FileResponse) {
    if (this.selectedFiles.includes(file)) {
      this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
    }
    else {
      this.selectedFiles.push(file);
    }
  }

  removeFileFromSelectedFiles(file: FileResponse) {
    this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
  }

  getAllAssistantGroups() {
    this.searchingForAssistants = true;
    let params: _COR_AII_17 = {
      paginate: 0,
      search_string: this.searchString,
      search_string_columns: ["reference", "assistant_group_name"],
    }
    this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantsResults = data.data;
      this.searchingForAssistants = false;
    })
  }

  toggleAssistant(index: number): void {
    this.expandedAssistant = this.expandedAssistant === index ? null : index;
  }

  addFilesToVectorStore() {
    let params: _COR_AII_49 = {
      thread_id: this.thread.thread_id,
      file_ids: this.selectedFiles.map(file => file.ai_file_id)
    }
    this.aiService.addFilesToThreadVectorStore(params).subscribe((res) => {
      this.thread = res;
      this.selectedFiles = [];
    })
  }

  removeFileFromVectorStore(file: FileResponse) {
    let params: _COR_AII_50 = {
      thread_id: this.thread.thread_id,
      ai_file_id: file.ai_file_id
    }
    this.aiService.removeFileFromThreadVectorStore(params).subscribe((res) => {
      this.thread.vector_store.files = this.thread.vector_store.files.filter(f => f.ai_file_id != file.ai_file_id);
    })
  }

  checkFile(file: FileResponse) {
    return this.thread.vector_store.files.some(f => f.file_id === file.file_id)
  }

  filterAssistantFiles(files: FileResponse[]) {
    return files.filter(file => !this.thread.vector_store.files.some(f => f.file_id === file.file_id))
  }
}
