<div class="box d-flex align-items-center justify-content-center">
  <div>
    <div class="row d-flex align-items-center justify-content-start">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 80px">
      </div>
    </div>
    <div class="row pt-1">
      <div class="col-12">
        <p style="color: #707070">{{ "registerUser.description" | translate }}</p>
      </div>
    </div>
    <form [formGroup]="form" >
      <div class="input-group">
        <input
          formControlName="email"
          required
          [placeholder]="'login.emailLabel' | translate"
          type="text"
          id="email"
          class="form-control"
        >
      </div>
      <span *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
            class="text-danger">{{ "login.invalidEmail" | translate }}</span>

      <div class="form-group mt-3 position-relative">
        <input id="password" type="password" class="form-control" name="password" formControlName="password" required
               placeholder="{{'registerUser.passwordLabel' | translate}}" minlength="2">
      </div>
      <div class="form-group mt-3 position-relative">
        <input id="confirmPassword" type="password" class="form-control" name="password" formControlName="confirmPassword" required
               placeholder="{{'registerUser.confirmPasswordLabel' | translate}}" minlength="2">
      </div>

      <span *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
            class="text-danger">{{ "login.invalidPassword" | translate }}</span>

      <div class="form-group mt-3 ">
        <div class="d-flex justify-content-between">
          <button class="btn btn-primary btn-block" style="background-color: #5DA9D7; border-color: #5DA9D7" (click)="onSubmit()">
            {{ "registerUser.registerBtn" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div> <!-- end card-body -->
</div>
