<div class="card p-3" style="background-color: #0D0F11; max-width: 1400px">
  <div class="card-header">
    <h4 class="card-title" style="color: white">{{ titleLocalisationStrong | translate}}</h4>
  </div>
  <div class="card-body">
    <ng-content></ng-content>
  </div>
  <div class="card-footer" *ngIf="footerWithButton" style="background-color: #0D0F11!important; border-color: #0D0F11">
    <div class="d-flex justify-content-end" style="background-color: #0D0F11">
      <button type="button" class="btn btn-primary" style="background-color: #0D0F11; border-color: #0D0F11; color: white" (click)="onButtonClick()">{{buttonLocalisationString | translate}}</button>
    </div>
  </div>
</div>
