<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"profile.changePreferredAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row pt-3 pb-3 mt-0">
      <div class="col-8">
        <label class="f-16 f-bold">{{ "profile.changePreferredAssistant.label" | translate}}</label>
        <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedAssistantTypeId" (change)="changePreferredAssistantType()">
          <option *ngFor="let assistantType of assistantModels" value="{{assistantType.assistant_type_id}}">{{assistantType.assistant_type_name}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
