import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AssistantGroupResponse,
  AssistantResponse, PersonalAssistantThreadResponse,
  TemplateResponse, ThreadResponse,
  UserOptionsResponse
} from "../../../../@shared/models/response.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../../../@shared/services/template.service";
import {Router} from "@angular/router";
import {UserService} from "../../../../@core/services/user.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {_COR_AII_14, _COR_AII_17, _COR_AII_6, _COR_TMP_6, COR_AII_32} from "../../../../@shared/models/input.module";
import {PaginationResponse} from "../../../../@shared/models/global/response-wrapper.service";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-create-personal-assistant-thread',
  templateUrl: './create-personal-assistant-thread.component.html',
  styleUrl: './create-personal-assistant-thread.component.css'
})
export class CreatePersonalAssistantThreadComponent {
  @Input() assistant: AssistantResponse
  createThreadForm: FormGroup
  newThreadId: string = "";


  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private templateService: TemplateService,
              private router: Router,
              private userService: UserService,
              private toastService: ToastService) {
    this.createThreadForm = new FormGroup({
      topic: new FormControl('', [Validators.maxLength(200)]),
    });
  }

  ngOnInit() {

  }

  createThread() {
    let params: _COR_AII_6 = {
      assistant_id: this.assistant.assistant_id,
      topic: this.createThreadForm.get('topic')!.value,
      qna: 1,
      file_ids: []
    }

    this.aiService.createThread(params).subscribe((data: ThreadResponse) => {
      this.modal.close(data);
    })
  }

  close() {
    this.modal.close(false);
  }
}
