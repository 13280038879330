<div class="row spacing-30" style="max-width: 1400px" *ngIf="userData">
  <div class="col-6 d-flex flex-column pb-0">
    <div class="d-flex">
      <h2 style="color: white" class="m-0">{{ "profile.title" | translate }}</h2>
    </div>
  </div>
</div>
<div class="row spacing-30" style="max-width: 1400px" *ngIf="userData">
  <div class="col-6 d-flex flex-column">
    <div class="d-flex ">
      <p style="color: #909699" class="m-0">Here you can change your user information and update your password. You can also add a profile picture.
        You can set a preferred assistant type. This assistant type will be set as a default when you create dialogues, but you can choose other assistant types when you create a dialogue if you want to.</p>
    </div>
  </div>
</div>
<div class="row spacing-30 d-flex align-items-center" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.firstName.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex flex-column">
    <div class="input-group">
      <input
        type="text"
        class="form-control input-class"
        [(ngModel)]="userData.first_name"
      >
    </div>
  </div>
</div>
<div class="row spacing-30 d-flex align-items-center" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.lastName.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex flex-column">
    <div class="input-group">
      <input
        type="text"
        class="form-control input-class"
        [(ngModel)]="userData.last_name"
      >
    </div>
  </div>
</div>
<div class="row spacing-30 d-flex align-items-center" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.email.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex flex-column">
    <div class="input-group">
      <input
        type="text"
        class="form-control input-class"
        [(ngModel)]="userData.email"
      >
    </div>
  </div>
</div>
<div class="row spacing-30 d-flex align-items-center" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.phone.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex flex-column">
    <div class="input-group">
      <input
        type="text"
        class="form-control input-class"
        [(ngModel)]="userData.phone"
      >
    </div>
  </div>
</div>
<div class="row spacing-30 d-flex align-items-center" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.password.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex flex-column">
    <div class="input-group">
      <input
        type="password"
        class="form-control input-class"
        [(ngModel)]="randomPassword"
      >
    </div>
  </div>
  <div class="col-1">
    <div class="d-flex justify-content-end">
      <p class="m-0 cursor-pointer" style="color: #ED622E" (click)="changePassword()">Change</p>
    </div>
  </div>
</div>
<hr class="w-100" style="border-top: 1px solid #495055">
<div class="row spacing-30 d-flex align-items-center pt-2" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column justify-content-center">
    <label class="f-16 f-bold label-class">{{ "profile.editProfile.profileImage" | translate }}</label>
    <p class="m-0" style="color: #909699">This will be displayed on your profile.</p>
  </div>
  <div class="col-5 d-flex align-items-center">
    <div class="input-group">
      <div class="col-2 d-flex align-items-center">
        <img src="{{userData.profile_image_url}}" alt="Profile Image" class="profile-image me-2" *ngIf="userData.profile_image_url">
        <div *ngIf="!userData.profile_image_url" class="profile-initials-circle me-2">
          <h1>{{ initials }}</h1>
        </div>
      </div>
      <div class="col-1 d-flex align-items-center">
        <div class="d-flex justify-content-end">
          <p class="m-0 cursor-pointer" style="color: #ED622E" (click)="fileInput.click()">Update</p>
          <input #fileInput style="display: none;" type="file" (change)="onFileSelected($event)"/>
        </div>
      </div>
    </div>
  </div>
</div>
<hr class="w-100" style="border-top: 1px solid #495055">
<div class="row spacing-30 d-flex align-items-center pt-2" style="max-width: 1400px" *ngIf="userData">
  <div class="col-4 d-flex flex-column justify-content-center">
    <label class="f-16 f-bold label-class" style="box-shadow: none">{{ "profile.changePreferredAssistant.label" | translate }}</label>
  </div>
  <div class="col-5 d-flex align-items-center">
    <select class="form-select input-class w-100" style="box-shadow: none" aria-label="Default select example" [(ngModel)]="selectedAssistantTypeId" (change)="changePreferredAssistantType()">
      <option *ngFor="let assistantType of assistantModels" value="{{assistantType.assistant_model_id}}">{{assistantType.assistant_type_name}}</option>
    </select>
  </div>
</div>
<!--<hr class="w-100" style="border-top: 1px solid #495055">-->
<!--<div class="row spacing-30 d-flex align-items-center pt-2" style="max-width: 1400px" *ngIf="userData">-->
<!--  <div class="col-4 d-flex flex-column justify-content-center">-->
<!--    <label class="f-16 f-bold label-class">{{ "profile.editProfile.systemLanguage" | translate }}</label>-->
<!--  </div>-->
<!--  <div class="col-5 d-flex align-items-center">-->
<!--    <select class="form-select input-class w-100" style="box-shadow: none" aria-label="Default select example" [(ngModel)]="selectedLanguage" (change)="changeLanguage()">-->
<!--      <option value="no">{{"global.norwegian" | translate}}</option>-->
<!--      <option value="en">{{"global.english" | translate}}</option>-->
<!--    </select>-->
<!--  </div>-->
<!--</div>-->
