import { Component } from '@angular/core';
import {AuthService} from "../../@core/services/auth.service";
import {AiService} from "../../@shared/services/ai.service";
import {Thread} from "@sentry/angular-ivy";
import {
  AssistantGroupResponse,
  AssistantResponse,
  ThreadResponse,
  UserResponse
} from "../../@shared/models/response.module";
import {_COR_AII_17, _COR_AII_5} from "../../@shared/models/input.module";
import {UserService} from "../../@core/services/user.service";
import {StorageService} from "../../@core/services/storage.service";
import {PaginationResponse} from "../../@shared/models/global/response-wrapper.service";
import {th} from "date-fns/locale";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateThreadComponent} from "../threads/components/create-thread/create-thread.component";
import {ReleaseNotesComponent} from "./components/release-notes/release-notes.component";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  loadingAssistants: boolean = false;
  loadingThreads: boolean = false;
  recentThreads: ThreadResponse[] = [];
  recentAssistants: AssistantGroupResponse[] = [];
  user: UserResponse

  constructor(private aiService: AiService,
              private authService: AuthService,
              private userService: UserService,
              private storageService: StorageService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getLatestThreads();
    this.getLatestAssistants()
    this.user = this.storageService.getUser()
  }

  getLatestThreads() {
    this.loadingThreads = true;
    let params: _COR_AII_5 = {
      paginate: 1,
      page: 1,
      limit: 7,
      qna: 0,
    }
    this.aiService.getThreads(params).subscribe((data) => {
      this.recentThreads = data.data;
      this.loadingThreads = false;
      console.log(this.recentThreads);
    })
  }

  getLatestAssistants() {
    this.loadingAssistants = true;
    let params: _COR_AII_17 = {
      paginate: 1,
      page: 1,
      limit: 7,
    }
    this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.recentAssistants = data.data;
      this.loadingAssistants = false;
      console.log(this.recentAssistants);
    })
  }

  goToThread(thread: ThreadResponse) {
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  createThread(assistantGroup: AssistantGroupResponse) {
    let modalRef = this.modalService.open(CreateThreadComponent, {size: 'lg'});
    modalRef.componentInstance.assistantGroupId = assistantGroup.assistant_group_id;
    modalRef.result.then((result) => {
      this.getLatestThreads();
    }, (reason) => {
      console.log(reason);
    })
  }

  showReleaseNotes() {
    let modalRef = this.modalService.open(ReleaseNotesComponent, {size: 'lg', centered: true, keyboard: false});
  }

}
