import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrl: './view-file.component.css'
})
export class ViewFileComponent {
  @Input() file_id: number;
  @Input() file_name: string;

  constructor(private modal: NgbActiveModal) {
  }

  close(){
    this.modal.close();
  }
}
