<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"assistants.editAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can edit the assistant details <br> Press "Save" to save your changes.</p>
      </div>
    </div>
    <form [formGroup]="editAssistantForm">
      <div class="row">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.assistantName.label" | translate}}</label>
          <div class="input-group spacing-30">
            <input
              type="text"
              class="form-control input-class"
              formControlName="assistant_name"
              placeholder="{{'assistants.createAssistant.assistantName' | translate}}"
            >
          </div>
          <span *ngIf="editAssistantForm.get('assistant_name')?.invalid && editAssistantForm.get('assistant_name')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.reference.label" | translate}}</label>
          <div class="input-group spacing-30">
            <input
              type="text"
              class="form-control input-class"
              formControlName="reference"
              placeholder="{{'assistants.createAssistant.reference.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.description.label" | translate}}</label>
          <div class="input-group spacing-30">
            <input
              type="text"
              class="form-control input-class"
              formControlName="assistant_group_description"
              placeholder="{{'assistants.createAssistant.description.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold label-class" for="flexSwitchCheckDefault">{{ "assistants.createAssistant.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="updateAssistant()">{{ "assistants.editAssistant.editBtn" | translate }}</div>
  </div>
</div>

