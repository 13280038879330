<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"companySettings.inviteEmployees.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <form [formGroup]="inviteForm">
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.firstName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="first_name"
            >
          </div>
<!--          <span *ngIf="inviteForm.get('first_name')?.invalid && inviteForm.get('first_name')?.touched"-->
<!--                class="text-danger">{{ "global.requiredField" | translate }}</span>-->
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.lastName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="last_name"
            >
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.email.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="email"
            >
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.phone.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="phone"
            >
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.role.label" | translate}}</label>
          <select class="form-select input-class" style="box-shadow: none" aria-label="Default select example" formControlName="role_id">
            <option value="" selected>{{'companySettings.inviteEmployees.role.placeholder' | translate}}</option>
            <option value="1">{{'companySettings.inviteEmployees.role.admin' | translate}}</option>
            <option value="2">{{'companySettings.inviteEmployees.role.user' | translate}}</option>
          </select>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.access.label" | translate}}</label>
          <select class="form-select input-class" style="box-shadow: none" aria-label="Default select example" formControlName="component_id">
            <option value="" selected>{{'companySettings.inviteEmployees.access.placeholder' | translate}}</option>
            <option value="1">{{'companySettings.inviteEmployees.access.personal' | translate}}</option>
            <option value="2">{{'companySettings.inviteEmployees.access.master' | translate}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="inviteEmployee()">
      {{ "companySettings.inviteEmployees.inviteBtn" | translate }}
    </div>
  </div>
</div>
