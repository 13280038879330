<div class="left-nav-bar" >
  <div class="logo mb-0">
    <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 60px;">
    <div *ngIf="environment.envName == 'sandbox'" class="sandbox-label ms-2" style="color: red; font-weight: bold;">Beta</div>
  </div>
  <div class="sandbox-label">

  </div>
  <nav style="margin-top: 50px">
    <ul class="top-items" *ngIf="userObj">
      <li style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/dashboard')}" class="btn m-0" style="width: 100%">
          <a
            routerLink="/dashboard"
            routerLinkActive="active"
            (mouseover)="hoverClass = '/dashboard'"
            (mouseout)="hoverClass = ''">
            <i class="far fa-home"></i> {{ "sidebar.home" | translate }}
          </a>
        </div>
      </li>
      <li *ngIf="userObj.component_access_id == '1' || userObj.component_access_id == '2'" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/personal-assistant')}" class="btn" style="width: 100%">
          <a
            routerLink="/personal-assistant"
            routerLinkActive="active"
            (mouseover)="hoverClass = '/personal-assistant'"
            (mouseout)="hoverClass = ''">
            <i class="far fa-person-running-fast"></i> {{ "sidebar.personalAssistant" | translate }}
          </a>
        </div>
      </li>
      <li *ngIf="userObj.component_access_id == '2'" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/assistants')}" class="btn" style="width: 100%">
          <a
            routerLink="/assistants"
            routerLinkActive="active"
            (mouseover)="hoverClass = '/assistants'"
            (mouseout)="hoverClass = ''">
            <i class="far fa-users"></i> {{ "sidebar.assistants" | translate }}
          </a>
        </div>
      </li>
      <li *ngIf="userObj.component_access_id == '2'" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/threads')}" class="btn" style="width: 100%">
          <a
            routerLink="/threads"
            routerLinkActive="active"
            (mouseover)="hoverClass = '/threads'"
            (mouseout)="hoverClass = ''">
            <i class="far fa-comment"></i> {{ "sidebar.threads" | translate }}
          </a>
        </div>
      </li>
      <li *ngIf="userObj.component_access_id == '2'" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/templates')}" class="btn" style="width: 100%">
          <a
            routerLink="/templates"
            routerLinkActive="active"
            (mouseover)="hoverClass = '/templates'"
            (mouseout)="hoverClass = ''">
            <i class="far fa-circle-question"></i> {{ "sidebar.templates" | translate }}
          </a>
        </div>
      </li>
    </ul>

    <div class="center-items">
      <div *ngIf="userFavorites && userFavorites.length > 0" class="favorites-container">
        <!-- Fixed header (not inside ul) -->
        <div class="favorites-header">
          <i class="far fa-star me-2"></i>Favorites
        </div>

        <!-- Scrollable ul for the li items only -->
        <ul class="favorites-list">
          <li
            *ngFor="let favourite of userFavorites"
            class="m-1 ps-4 cursor-pointer"
            title="{{ favourite.assistant_group.assistant_group_name }}"
            (click)="goToAssistantDetails(favourite)"
          >
            {{ favourite.assistant_group.assistant_group_name }}
          </li>
        </ul>
      </div>
    </div>

    <ul class="bottom-items">
      <li *ngIf="(user && user.role_id == 1) || superAdmin" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/settings')}" class="btn" style="width: 100%">
          <a routerLink="/settings"
             routerLinkActive="active"
             (mouseover)="hoverClass = '/settings'"
             (mouseout)="hoverClass = ''">
            <i class="far fa-gear"></i> {{ "sidebar.settings" | translate }}
          </a>
        </div>
      </li>
      <li *ngIf="superAdmin" style="margin: 10px">
        <div [ngClass]="{'btn-primary': isActive('/super-admin')}" class="btn" style="width: 100%">
          <a routerLink="/super-admin"
             routerLinkActive="active"
             (mouseover)="hoverClass = '/super-admin'"
             (mouseout)="hoverClass = ''">
            <i class="far fa-lock"></i> {{ "sidebar.superAdmin" | translate }}
          </a>
        </div>
      </li>
      <li>
        <form [formGroup]="form" *ngIf="superAdmin">
          <select class="form-select me-3" aria-label="Default select example" style="" formControlName="selectedCompanyId"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'threads.createThread.assistantLabel' | translate}}</option>
            <option *ngFor="let company of companies" [value]="company.company_id">
              {{ company.company_name }}
            </option>
          </select>
        </form>
      </li>
      <li class="d-flex justify-content-between align-items-center">
        <!-- User profile dropdown -->
        <div class="d-flex align-items-center">
          <div class="dropdown" *ngIf="userObj">
            <button class="dropbtn ps-0" routerLink="/profile">
              <img src="{{userObj.profile_image_url}}" alt="Profile Image" class="profile-image " *ngIf="userObj.profile_image_url">
              <div *ngIf="!userObj.profile_image_url" class="profile-initials-circle">
                {{ initials }}
              </div>
            </button>
<!--            <div class="dropdown-content" style="border-radius: 5px">-->
<!--              <a routerLink="/profile" class="cursor-pointer">Profile</a>-->
<!--              <a class="cursor-pointer">Logout</a>-->
<!--            </div>-->
          </div>
          <p class="mb-0" style="font-weight: 600" *ngIf="userObj">{{userObj.first_name}} {{userObj.last_name}}</p>
        </div>
        <i class="fa fa-regular fa-sign-out" (click)="signOut()"></i>
      </li>
    </ul>
  </nav>
</div>
