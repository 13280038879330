import {NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler} from "@angular/core";
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {environment} from "../environments/environment";
import { FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { LoginComponent } from './pages/auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LayoutComponent} from "./pages/auth/layout/layout.component";
import { SidebarComponent } from './@shared/components/sidebar/sidebar.component';
import { TopbarComponent } from './@shared/components/topbar/topbar.component';
import { FooterComponent } from './@shared/components/footer/footer.component';
import { CardComponent } from './@shared/components/card/card.component';
import { QnaComponent } from './pages/qna/qna.component';
import { QuestionTemplatesComponent } from './pages/question-templates/question-templates.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateTemplateComponent } from './pages/question-templates/components/create-template/create-template.component';
import { EditTemplateComponent } from './pages/question-templates/components/edit-template/edit-template.component';
import { QuestionComponent } from './pages/question-templates/components/question/question.component';
import { AssistantsComponent } from './pages/assistants/assistants.component';
import { CreateAssistantComponent } from './pages/assistants/components/create-assistant/create-assistant.component';
import { EditAssistantComponent } from './pages/assistants/components/edit-assistant/edit-assistant.component';
import { ThreadsComponent } from './pages/threads/threads.component';
import { ViewThreadComponent } from './pages/threads/components/view-thread/view-thread.component';
import { CreateThreadComponent } from './pages/threads/components/create-thread/create-thread.component';
import { EditThreadComponent } from './pages/threads/components/edit-thread/edit-thread.component';
import {MarkdownComponent, MarkdownModule} from "ngx-markdown";
import { UploadFileComponent } from './pages/qna/components/upload-file/upload-file.component';
import { HomeComponent } from './pages/home/home.component';
import { ChooseAssistantComponent } from './pages/assistants/components/choose-assistant/choose-assistant.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DeleteModalComponent } from './@shared/components/delete-modal/delete-modal.component';
import { ChangeLanguageComponent } from './pages/profile/components/change-language/change-language.component';
import { ChangePasswordComponent } from './pages/profile/components/change-password/change-password.component';
import { ChangePreferredAssistantComponent } from './pages/profile/components/change-preferred-assistant/change-preferred-assistant.component';
import { AssistantDetailsComponent } from './pages/assistant-details/assistant-details.component';
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AddDocumentsComponent } from './pages/assistant-details/components/add-documents/add-documents.component';
import { EditProfileComponent } from './pages/profile/components/edit-profile/edit-profile.component';
import { CompareComponent } from './pages/threads/components/view-thread/components/compare/compare.component';
import { InviteEmployeesComponent } from './pages/settings/components/invite-employees/invite-employees.component';
import { RegisterUserComponent } from './pages/auth/register-user/register-user.component';
import { EditEmployeeComponent } from './pages/settings/components/edit-employee/edit-employee.component';
import { ForgotPassordComponent } from './pages/auth/forgot-passord/forgot-passord.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ShareComponent } from './pages/assistant-details/components/share/share.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import { ViewPdfComponent } from './@shared/components/view-pdf/view-pdf.component';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {Router} from "@angular/router";
import { SuperAdminComponent } from './pages/super-admin/super-admin.component';
import { CompanyAdministrationComponent } from './pages/super-admin/components/company-administration/company-administration.component';
import { CreateRoleComponent } from './pages/super-admin/components/company-administration/components/create-role/create-role.component';
import { EditRoleComponent } from './pages/super-admin/components/company-administration/components/edit-role/edit-role.component';
import { CreateCompanyComponent } from './pages/super-admin/components/create-company/create-company.component';
import {ChatWidgetComponent} from "./@shared/components/chat-widget/chat-widget.component";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViewFileComponent } from './pages/assistant-details/components/view-file/view-file.component';
import { PersonalAssistantComponent } from './pages/personal-assistant/personal-assistant.component';
import { CreatePersonalAssistantThreadComponent } from './pages/personal-assistant/components/create-personal-assistant-thread/create-personal-assistant-thread.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    CardComponent,
    QnaComponent,
    QuestionTemplatesComponent,
    CreateTemplateComponent,
    EditTemplateComponent,
    QuestionComponent,
    AssistantsComponent,
    CreateAssistantComponent,
    EditAssistantComponent,
    ThreadsComponent,
    ViewThreadComponent,
    CreateThreadComponent,
    EditThreadComponent,
    UploadFileComponent,
    HomeComponent,
    ChooseAssistantComponent,
    SettingsComponent,
    ProfileComponent,
    DeleteModalComponent,
    ChangeLanguageComponent,
    ChangePasswordComponent,
    ChangePreferredAssistantComponent,
    AssistantDetailsComponent,
    AddDocumentsComponent,
    EditProfileComponent,
    CompareComponent,
    InviteEmployeesComponent,
    RegisterUserComponent,
    EditEmployeeComponent,
    ForgotPassordComponent,
    ResetPasswordComponent,
    ShareComponent,
    ViewPdfComponent,
    ExamplePdfViewerComponent,
    SuperAdminComponent,
    CompanyAdministrationComponent,
    CreateRoleComponent,
    EditRoleComponent,
    CreateCompanyComponent,
    ChatWidgetComponent,
    ViewFileComponent,
    PersonalAssistantComponent,
    CreatePersonalAssistantThreadComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:5000'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
    }),
    NgbModule,
    FormsModule,
    MarkdownModule.forRoot(), // Import NgxMarkdownModule
    ToastrModule.forRoot(),
    NgxExtendedPdfViewerModule,

  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),

    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
