import {Component, Input} from '@angular/core';
import {AiService} from "../../../../@shared/services/ai.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {_COR_AII_10} from "../../../../@shared/models/input.module";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrl: './add-documents.component.css'
})
export class AddDocumentsComponent {
  @Input() assistantGroupId: string;
  selectedFiles: File[] = [];
  uploading: boolean = false;

  constructor(private aiService: AiService,
              private toastService: ToastService,
              private modal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      for (let file of Array.from(files)) {
        this.selectedFiles!.push(file);
      }
    }
  }

  async uploadFilesSequentially() {
    this.uploading = true;
    for (let file of this.selectedFiles!) {
      let params: _COR_AII_10 = {
        assistant_group_id: this.assistantGroupId,
        file: file
      };
      this.aiService.uploadFile(params).subscribe();
    }
    await this.delay(3);
    this.uploading = false;
    this.toastService.showSuccess('toast.success.title.success', 'toast.uploadFile.success');
    this.modal.close(true);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  close() {
    this.modal.close(false);
  }

}
