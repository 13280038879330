import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../../@core/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../@core/services/user.service";
import {USM_USR_6} from "../../../../@shared/models/input.module";
import {StorageService} from "../../../../@core/services/storage.service";

@Component({
  selector: 'app-invite-employees',
  templateUrl: './invite-employees.component.html',
  styleUrl: './invite-employees.component.css'
})
export class InviteEmployeesComponent {
  @Input() company_id: string;
  inviteForm: FormGroup;
  constructor(private modal: NgbActiveModal,
              private toastService: ToastService,
              private userService: UserService,
              private storageService: StorageService) { }

  ngOnInit() {
    this.inviteForm = new FormGroup({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(200)]),
      phone: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      role_id: new FormControl('', [Validators.required]),
      component_id: new FormControl([])
    });
  }

  close() {
    this.modal.close();
  }

  inviteEmployee() {
    let params: USM_USR_6 = {
      first_name: this.inviteForm.value.first_name,
      last_name: this.inviteForm.value.last_name,
      email: this.inviteForm.value.email,
      phone: this.inviteForm.value.phone,
      role_id: parseInt(this.inviteForm.value.role_id),
      entity_id: this.company_id ? this.company_id : this.storageService.getSelectedCompanyId(),
      skip_invitation: 0,
      component_id: this.inviteForm.value.component_id
    }

    this.userService.inviteUserToEntity(params).subscribe((data) => {
      this.toastService.showSuccess('toast.settings.employeeInvite.success', 'toast.success.title.success');
      this.modal.close();
    })
  }

}
