import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AiService} from "../../../../../../@shared/services/ai.service";
import {_COR_AII_10, _COR_AII_14} from "../../../../../../@shared/models/input.module";
import {ThreadResponse} from "../../../../../../@shared/models/response.module";

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrl: './compare.component.css'
})
export class CompareComponent {
  @Input() thread: ThreadResponse
  selectedFile: File;
  loading: boolean = false;

  constructor(private modal: NgbActiveModal,
              private aiService: AiService) {
  }

  addNewMessageToThread() {
    let messageInput = 'I have uploaded a new document that will serve as a reference for certain terms, policies, or conditions. Please thoroughly analyze the previously uploaded documents and compare them with this reference document. I need a detailed report covering the following aspects:\n' +
      '\n' +
      '\t1.\tDiscrepancies: Identify and describe any differences between the content of the previously uploaded documents and the reference document. Provide specific examples with references to the exact sections, clauses, or items in both sets of documents.\n' +
      '\t2.\tConflicts: Highlight any conflicting terms, conditions, or policies between the previously uploaded documents and the reference document. Explain how each conflict could impact the interpretation or application of the terms.\n' +
      '\t3.\tNon-Compliance Issues: Point out any areas where the previously uploaded documents do not adhere to the standards, policies, or conditions outlined in the reference document. Describe the nature of the non-compliance and its potential implications.\n' +
      '\t4.\tAdditional Insights: Provide any observations or potential areas of concern, even if they do not constitute direct conflicts or discrepancies. This could include ambiguities, vague language, or anything that may require further review.\n' +
      '\t5.\tSummary: Conclude with a summary of the key findings, highlighting the most critical issues that may need immediate attention or resolution.\n' +
      '\n' +
      'Please ensure that the report is well-structured and includes clear references to the specific parts of the documents being compared.';
    if (this.selectedFile) {
      let file_params: _COR_AII_10 = {
        file: this.selectedFile
      }
      this.aiService.uploadFile(file_params).subscribe((res) => {
        let message_params: _COR_AII_14 = {
          thread_id: this.thread.thread_id,
          input: messageInput,
          file_id: res.ai_file_id,
          display_message: 0
        }
        this.aiService.addMessageToThread(message_params).subscribe((res) => {
          this.loading = false;
          this.modal.close(res);
        })
      })
    }
  }


  onFileSelected(event: any) {
    this.loading = true;
    this.selectedFile = event.target.files[0];
    this.addNewMessageToThread()

  }

  close() {
    this.modal.close();
  }

}
