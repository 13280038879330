import {Injectable} from "@angular/core";
import {EndpointService} from "../endpoints.service";
import {
  _COR_TMP_1, _COR_TMP_10, _COR_TMP_11, _COR_TMP_12,
  _COR_TMP_2,
  _COR_TMP_4,
  _COR_TMP_5, _COR_TMP_6, _COR_TMP_8, _COR_TMP_9,
  COR_TMP_0, COR_TMP_1, COR_TMP_10, COR_TMP_11, COR_TMP_12,
  COR_TMP_2,
  COR_TMP_4,
  COR_TMP_5,
  COR_TMP_6,
  COR_TMP_7, COR_TMP_8, COR_TMP_9
} from "../models/input.module";
import {StorageService} from "../../@core/services/storage.service";
import {distinctUntilChanged, map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  public templatesUpdatedSubject = new BehaviorSubject<Boolean>(false);
  public templatesUpdated = this.templatesUpdatedSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private endpointService: EndpointService,
              private storageService: StorageService) {
  }

  getTemplateById(template_id: string) {
    let payload: COR_TMP_0 = {
      company_id: this.storageService.getSelectedCompanyId(),
      template_id: template_id
    }
    return this.endpointService.cor_tmp_0(payload).pipe(map((data => {
      return data.data;
    })))
  }

  createTemplate(payload: _COR_TMP_1) {
    let params: COR_TMP_1 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_1(params).pipe(map((data => {
      return data.data;
    })))
  }

  editTemplate(payload: _COR_TMP_2) {
    let params: COR_TMP_2 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_2(params).pipe(map((data => {
      return data.data;
    })))
  }

  deleteTemplate(payload: _COR_TMP_8) {
    let params: COR_TMP_8 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_8(params).pipe(map((data => {
      return data;
    })))
  }

  addQuestionToTemplate(payload: _COR_TMP_4) {
    let params: COR_TMP_4 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_4(params).pipe(map((data => {
      return data.data;
    })))
  }

  editQuestion(payload: _COR_TMP_5) {
    let params: COR_TMP_5 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_5(params).pipe(map((data => {
      return data.data;
    })))
  }

  deleteQuestion(payload: _COR_TMP_9) {
    let params: COR_TMP_9 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_9(params).pipe(map((data => {
      return data;
    })))
  }

  getAllTemplates(payload: _COR_TMP_6) {
    let params: COR_TMP_6 = {
      company_id: this.storageService.getSelectedCompanyId(),
        ...payload
    }
    return this.endpointService.cor_tmp_6(params).pipe(map((data => {
      return data;
    })))
  }

  getQuestionAnswerTypes() {
    let payload: COR_TMP_7 = {
      company_id: this.storageService.getSelectedCompanyId()
    }

    return this.endpointService.cor_tmp_7(payload).pipe(map((data => {
      return data.data;
    })))
  }

  createTemplateAccessRelation(payload: _COR_TMP_10) {
    let params: COR_TMP_10 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_10(params).pipe(map((data => {
      return data;
    })))
  }

  editTemplateAccessRelation(payload: _COR_TMP_11) {
    let params: COR_TMP_11 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_11(params).pipe(map((data => {
      return data;
    })))
  }

  deleteTemplateAccessRelation(payload: _COR_TMP_12) {
    let params: COR_TMP_12 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.cor_tmp_12(params).pipe(map((data => {
      return data;
    })))
  }
}
