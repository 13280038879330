<div class="row pb-4" style="max-width: 1400px" *ngIf="userData">
  <div class="col-6">
    <div class="card position-relative" style="background-color: #0D0F11">
      <!-- Edit Icon -->
      <div class="edit-icon" style="position: absolute; top: 30px; right: 30px;">
        <a style="color: white;" (click)="editProfile()">
          <i class="fas fa-edit fa-xl"></i>
        </a>
      </div>

      <div class="card-body" style="padding: 50px">
        <div class="row" style="height: 150px">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="{{userData.profile_image_url}}" alt="Profile Image" class="profile-image me-2" *ngIf="userData.profile_image_url">
            <div *ngIf="!userData.profile_image_url" class="profile-initials-circle me-2">
              <h1>{{ initials }}</h1>
            </div>
          </div>
        </div>
        <div class="row" style="height: 150px">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <h2 style="color: white">{{userData.first_name}} {{userData.last_name}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row pb-3">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">Phone</label>
                <p style="color: white">{{userData.phone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">Email</label>
                <p style="color: white">{{userData.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card" style="background-color: #0D0F11">
      <div class="card-body" style="padding: 50px">
        <div class="row pb-3">
          <div class="col-12 ">
            <h2 style="color: white">Actions</h2>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12 ">
            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%" (click)="changePreferredAssistantType()">Change Preferred Assistant Type</div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12 ">
            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%" (click)="changePassword()">Change Password</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 ">
            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%" (click)="changeLanguage()">Change Language</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="row" style="max-width: 1400px">-->
<!--  <div class="col-12">-->
<!--    <div class="card p-3" style="background-color: #191D23">-->
<!--      <div class="card-header">-->
<!--        <h5 style="color: white">Employees</h5>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
