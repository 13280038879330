import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../../../@shared/services/template.service";
import {
  AssistantGroupResponse,
  AssistantResponse, FileResponse,
  TemplateResponse,
  ThreadResponse, UserOptionsResponse
} from "../../../../@shared/models/response.module";
import {
  _COR_AII_0,
  _COR_AII_12,
  _COR_AII_13,
  _COR_AII_14, _COR_AII_17,
  _COR_AII_6, _COR_TMP_6,
  COR_AII_0
} from "../../../../@shared/models/input.module";
import {Router} from "@angular/router";
import {forkJoin} from "rxjs";
import {UserService} from "../../../../@core/services/user.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {PaginationResponse} from "../../../../@shared/models/global/response-wrapper.service";

@Component({
  selector: 'app-create-thread',
  templateUrl: './create-thread.component.html',
  styleUrl: './create-thread.component.css'
})
export class CreateThreadComponent {
  @Input() details: boolean = false;
  @Input() assistantGroupId: string;
  @Input() selectedFiles: FileResponse[] = [];
  createThreadForm: FormGroup
  templates: TemplateResponse[] = [];
  assistantsGroups: AssistantGroupResponse[] = [];
  assistants: AssistantResponse[] = [];
  newThreadId: string = "";
  filteredAssistants: AssistantResponse[] = [];
  userOptions: UserOptionsResponse
  viewMoreFiles: boolean = false;
  searchString: string = '';
  assistantsResults: AssistantGroupResponse[] = [];
  searchingForAssistants: boolean = false;
  expandedAssistant: number | null = null; // Tracks the currently expanded assistant



  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private templateService: TemplateService,
              private router: Router,
              private userService: UserService,
              private toastService: ToastService) {
    this.createThreadForm = new FormGroup({
      template_id: new FormControl('', [Validators.maxLength(200)]),
      assistant_group_id: new FormControl('', {validators: [Validators.required, Validators.maxLength(200)]}),
      assistant_id: new FormControl('', {validators: [Validators.required, Validators.maxLength(200)]}),
      topic: new FormControl('', [Validators.maxLength(200)]),
    });
  }

  ngOnInit() {
    this.getAllTemplates()
    this.getAllAssistantsGroups()
    this.getUserOptions()
    console.log('selected file', this.selectedFiles)
  }

  getUserOptions() {
    this.userService.getUserOptions().subscribe((data: UserOptionsResponse) => {
      this.userOptions = data;
    })
  }
  getAllTemplates() {
    let params: _COR_TMP_6 = {}
    this.templateService.getAllTemplates(params).subscribe((data) => {
      this.templates = data.data;
    })
  }

  getAllAssistantsGroups() {
    if (this.assistantGroupId) {
      let params: _COR_AII_17 = {assistant_group_id: this.assistantGroupId}
      this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
        this.createThreadForm.get('assistant_group_id')!.setValue(this.assistantGroupId);
        const selectedGroup = data.data.find(group => group.assistant_group_id == this.assistantGroupId);
        if (selectedGroup) {
          // Filter assistants for the selected group
          this.filteredAssistants = selectedGroup.assistants;

          // Set the assistant_id form control to the preferred assistant type
          const preferredAssistant = this.filteredAssistants.find(
            assistant => assistant.assistant_type_id == this.userOptions.preferred_assistant_type_id
          );

          if (preferredAssistant) {
            this.createThreadForm.get('assistant_id')!.setValue(preferredAssistant.assistant_id);
          }
        }
      })
      // this.onGroupChange(new Event('change'));
    }
    else {
      let params: _COR_AII_17 = {}
      this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
        this.assistantsGroups = data.data;
      })
    }
  }

  onGroupChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const groupId = target.value;
    const selectedGroup = this.assistantsGroups.find(group => group.assistant_group_id == groupId);
    this.filteredAssistants = selectedGroup!.assistants;
    this.createThreadForm.get('assistant_id')!.setValue(this.filteredAssistants.find((assistant) => assistant.assistant_model_id = this.userOptions.preferred_assistant_type_id)?.assistant_id); // Reset the assistant dropdown
  }

  createThread() {
    let params: _COR_AII_6 = {
      template_id: this.createThreadForm.get('template_id')!.value ? this.createThreadForm.get('template_id')!.value : null,
      assistant_id: this.createThreadForm.get('assistant_id')!.value,
      qna: 0,
      topic: this.createThreadForm.get('topic')!.value ? this.createThreadForm.get('topic')!.value : null,
      file_ids: this.selectedFiles.map(file => file.ai_file_id),
    }

    this.aiService.createThread(params).subscribe((res) => {
      this.newThreadId = res.thread_id;
      this.toastService.showSuccess('toast.threadCreated.success', 'toast.success.title.success');
      if (this.createThreadForm.get('template_id')!.value) {
        this.templateService.getTemplateById(this.createThreadForm.get('template_id')!.value).subscribe((data: TemplateResponse) => {
          const observables = [];

          for (let question of data.questions) {
            let params: _COR_AII_14 = {
              thread_id: res.thread_id,
              input: question.question,
              file_id: null,
            };
            observables.push(this.aiService.addMessageToThread(params));
          }
          // Use forkJoin to wait for all the observables to complete
          forkJoin(observables).subscribe(results => {
            // All the addMessageToThread calls have completed
            this.goToThread(res);
          });
        })
      }
      this.goToThread(res);
      this.modal.close(res);
    })
  }

  goToThread(thread: ThreadResponse) {
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  close() {
    this.modal.close(false);
  }

  addFileToSelectedFiles(file: FileResponse) {
    if (this.selectedFiles.includes(file)) {
      this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
    }
    else {
      this.selectedFiles.push(file);
    }
  }

  removeFileFromSelectedFiles(file: FileResponse) {
    this.selectedFiles = this.selectedFiles.filter(selectedFile => selectedFile.ai_file_id != file.ai_file_id);
  }

  getAllAssistantGroups() {
    this.searchingForAssistants = true;
    let params: _COR_AII_17 = {
      paginate: 0,
      search_string: this.searchString,
      search_string_columns: ["reference", "assistant_group_name"],
    }
    this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantsResults = data.data;
      this.searchingForAssistants = false;
    })
  }

  toggleAssistant(index: number): void {
    this.expandedAssistant = this.expandedAssistant === index ? null : index;
  }
}
