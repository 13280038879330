<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.editAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="editAssistantForm">
      <div class="row">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.assistantName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="assistant_name"
              placeholder="{{'assistants.createAssistant.assistantName' | translate}}"
            >
          </div>
          <span *ngIf="editAssistantForm.get('assistant_name')?.invalid && editAssistantForm.get('assistant_name')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.reference.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="reference"
              placeholder="{{'assistants.createAssistant.reference.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"assistants.createAssistant.description.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="assistant_group_description"
              placeholder="{{'assistants.createAssistant.description.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold" for="flexSwitchCheckDefault">{{ "assistants.createAssistant.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="updateAssistant()">{{ "assistants.editAssistant.editBtn" | translate }}</div>
  </div>
</div>

