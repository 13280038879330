import { Component } from '@angular/core';
import {CompanyService} from "../../../../@shared/services/company.service";
import {ActivatedRoute} from "@angular/router";
import {
  AssistantModelResponse,
  CompanyResponse, MessagesStatsResponse,
  UserEntityRelationWithUserDataResponse
} from "../../../../@shared/models/response.module";
import {COR_AII_25, COR_AII_28, COR_SER_1, USM_ENT_3} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";
import {AiService} from "../../../../@shared/services/ai.service";
import {formatTimeDMYHHMM} from "../../../../@core/utils/utils.service";
import {InviteEmployeesComponent} from "../../../settings/components/invite-employees/invite-employees.component";
import {EditEmployeeComponent} from "../../../settings/components/edit-employee/edit-employee.component";
import {DeleteModalComponent} from "../../../../@shared/components/delete-modal/delete-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "../../../../@core/services/user.service";
import {CreateRoleComponent} from "./components/create-role/create-role.component";
import {EditRoleComponent} from "./components/edit-role/edit-role.component";

@Component({
  selector: 'app-company-administration',
  templateUrl: './company-administration.component.html',
  styleUrl: './company-administration.component.css'
})
export class CompanyAdministrationComponent {
  company: CompanyResponse
  assistantModels: AssistantModelResponse[] = []
  employees: UserEntityRelationWithUserDataResponse[] = []
  messageStats: MessagesStatsResponse;

  constructor(private route: ActivatedRoute,
              private companyService: CompanyService,
              private toastService: ToastService,
              private aiService: AiService,
              private modalService: NgbModal,
              private userService: UserService) {
  }

  ngOnInit() {
    this.companyService.getCompanyById({company_id: String(this.route.snapshot.paramMap.get('id'))}).subscribe((data) => {
      this.company = data;
      this.getAssistantModels();
      this.getEmployees();
      this.getMessageStats();
    });
  }

  getMessageStats() {
    let payload: COR_SER_1 = {
      company_id: this.company.company_id
    }
    this.companyService.getMessagesStats(payload).subscribe((data: MessagesStatsResponse) => {
      this.messageStats = data;
    })
  }

  getAssistantModels() {
    let params: COR_AII_25 = {
      company_id: this.company.company_id
    }
    this.aiService.getAssistantModel(params).subscribe((data) => {
      this.assistantModels = data.data;
    })
  }

  getEmployees() {
    this.companyService.getCompanyEmployees(this.company.company_id).subscribe((data: UserEntityRelationWithUserDataResponse[]) => {
      this.employees = data;
    });
  }

  inviteEmployees() {
    let modalRef = this.modalService.open(InviteEmployeesComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.company_id = this.company.company_id;
    modalRef.result.then((result) => {
      this.getEmployees();
    })
  }

  editEmployee(user: UserEntityRelationWithUserDataResponse) {
    let modalRef = this.modalService.open(EditEmployeeComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.user = user;
    modalRef.result.then((result) => {
      if (result) {
        this.getEmployees();
      }
    })
  }

  removeEmployeeFromEntity(employee: UserEntityRelationWithUserDataResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "companySettings.deleteEmployee.title";
    modalRef.result.then((result) => {
      // Implement your logic to remove employee from entity here
      let payload: USM_ENT_3 = {
        user_id: employee.user_id,
        entity_id: this.company.company_id
      }
      this.userService.removeUserFromEntity(payload).subscribe((res) => {
        this.toastService.showSuccess('toast.settings.removeEmployee.success', 'toast.success.title.success');
        this.getEmployees();
      })
    })
  }

  createRole() {
    let modalRef = this.modalService.open(CreateRoleComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.company_id = this.company.company_id;
    modalRef.result.then((result) => {
      if (result) {
        this.getAssistantModels();
      }
    })
  }

  editRole(assistantModel: AssistantModelResponse) {
    let modalRef = this.modalService.open(EditRoleComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.company_id = this.company.company_id;
    modalRef.componentInstance.assistantModel = assistantModel;
    modalRef.result.then((result) => {
      if (result) {
        this.getAssistantModels();
      }
    })
  }

  removeRole(assistantModel: AssistantModelResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "superAdmin.companies.models.deleteRole.title";
    modalRef.result.then((result) => {
      // Implement your logic to remove role here
      let params: COR_AII_28 = {
        company_id: this.company.company_id,
        assistant_model_id: assistantModel.assistant_model_id
      }
      this.aiService.deleteAssistantModel(params).subscribe((res) => {
        this.toastService.showSuccess('toast.assistantModel.delete.success', 'toast.success.title.success');
        this.getAssistantModels();
      })
    })
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
