<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"profile.changePassword.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body mt-3">
    <form [formGroup]="passwordForm" (ngSubmit)="submit()">
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="oldPassword">{{"profile.changePassword.currentPassword" | translate}}</label>
            <input type="password" class="form-control" id="oldPassword" formControlName="oldPassword">
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="newPassword">{{"profile.changePassword.newPassword" | translate}}</label>
            <input type="password" class="form-control" id="newPassword" formControlName="newPassword">
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="confirmPassword">{{"profile.changePassword.confirmPassword" | translate}}</label>
            <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end">
        <div class="col-12">
          <button type="submit" class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">{{"profile.changePassword.submit" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
