<div class="home-main">
  <div class="row d-flex align-items-center justify-content-start">
    <div class="col-xl-6 col-sm-6 d-flex align-items-center">
<!--      <div class="img-box">-->
<!--        <img src="assets/images/icononly_transparent_nobuffer.png" alt="home-image" class="topbar-logo"/>-->
<!--      </div>-->
      <h1 class="logo-text-header-master mb-0">Master</h1>
      <h1 class="logo-text-header-employee mb-0">Employee</h1>
    </div>
    <div class="col-xl-6 col-sm-5 d-flex justify-content-end">
      <div class="btn " style="color: #DCE7ED" *ngIf="isLoggedIn" (click)="navigateToDashboard()">{{ "home.dashboardBtn" | translate }}</div>
      <div class="btn" style="color: #DCE7ED" *ngIf="!isLoggedIn" (click)="navigateToLogin()">{{ "home.loginBtn" | translate }}</div>
    </div>
  </div>
  <div class="row content-main d-flex align-items-center">
    <div class="col-xl-6 col-sm-12">
      <div class="row">
        <div class="col-xl-12 col-sm-12 d-flex logo-page">
          <h1 class="logo-text-master me-2">Master</h1>
          <h1 class="logo-text-employee">Employee</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-sm-12 d-flex align-items-center justify-content-start">
          <h5 class="text-center mb-0" style="color: #707070">{{ "home.subHeader" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-sm-12 h-100 pt-4 d-flex justify-content-center">
      <div class="img-box-device">
        <img src="assets/images/Device%20-%20Macbook%20Air.png" alt="home-image" class="home-image"/>
      </div>
    </div>
  </div>
  <div class="row feature-list d-flex align-items-center justify-content-center">
    <div class="col-xl-4 col-sm-12">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box1.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 350px">{{ "home.box1.description" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12 with-border">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box2.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 350px">{{ "home.box2.description" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <h3>{{ "home.box3.title" | translate }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center" style="text-align: center; color: #707070">
          <h5 style="max-width: 350px">{{ "home.box3.description" | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
