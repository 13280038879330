import {Component, Input} from '@angular/core';
import {FileResponse, ThreadResponse} from "../../models/response.module";
import {_COR_AII_24} from "../../models/input.module";
import {AiService} from "../../services/ai.service";
import {NgxExtendedPdfViewerService, pdfDefaultOptions} from "ngx-extended-pdf-viewer";

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrl: './view-pdf.component.css'
})
export class ViewPdfComponent {
  @Input() thread: ThreadResponse;
  @Input() file_id: number;
  selectedFileUrl: string | null = null;

  constructor(private aiService: AiService, private pdfService: NgxExtendedPdfViewerService) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit() {
    this.getFileUrl(this.file_id);
  }

  ngOnChanges() {
    this.getFileUrl(this.file_id);
  }

  getFileUrl(file_id: number){
    let payload: _COR_AII_24 = {
      file_id: String(file_id),
      org: 0
    }

    this.aiService.getFileObject(payload).subscribe((res) => {
      this.selectedFileUrl = res.data.file_url;
      console.log(this.selectedFileUrl);
    });
  }
}
