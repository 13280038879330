import { Component } from '@angular/core';
import {
  AssistantModelResponse,
  UserOptionsResponse
} from "../../../../@shared/models/response.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {UserService} from "../../../../@core/services/user.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {USM_USR_22} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";

@Component({
  selector: 'app-change-preferred-assistant',
  templateUrl: './change-preferred-assistant.component.html',
  styleUrl: './change-preferred-assistant.component.css'
})
export class ChangePreferredAssistantComponent {
  assistantModels: AssistantModelResponse[] = [];
  selectedAssistantTypeId: string;
  userOptions: UserOptionsResponse;

  constructor(private aiService: AiService,
              private userService: UserService,
              private modal: NgbActiveModal,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.getAssistantModels();
  }

  getUserOptions() {
    this.userService.getUserOptions().subscribe((data) => {
      this.userOptions = data;
      this.selectedAssistantTypeId = this.assistantModels.find((assistantType) => assistantType.assistant_type_id === this.userOptions.preferred_assistant_type_id)!.assistant_type_id;
    })

  }
  getAssistantModels() {
    this.aiService.getAssistantModels().subscribe((data) => {
      this.assistantModels = data;
      this.getUserOptions();
    })
  }

  changePreferredAssistantType() {
    let params: USM_USR_22 = {
      language: this.userOptions.language,
      preferred_assistant_type_id: this.selectedAssistantTypeId
    }
    this.userService.updateUserOptions(params).subscribe(() => {
      this.toastService.showSuccess('toast.success.profile.preferredAssistant', 'toast.success.title.success');
      this.modal.close();
    });
  }

  close() {
    this.modal.close();
  }
}
