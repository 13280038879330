import { Component } from '@angular/core';
import {_COR_AII_5, _COR_AII_8} from "../../@shared/models/input.module";
import {AiService} from "../../@shared/services/ai.service";
import {ThreadResponse} from "../../@shared/models/response.module";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateThreadComponent} from "./components/create-thread/create-thread.component";
import {Router} from "@angular/router";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {ToastService} from "../../@core/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ViewThreadFilesComponent} from "../../@shared/components/view-thread-files/view-thread-files.component";

@Component({
  selector: 'app-threads',
  templateUrl: './threads.component.html',
  styleUrl: './threads.component.css'
})
export class ThreadsComponent {
  threads: ThreadResponse[] = [];
  loading: boolean = false;
  searchForm: FormGroup;
  page: number = 1;
  limit: number = 8;
  totalItems: number = 0; // This should be fetched from the backend if available
  totalPages: number = 0; // This should be fetched from the backend if available
  searchString: string = '';
  search: boolean = false;
  displayedPages: (number | string)[] = [];
  constructor(private aiService: AiService,
              private modalService: NgbModal,
              private router: Router,
              private toastService: ToastService) { }

  ngOnInit() {
    this.getThreads();
    this.searchForm = new FormGroup({
      searchString: new FormControl('', [Validators.maxLength(200)])
    })
  }

  getThreads() {
    this.loading = true;
    let params: _COR_AII_5 = {
      paginate: 1,
      page: this.page,
      limit: this.limit,
      search_string: this.searchString,
      search_string_columns: ['reference', 'assistant_name'],
      qna: 0
    }
    this.aiService.getThreads(params).subscribe((response) => {
      this.threads = response.data;
      this.totalPages = response.total_pages;
      this.loading = false;
      this.updatePagination();
    })
  }

  createThread() {
    let modalRef = this.modalService.open(CreateThreadComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.result.then((result) => {
      this.getThreads()
    })
  }

  deleteThread(thread: ThreadResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "threads.deleteThread.title";
    modalRef.result.then((result) => {
      if (result == true) {
        let params: _COR_AII_8 = {
          thread_id: thread.thread_id
        }
        this.aiService.deleteThread(params).subscribe(() => {
          this.getThreads();
          this.toastService.showSuccess('toast.threadDeleted.success', 'toast.success.title.success');
        })
      }
    })
  }

  goToThread(thread: ThreadResponse) {
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.getThreads();
  }

  onSearch() {
    this.searchString = this.searchForm.get('searchString')!.value;
    this.getThreads();
  }

  toggelSearch() {
    this.search = !this.search;
    if (!this.search) {
      this.searchString = '';
      this.searchForm.patchValue({searchString: ''});
      this.getThreads();
    }
  }

  updatePagination(): void {
    if (this.totalPages <= 6) {
      this.displayedPages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      this.displayedPages = this.getCondensedPagination();
    }
  }

  getCondensedPagination(): (number | string)[] {
    const firstPages = [1, 2, 3];  // First few pages
    const lastPages = [this.totalPages - 2, this.totalPages - 1, this.totalPages];  // Last few pages

    if (this.totalPages <= 6) {
      // If there are fewer than or equal to 6 pages, show all of them
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    if (this.page <= 3) {
      // If you're on the first few pages, show 1, 2, 3, ... and last pages
      return [...firstPages, '...', ...lastPages];
    } else if (this.page >= this.totalPages - 2) {
      // If you're on the last few pages, show first pages, ..., and last pages
      return [...firstPages, '...', ...lastPages];
    } else {
      // If you're in the middle (like page 3), show a range around the current page
      return [
        this.page - 1,
        this.page,
        this.page + 1,
        '...',
        this.totalPages - 2,
        this.totalPages - 1,
        this.totalPages,
      ];
    }
  }

  selectPage(page: number | string): void {
    if (page === '...') {
      // If the ellipsis is clicked, move to the next or previous page range
      if (this.page < this.totalPages / 2) {
        // Clicking on the first ellipsis: move right by 1 page
        this.page = Math.min(this.page + 1, this.totalPages - 1);
      } else {
        // Clicking on the second ellipsis: move left by 1 page
        this.page = Math.max(this.page - 1, 4);
      }
    } else if (typeof page === 'number') {
      this.page = page;
    }

    this.updatePagination();
    this.onPageChange(this.page); // Notify the change of page
  }

  viewThreadFiles(thread: ThreadResponse) {
    let modalRef = this.modalService.open(ViewThreadFilesComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.thread = thread;
    modalRef.result.then((result) => {
      this.getThreads();
    })
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
