<div class="account-pages">
  <div class="row d-flex h-100">
    <!-- Left column with background -->
    <div class="col-6 h-100 p-0 ms-3 form-edit-picture-wrapper">
      <!-- Text content on top of the image -->
      <div class="overlay-text w-100 h-100">
        <div class="top m-5">
          <h1 class="text-white mb-3" style="font-size: 48px">Experience the power of <br>Artificial Intelligence<br></h1>
          <p class="text-white f-18">MasterEmployee is a powerful AI-tool that accelerates you and your workforce. Our solution will make your organization smarter and faster.
            AI does not have to be complex to make a an impact. MasterEmployee is a so easy to use that you can get started immediately. We have done the hard job for you so that you can focus on the easy part.
            There is no need for technical integrations or costly set-up projects. With MasterEmployee you can get started right away.
            Make AI available to all your employees to unleash the creativity of your organization.
            What are you waiting for? <a href="https://www.masteremployee.com" class="text-white f-18">Read more</a>
          </p>
        </div>
        <div class="bottom d-flex justify-content-between m-5">
          <p class="text-white f-18">© MasterEmployee 2024</p>
          <a href="https://www.masteremployee.com/terms-and-conditions" class="text-white f-18">Privacy Policy / User Terms</a>
        </div>
      </div>
    </div>

    <!-- Right column with content -->
    <div class="col d-flex justify-content-center ">
      <div class="col-6" style="background-color: #0D0F11">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
