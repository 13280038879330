import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "../../../../@core/services/user.service";
import {ToastService} from "../../../../@core/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../../@shared/services/company.service";
import {COR_COY_1} from "../../../../@shared/models/input.module";

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrl: './create-company.component.css'
})
export class CreateCompanyComponent {
  companyForm: FormGroup;

  constructor(private modal: NgbActiveModal,
              private userService: UserService,
              private toastService: ToastService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this.companyForm = new FormGroup({
      company_name: new FormControl('', [Validators.required]),
      organization_number: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      postal_code: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required])
    });
  }

  submit(){
    let payload: COR_COY_1 = {
      company_name: this.companyForm.value.company_name,
      organisation_number: this.companyForm.value.organization_number,
      address: this.companyForm.value.address,
      city: this.companyForm.value.city,
      postal_code: this.companyForm.value.postal_code,
      country: this.companyForm.value.country,
      phone: this.companyForm.value.phone,
      email: this.companyForm.value.email
    }

    this.companyService.createCompany(payload).subscribe((data) => {
      this.toastService.showSuccess('toast.success.company.createCompany', 'toast.success.title.success')
      this.modal.close(true);
    })
  }

  close() {
    this.modal.close();
  }
}
