import { Component } from '@angular/core';
import {_COR_AII_5, _COR_AII_8} from "../../@shared/models/input.module";
import {AiService} from "../../@shared/services/ai.service";
import {ThreadResponse} from "../../@shared/models/response.module";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateThreadComponent} from "./components/create-thread/create-thread.component";
import {Router} from "@angular/router";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {ToastService} from "../../@core/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-threads',
  templateUrl: './threads.component.html',
  styleUrl: './threads.component.css'
})
export class ThreadsComponent {
  threads: ThreadResponse[] = [];
  loading: boolean = false;
  searchForm: FormGroup;
  page: number = 1;
  limit: number = 10;
  totalItems: number = 0; // This should be fetched from the backend if available
  totalPages: number = 0; // This should be fetched from the backend if available
  searchString: string = '';
  search: boolean = false;
  constructor(private aiService: AiService,
              private modalService: NgbModal,
              private router: Router,
              private toastService: ToastService) { }

  ngOnInit() {
    this.getThreads();
    this.searchForm = new FormGroup({
      searchString: new FormControl('', [Validators.maxLength(200)])
    })
  }

  getThreads() {
    this.loading = true;
    let params: _COR_AII_5 = {
      paginate: 1,
      page: this.page,
      limit: this.limit,
      search_string: this.searchString,
      search_string_columns: ['reference', 'assistant_name'],
      qna: 0
    }
    this.aiService.getThreads(params).subscribe((response) => {
      this.threads = response.data;
      this.totalPages = response.total_pages;
      this.loading = false;
    })
  }

  createThread() {
    let modalRef = this.modalService.open(CreateThreadComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.result.then((result) => {
      this.getThreads()
    })
  }

  deleteThread(thread: ThreadResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "threads.deleteThread.title";
    modalRef.result.then((result) => {
      if (result == true) {
        let params: _COR_AII_8 = {
          thread_id: thread.thread_id
        }
        this.aiService.deleteThread(params).subscribe(() => {
          this.getThreads();
          this.toastService.showSuccess('toast.threadDeleted.success', 'toast.success.title.success');
        })
      }
    })
  }

  goToThread(thread: ThreadResponse) {
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.getThreads();
  }

  onSearch() {
    this.searchString = this.searchForm.get('searchString')!.value;
    this.getThreads();
  }

  toggelSearch() {
    this.search = !this.search;
    if (!this.search) {
      this.searchString = '';
      this.searchForm.patchValue({searchString: ''});
      this.getThreads();
    }
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
