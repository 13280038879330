import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import jsPDF from "jspdf";
import Quill from "quill";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import html2canvas from "html2canvas";
import {_COR_AII_10, _COR_AII_24} from "../../../../@shared/models/input.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {FileResponse} from "../../../../@shared/models/response.module";
import {formatTimeDMYHHMM} from "../../../../@core/utils/utils.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrl: './create-document.component.css'
})
export class CreateDocumentComponent {
  @Input() assistantGroupId: string;
  @Input() view: boolean = false;
  @Input() file: FileResponse
  fileName: string = '';  // Stores the name of the file
  editorContent: string = '';  // Stores the content of the editor

  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],        // Basic styling
      [{ list: 'ordered' }, { list: 'bullet' }],  // Lists
      [{ header: [1, 2, 3, false] }],         // Headers
      ['link', 'image']                        // Links and images
    ]
  };

  @ViewChild('parentdiv', { static: false }) parentdiv!: ElementRef;  // Access the parent div

  constructor(private modal: NgbActiveModal, private aiService: AiService, private http: HttpClient) {}

  ngOnInit() {
    if (this.view && this.file) {
      this.fileName = this.file.file_name;
      this.getFileUrl(this.file);
    }
  }

  getFileUrl(file: FileResponse) {
    let payload: _COR_AII_24 = {
      file_id: String(file.file_id),
      org: 1
    };

    this.aiService.getFileObject(payload).subscribe((data) => {
      let file_url = data.data.file_url;

      if (file_url) {
        this.downloadFileContent(file_url);
      }
    });
  }

  downloadFileContent(fileUrl: string) {
    this.http.get(fileUrl, { responseType: 'text' }).subscribe(
      (content) => {
        this.editorContent = content; // Set editor content
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  }

  ngAfterViewInit() {
    // Ensure the ViewChild is available after the view has been initialized
    console.log('parentdiv:', this.parentdiv);
  }

  exportToPDF() {
    let content = "<h1>" + this.fileName + "</h1>" + "\n\n";

    content += this.editorContent;

    const blob = new Blob([content], { type: 'text/plain' });

    let payloadPost: _COR_AII_10 = {
      assistant_group_id: this.assistantGroupId,
      supplementary_data: 0,
      file: new File([blob], this.fileName + '.txt')
    }
    this.aiService.uploadFile(payloadPost).subscribe((res) => {
      console.log(res);
      this.modal.close(res);
    });
  }

  close() {
    this.modal.close();
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
