<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{"companySettings.editEmployee.title" | translate}}</h5>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <img src="{{user.profile_image_url}}" alt="Profile Image" class="profile-image">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>{{user.first_name}} {{user.last_name}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row pb-3">
          <div class="col-12">
            <h5 style="color: #707070">{{ "companySettings.editEmployee.phone" | translate }}</h5>
            <p style=>{{user.phone}}</p>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <h5 style="color: #707070">{{ "companySettings.editEmployee.email" | translate }}</h5>
            <p>{{user.email}}</p>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <h5 style="color: #707070">{{ "companySettings.editEmployee.role" | translate }}</h5>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedRoleId" (change)="editEmployeeRole()">
              <option value="1">{{"companySettings.inviteEmployees.role.admin" | translate}}</option>
              <option value="2">{{"companySettings.inviteEmployees.role.user" | translate}}</option>
            </select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-8">
            <label style="font-weight: bold">{{"companySettings.inviteEmployees.access.label" | translate}}</label>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="user.component_access_id" (change)="editEmployeeRole()">
              <option value="" selected>{{'companySettings.inviteEmployees.access.placeholder' | translate}}</option>
              <option value="1">{{'companySettings.inviteEmployees.access.personal' | translate}}</option>
              <option value="2">{{'companySettings.inviteEmployees.access.master' | translate}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
