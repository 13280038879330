<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.details.addDocumentsModal.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-8 dropzone">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        <i class="fa fa-regular fa-upload fa-2x pb-2"></i>
        <h3>Drag and drop file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>
    </div>
    <div class="row pt-3" *ngFor="let file of selectedFiles">
      <div class="col-12 d-flex ms-5 align-items-center">
        <i class="fa fa-file-pdf fa-2x"></i>
        <h5 class="m-0">{{file.name}}</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" (click)="uploadFilesSequentially()">
      <div *ngIf="uploading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
        {{ "assistants.details.addDocumentsModal.saveBtn" | translate }}
    </div>
  </div>
</div>
