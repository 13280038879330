<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"templates.createNew.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="createTemplateForm">
      <div class="row">
        <div class="col-8">
          <label class="f-16 f-bold">{{"templates.createNew.templateName" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="templateName"
              placeholder="{{'templates.createNew.templateName' | translate}}"
            >
          </div>
          <span *ngIf="createTemplateForm.get('templateName')?.invalid && createTemplateForm.get('templateName')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold">{{"templates.createNew.templateDescription" | translate}}</label>
          <input
            type="text"
            class="form-control"
            formControlName="templateDescription"
            placeholder="{{'templates.createNew.templateDescription' | translate}}"
          >
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold" for="flexSwitchCheckDefault">{{ "templates.createNew.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createTemplate()">{{ "templates.createNew.createBtn" | translate }}</div>
  </div>
</div>
