<!--<chatlio-widget widgetid="96e7726b-3958-49dd-5a03-71d72e0c0df5"></chatlio-widget>-->
<body style="max-height: 100%">
<!-- Begin page -->
<div class="wrapper" *ngIf="isLoggedIn && currentPath !== '/home' && currentPath !== '/'" >
  <div class="main-layout">
    <app-sidebar></app-sidebar>
<!--    <app-topbar></app-topbar>-->
    <div class="content-page">
      <div class="content">
        <router-outlet></router-outlet>
        <app-chat-widget></app-chat-widget>
      </div>
<!--      <app-footer *ngIf="isLoggedIn"></app-footer>-->
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="!isLoggedIn || currentPath === '/home' || currentPath === '/' || currentPath === '/auth/forgot-password'" >
  <router-outlet></router-outlet>
  <!-- END wrapper -->
</div>
</body>
