<div class="left-nav-bar" >
  <div class="logo d-flex justify-content-start mb-0">
    <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 60px;">
  </div>
  <div class="sandbox-label">
    <a *ngIf="environment.envName == 'sandbox'" style="color: red; font-weight: bold;">Sandbox</a>
  </div>
  <nav>
    <ul class="top-items" *ngIf="userObj">
      <li><a routerLink="/dashboard" routerLinkActive="active"><i class="fas fa-home"></i> {{ "sidebar.home" | translate }}</a></li>
      <li *ngIf="userObj.component_access_id == '1' || '2'"><a routerLink="/personal-assistant" routerLinkActive="active"><i class="fas fa-person-running-fast"></i> {{ "sidebar.personalAssistant" | translate }}</a></li>
      <li *ngIf="userObj.component_access_id == '2'"><a routerLink="/assistants" routerLinkActive="active"><i class="fas fa-users"></i> {{ "sidebar.assistants" | translate }}</a></li>
      <li *ngIf="userObj.component_access_id == '2'"><a routerLink="/threads" routerLinkActive="active"><i class="fas fa-comments"></i> {{ "sidebar.threads" | translate }}</a></li>
      <li *ngIf="userObj.component_access_id == '2'"><a routerLink="/templates" routerLinkActive="active"><i class="fas fa-list-check"></i> {{ "sidebar.templates" | translate }}</a></li>
    </ul>

    <div class="center-items">
      <div *ngIf="userFavorites && userFavorites.length > 0">
        <a><i class="far fa-star me-2"></i>Favorite assistants</a>
        <ul>
          <li *ngFor="let favourite of userFavorites"
              class="m-1 ps-4 cursor-pointer"
              style="overflow: hidden"
              title="{{favourite.assistant_group.assistant_group_name}}"
              (click)="goToAssistantDetails(favourite)"
          >{{favourite.assistant_group.assistant_group_name}}</li>
        </ul>
      </div>
    </div>

    <ul class="bottom-items">
      <li *ngIf="(user && user.role_id == 1) || superAdmin">
        <a routerLink="/settings" routerLinkActive="active"><i class="fas fa-gear"></i> {{ "sidebar.settings" | translate }}</a>
      </li>
      <li *ngIf="superAdmin">
        <a routerLink="/super-admin" routerLinkActive="active"><i class="fas fa-lock"></i> {{ "sidebar.superAdmin" | translate }}</a>
      </li>
      <li class="d-flex align-items-center">
        <!-- User profile dropdown -->
        <div class="dropdown" *ngIf="userObj">
          <button class="dropbtn ps-0">
            <img src="{{userObj.profile_image_url}}" alt="Profile Image" class="profile-image " *ngIf="userObj.profile_image_url">
            <div *ngIf="!userObj.profile_image_url" class="profile-initials-circle">
              {{ initials }}
            </div>
          </button>
          <div class="dropdown-content" style="border-radius: 5px">
            <a routerLink="/profile" class="cursor-pointer">Profile</a>
            <a (click)="signOut()" class="cursor-pointer">Logout</a>
          </div>
        </div>
        <form [formGroup]="form" *ngIf="superAdmin">
          <select class="form-select me-3" aria-label="Default select example" style="" formControlName="selectedCompanyId"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'threads.createThread.assistantLabel' | translate}}</option>
            <option *ngFor="let company of companies" [value]="company.company_id">
              {{ company.company_name }}
            </option>
          </select>
        </form>
        <h4 *ngIf="!superAdmin && userObj">{{userObj.first_name}}</h4>
      </li>
    </ul>
  </nav>
</div>
