import { Component } from '@angular/core';
import {CompanyService} from "../../@shared/services/company.service";
import {CompanyResponse, UserEntityRelationWithUserDataResponse} from "../../@shared/models/response.module";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../@core/services/toast.service";
import {InviteEmployeesComponent} from "./components/invite-employees/invite-employees.component";
import {EditEmployeeComponent} from "./components/edit-employee/edit-employee.component";
import {th} from "date-fns/locale";
import {UserService} from "../../@core/services/user.service";
import {USM_ENT_3, USM_USR_3} from "../../@shared/models/input.module";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {WordBankComponent} from "./components/word-bank/word-bank.component";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent {
  company: CompanyResponse
  employees: UserEntityRelationWithUserDataResponse[] = []

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private userService: UserService) { }

  ngOnInit() {
    this.companyService.getCompany({}).subscribe((data) => {
      this.company = data;
    });
    this.getEmployees();
  }

  getEmployees() {
    this.companyService.getCompanyEmployees().subscribe((data: UserEntityRelationWithUserDataResponse[]) => {
      this.employees = data;
    });
  }

  inviteEmployees() {
    let modalRef = this.modalService.open(InviteEmployeesComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.result.then((result) => {
    })
  }

  editEmployee(user: UserEntityRelationWithUserDataResponse) {
    let modalRef = this.modalService.open(EditEmployeeComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.user = user;
    modalRef.result.then((result) => {
      if (result) {
        this.getEmployees();
      }
    })
  }

  removeEmployeeFromEntity(employee: UserEntityRelationWithUserDataResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "companySettings.deleteEmployee.title";
    modalRef.result.then((result) => {
      // Implement your logic to remove employee from entity here
      let payload: USM_ENT_3 = {
        user_id: employee.user_id,
        entity_id: this.company.company_id
      }
      this.userService.removeUserFromEntity(payload).subscribe((res) => {
        this.toastService.showSuccess('toast.settings.removeEmployee.success', 'toast.success.title.success');
        this.getEmployees();
      })
    })
  }

  editWordBank() {
    let modalRef = this.modalService.open(WordBankComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.result.then((result) => {
    })
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
