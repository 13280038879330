<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">Create Report</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 16px; margin-right: 16px">
    <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
      <div class="col-10 p-0">
        <p style="color: #909699">Here you can create a report based on all questions in a question set. The report can then be view in the portal or exported as PDF</p>
      </div>
    </div>
    <form [formGroup]="createReportForm">
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
        <div class="col-8 p-0">
          <label class="f-16 f-bold label-class spacing-15">Report name</label>
          <div class="">
            <div class="input-group">
              <input
                type="text"
                class="form-control input-class"
                formControlName="report_name"
                placeholder="Enter report name"
              >
            </div>
            <span *ngIf="createReportForm.get('report_name')?.invalid && createReportForm.get('report_name')?.touched"
                  class="text-danger">{{ "global.requiredField" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px">
        <div class="col p-0">
          <label class="f-16 f-bold label-class spacing-15">Assistant type</label>
          <select class="form-select input-class"
                  aria-label="Default select example"
                  formControlName="assistant_id"
          >
            <option value="" selected>Select assistant type</option>
            <option *ngFor="let assistant of assistantGroup.assistants" [value]="assistant.assistant_id">
              {{assistant.assistant_type_name}}
            </option>
          </select>
          <span *ngIf="createReportForm.get('assistant_id')?.invalid && createReportForm.get('assistant_id')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row spacing-30" style="margin-left: 16px; margin-right: 16px" *ngIf="templates">
        <div class="col p-0">
          <label class="f-16 f-bold label-class spacing-15">Question set</label>
          <select class="form-select input-class"
                  aria-label="Default select example"
                  formControlName="template_id"
          >
            <option value="" selected>Select question set</option>
            <option *ngFor="let template of templates" [value]="template.template_id">
              {{template.template_name}}
            </option>
          </select>
          <span *ngIf="createReportForm.get('template_id')?.invalid && createReportForm.get('template_id')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 32px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createReport()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>
