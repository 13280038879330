import {Component} from '@angular/core';
import {AiService} from "../../@shared/services/ai.service";
import {
  AssistantGroupResponse,
  AssistantResponse,
  FileResponse,
  ReportResponse,
  TemplateMissingDataFieldResponse,
  ThreadResponse,
  UserAssistantGroupFavoriteResponse
} from "../../@shared/models/response.module";
import {
  _COR_AII_10,
  _COR_AII_11,
  _COR_AII_17,
  _COR_AII_24,
  _COR_AII_39,
  _COR_AII_42,
  _COR_AII_5,
  _COR_AII_6,
  _COR_AII_8,
  _COR_AII_9,
  _USM_USR_27,
  _USM_USR_28
} from "../../@shared/models/input.module";
import {ActivatedRoute, Router} from "@angular/router";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {UserService} from "../../@core/services/user.service";
import {ToastService} from "../../@core/services/toast.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AddDocumentsComponent} from "./components/add-documents/add-documents.component";
import {CreateThreadComponent} from "../threads/components/create-thread/create-thread.component";
import {EditAssistantComponent} from "../assistants/components/edit-assistant/edit-assistant.component";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {PaginationResponse} from "../../@shared/models/global/response-wrapper.service";
import {StorageService} from "../../@core/services/storage.service";
import {ShareComponent} from "./components/share/share.component";
import {ViewFileComponent} from "./components/view-file/view-file.component";
import {CreateDocumentComponent} from "./components/create-document/create-document.component";
import {CreateReportComponent} from "./components/create-report/create-report.component";
import {ViewReportComponent} from "./components/view-report/view-report.component";
import {ViewThreadFilesComponent} from "../../@shared/components/view-thread-files/view-thread-files.component";
import {
  TemplateMissingDataFieldsComponent
} from "./components/template-missing-data-fields/template-missing-data-fields.component";

@Component({
  selector: 'app-assistant-details',
  templateUrl: './assistant-details.component.html',
  styleUrl: './assistant-details.component.css'
})
export class AssistantDetailsComponent {
  assistantGroupId: string = "";
  assistantGroup: AssistantGroupResponse;
  threads: ThreadResponse[] = [];
  loadingThreads: boolean = false;
  canEdit: boolean = false;
  userFavorites: UserAssistantGroupFavoriteResponse[]
  userFavoritesIds: string[] = []
  reports: ReportResponse[] = []
  loadingReports: boolean = false;
  selectedFiles: FileResponse[] = [];
  selectedFile: any = null;  // Track the currently selected file
  templateMissingDataFields: TemplateMissingDataFieldResponse[] = [];


  constructor(private aiService: AiService,
              private route: ActivatedRoute,
              private userService: UserService,
              private router: Router,
              private toastService: ToastService,
              private modalService: NgbModal,
              private storageService: StorageService) {
  }

  ngOnInit() {
    this.assistantGroupId = String(this.route.snapshot.paramMap.get('id'));

    this.route.params.subscribe(params => {
      this.assistantGroupId = String(+params['id']);
      this.loadAssistantGroupDetails(this.assistantGroupId); // Function to fetch or update the data accordingly
    });
    this.getUserFavorites()
  }

  loadAssistantGroupDetails(id: string): void {
    let payload: _COR_AII_17 = {
      assistant_group_id: this.assistantGroupId
    }
    this.aiService.getAssistantGroups(payload).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantGroup = data.data[0];
      this.templateMissingDataFields = this.assistantGroup.template_missing_data_fields;
      this.checkForFileUpdates()
      if (this.assistantGroup.created_by?.user_id == this.storageService.getUser().user_id || !this.assistantGroup.private || this.assistantGroup.access_relations.find((relation) => relation.user_id == this.storageService.getUser().user_id && relation.access_level.access_level_id in [0, 1])) {
        this.canEdit = true;
      }
      this.getRecentThreads();
      this.getReports();
    })
  }

  checkForFileUpdates() {
    let checkFiles = () => {
      let payload: _COR_AII_9 = {
        assistant_id: this.assistantGroup.assistants[0].assistant_id
      };

      this.aiService.getFiles(payload).subscribe((data) => {
        // Check for file changes and update if necessary
        if (data.length > 0) {
          this.assistantGroup.assistants[0].files = data;

          let complete = this.assistantGroup.assistants[0].files.every((file) => {
            return Boolean(file.ocr) && Boolean(file.s3) && file.ai_file_id;
          });

          // If not complete, check again after 5 seconds
          if (!complete) {
            setTimeout(checkFiles, 5000);
          }
        }
        else {
          setTimeout(checkFiles, 5000);
        }
      });
    };

    // Start checking
    checkFiles();
  }

  getAssistantGroup() {
    let payload: _COR_AII_17 = {
      assistant_group_id: this.assistantGroupId
    }
    this.aiService.getAssistantGroups(payload).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantGroup = data.data[0];
      this.getRecentThreads();
      this.checkForFileUpdates();
      this.templateMissingDataFields = this.assistantGroup.template_missing_data_fields;
    })
  }

  getRecentThreads() {
    this.loadingThreads = true;
    this.userService.getUserOptions().subscribe((data) => {
      let payload: _COR_AII_5 = {
        qna: 0,
        assistant_group_id: this.assistantGroupId,
      }
      this.aiService.getThreads(payload).subscribe((res) => {
        this.threads = res.data;
        this.loadingThreads = false;
      })
    })
  }

  goToThread(thread: ThreadResponse) {
    this.router.navigate(['/threads/view/', thread.thread_id]);
  }

  createAndGoToThread(assistant: AssistantResponse) {
    let params: _COR_AII_6 = {
      assistant_id: assistant.assistant_id,
      qna: 0
    }
    this.aiService.createThread(params).subscribe((data) => {
      this.router.navigate(['/threads/view/', data.thread_id]);
    })
  }

  removeFile(file: FileResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "assistants.details.deleteDocumentModal.title";
    modalRef.result.then((result) => {
      if (result == true) {
        this.assistantGroup.assistants[0].files = this.assistantGroup.assistants[0].files.filter((f) => f.file_id !== file.file_id);
        let params: _COR_AII_11 = {
          file_id: file.file_id
        }
        this.aiService.deleteFile(params).subscribe(() => {
          this.toastService.showSuccess('toast.success.title.success', 'toast.deleteFile.success');
          this.aiService.assistantsUpdatedSubject.next(true);
        })
      }
    })
  }

  addDocuments() {
    let modalRef = this.modalService.open(AddDocumentsComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroup.assistant_group_id;
    modalRef.result.then((result) => {
      if (result){
        setTimeout(() => {
          this.checkForFileUpdates();
        },1000)
      }
    })
  }

  createThread(){
    let modalRef = this.modalService.open(CreateThreadComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroupId;
    modalRef.componentInstance.details = true;
    modalRef.componentInstance.selectedFiles = this.assistantGroup.assistants[0].files;
    modalRef.result.then((result) => {
      if (result){
        this.getRecentThreads();
        this.router.navigate(['/threads/view/' + result.thread_id]);
      }
    })
  }

  editAssistantGroup() {
    let modalRef = this.modalService.open(EditAssistantComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroup = this.assistantGroup;
    modalRef.result.then((result) => {
      if (result){
        setTimeout(() => {
          this.getAssistantGroup();
          this.toastService.showSuccess('toast.assistantUpdated.success', 'toast.success.title.success');
        },1000)
      }
    })
  }

  deleteThread(thread: ThreadResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "threads.deleteThread.title";
    modalRef.result.then((result) => {
      if (result == true) {
        let params: _COR_AII_8 = {
          thread_id: thread.thread_id
        }
        this.aiService.deleteThread(params).subscribe(() => {
          this.getRecentThreads();
          this.toastService.showSuccess('toast.threadDeleted.success', 'toast.success.title.success');
        })
      }
    })
  }

  shareAssistantGroup() {
    let modalRef = this.modalService.open(ShareComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroup = this.assistantGroup;
    modalRef.result.then((result) => {
      if (result){
        this.toastService.showSuccess('toast.assistantGroupShared.success', 'toast.success.title.success');
      }
    })
  }

  downloadFileObject(file: FileResponse, org: number) {
    let payload: _COR_AII_24 = {
      file_id: String(file.file_id),
      org: org
    }
    this.aiService.getFileObject(payload).subscribe((data) => {
      window.open(data.data.file_url, '_blank');
    })
  }

  getUserFavorites() {
    this.userService.getAssistantGroupFavorites().subscribe((res) => {
      this.userFavorites = res
      if (this.userFavorites.length > 0) {
        for (let favorite of this.userFavorites) {
          this.userFavoritesIds.push(favorite.assistant_group.assistant_group_id)
        }
      }
    })
  }

  addToFavorites(assistantGroup: AssistantGroupResponse) {
    let payload: _USM_USR_27 = {
      assistant_group_id: assistantGroup.assistant_group_id
    }

    this.userService.addAssistantGroupToFavorites(payload).subscribe((res) => {
      // Check if the item already exists to avoid duplicates
      if (!this.userFavorites.some(fav => fav.assistant_group.assistant_group_id === res.assistant_group.assistant_group_id)) {
        this.userFavorites.push(res); // Add to userFavorites
      }

      if (!this.userFavoritesIds.includes(res.assistant_group.assistant_group_id)) {
        this.userFavoritesIds.push(res.assistant_group.assistant_group_id); // Add to userFavoritesIds
      }

      // Emit the updated favorites list
      this.userService.userFavoritesSubject.next(this.userFavorites);
    });
  }

  removeFromFavorites(assistantGroup: AssistantGroupResponse) {
    let payload: _USM_USR_28 = {
      assistant_group_id: assistantGroup.assistant_group_id
    }

    this.userService.removeAssistantGroupFromFavorites(payload).subscribe((res) => {
      this.userFavorites = this.userFavorites.filter((favorite) => favorite.assistant_group.assistant_group_id !== assistantGroup.assistant_group_id)
      this.userFavoritesIds = this.userFavoritesIds.filter((id) => id !== assistantGroup.assistant_group_id)
      this.userService.userFavoritesSubject.next(this.userFavorites)
    })
  }

  viewFile(file: FileResponse) {
    let modalRef = this.modalService.open(ViewFileComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.file_id = file.file_id;
    modalRef.componentInstance.file_name = file.file_name;
  }

  createDocument() {
    let modalRef = this.modalService.open(CreateDocumentComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroupId;
    modalRef.result.then((result) => {
      if (result){
        this.checkForFileUpdates()
        this.toastService.showSuccess('toast.documentCreated.success', 'toast.success.title.success');

      }
    })
  }

  getReports() {
    this.loadingReports = true;
    let params: _COR_AII_39 = {
      assistant_group_id: this.assistantGroupId
    }
    this.aiService.getReports(params).subscribe((res) => {
      this.reports = res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      this.loadingReports = false;
      this.checkForReportUpdates()
    })
  }

  createReport(selectedFiles: boolean = false) {
    let modalRef = this.modalService.open(CreateReportComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroup = this.assistantGroup;
    if (selectedFiles) {
      modalRef.componentInstance.selectedFiles = this.selectedFiles;
    }
    else {
      modalRef.componentInstance.selectedFiles = this.assistantGroup.assistants[0].files;
    }
    modalRef.result.then((result) => {
      if (result){
        this.getReports();
        this.checkForReportUpdates();
      }
    })
  }

  checkForReportUpdates() {
    let checkReports = () => {
      let params: _COR_AII_39 = {
        assistant_group_id: this.assistantGroupId
      };

      this.aiService.getReports(params).subscribe((data) => {
        let complete = false;

        if (data.length === 0) {
          // No reports → consider complete
          complete = true;
        } else {
          this.reports = data.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );

          complete = this.reports.every((report) => {
            return report.status === 1 || report.status === 2;
          });
        }

        if (!complete) {
          setTimeout(checkReports, 5000);
        } else {
          this.getAssistantGroup();
        }
      });
    };

    checkReports();
  }

  viewReport(report: ReportResponse) {
    let modalRef = this.modalService.open(ViewReportComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.report = report;
  }

  deleteReport(report: ReportResponse) {
    let modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "deleteReport.title";
    modalRef.result.then((result) => {
      if (result == true) {
        let params: _COR_AII_42 = {
          report_id: report.report_id
        }
        this.aiService.deleteReport(params).subscribe(() => {
          this.reports = this.reports.filter((r) => r.report_id !== report.report_id);
        })
      }
    })

  }

  selectedFilesChanged(file: FileResponse) {
    if (this.selectedFiles.includes(file)) {
      this.selectedFiles = this.selectedFiles.filter((f) => f.file_id !== file.file_id);
      console.log(this.selectedFiles)
    } else {
      this.selectedFiles.push(file);
      console.log(this.selectedFiles)
    }
  }

  createThreadWithSelectedFiles() {
    let modalRef = this.modalService.open(CreateThreadComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroupId;
    modalRef.componentInstance.details = true;
    modalRef.componentInstance.selectedFiles = this.selectedFiles;
    modalRef.result.then((result) => {
      if (result) {
        this.getRecentThreads();
        this.router.navigate(['/threads/view/' + result.thread_id]);
      }
    })
  }

  viewThreadFiles(thread: ThreadResponse) {
    let modalRef = this.modalService.open(ViewThreadFilesComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.thread = thread;
    modalRef.result.then((result) => {
      if (result) {
        this.getRecentThreads();
      }
    })
  }

  toggleDownloadOptions(file: any) {
    this.selectedFile = this.selectedFile === file ? null : file;
  }

  showTemplateMissingDataFields() {
    let modalRef = this.modalService.open(TemplateMissingDataFieldsComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroupId;
    modalRef.componentInstance.files = this.assistantGroup.assistants[0].files;
    modalRef.result.then((result) => {
      if (result){
        this.getAssistantGroup();
        this.checkForFileUpdates()
      }
    })
  }

  isLastRow(file: any): boolean {
    let res = false;
    if (!this.assistantGroup.assistants[0].files || this.assistantGroup.assistants[0].files.length === 0) {
      res = false;
    }
    else {
      res = true

    }
    // console.log("isLastRow", res);
    return res
  }

  checkForFileCompletion() {
    return this.assistantGroup.assistants[0].files.every((file) => {
      return Boolean(file.ocr) && Boolean(file.s3) && file.ai_file_id;
    });
  }


  viewTxtFile(file: FileResponse) {
    let modalRef = this.modalService.open(CreateDocumentComponent, {size: 'xl', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroupId = this.assistantGroupId;
    modalRef.componentInstance.file = file;
    modalRef.componentInstance.view = true;
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
    protected readonly Number = Number;
}
