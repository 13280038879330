import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AssistantGroupResponse,
  AssistantModelResponse, AssistantTypeResponse,
} from "../../../../../../@shared/models/response.module";
import {AiService} from "../../../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../../../../@core/services/toast.service";
import {Router} from "@angular/router";
import {_COR_AII_10, _COR_AII_18, _COR_AII_26, COR_AII_26} from "../../../../../../@shared/models/input.module";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrl: './create-role.component.css'
})
export class CreateRoleComponent {
  @Input() company_id: string
  createRoleForm: FormGroup
  assistantModels: AssistantModelResponse[] = [];
  assistantTypes: AssistantTypeResponse[] = [];
  selectedFiles: File[] = [];
  loading: boolean = false;

  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService,
              private router: Router) {
    this.createRoleForm = new FormGroup({
      assistant_role_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_type_id: new FormControl('', [Validators.maxLength(200)]),
      model: new FormControl('', [Validators.maxLength(200)]),
      temperature: new FormControl({ value: 1, disabled: false }),
      instructions: new FormControl('', [Validators.maxLength(2000)]),
      top_p: new FormControl({ value: 1, disabled: false}, [Validators.maxLength(200)]),
    });
  }

  ngOnInit() {
    this.getAssistantModels()
    this.getAssistantTypes()
  }

  getAssistantTypes() {
    this.aiService.getAssistantTypes().subscribe((data: AssistantTypeResponse[]) => {
      this.assistantTypes = data;
    })
  }

  getAssistantModels() {
    this.aiService.getAssistantModels().subscribe((data: AssistantModelResponse[]) => {
      this.assistantModels = data;
    })
  }

  createRole() {
    this.loading = true;
    let payload: COR_AII_26 = {
      company_id: this.company_id,
      assistant_type_name: this.createRoleForm.get('assistant_role_name')!.value,
      assistant_type_id: this.createRoleForm.get('assistant_type_id')!.value,
      model: this.createRoleForm.get('model')!.value,
      instructions: this.createRoleForm.get('instructions')!.value,
      temperature: this.createRoleForm.get('temperature')!.value,
      top_p: this.createRoleForm.get('top_p')!.value,
    }

    this.aiService.createAssistantModel(payload).subscribe((res) => {
      this.toastService.showSuccess('toast.assistantModel.add.success', 'toast.success.title.success')
        this.modal.close(true);
    })
  }

  close() {
    this.modal.close();
  }

  onGroupChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const typeId = target.value;
    const assistantType = this.assistantModels.find(type => type.assistant_type_id == typeId);
    // this.filteredAssistants = selectedGroup!.assistants;
    // this.createThreadForm.get('assistant_id')!.setValue(this.filteredAssistants.find((assistant) => assistant.assistant_model_id = this.userOptions.preferred_assistant_type_id)?.assistant_id); // Reset the assistant dropdown
  }
}
