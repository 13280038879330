<app-card
  [titleLocalisationStrong]="'threads.title'"
  [footerWithButton]="true"
  [buttonLocalisationString]="'threads.createThreadBtn'"
  (buttonClick)="createThread()"
>
  <div class="row mb-2 me-1 d-flex justify-content-end">
    <div class="col pe-0 d-flex justify-content-end">
      <div class="" *ngIf="search">
        <form [formGroup]="searchForm" (focusout)="onSearch()" (change)="onSearch()">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="searchString"
              style="background-color: white; border-color: white; width: 400px;"
              placeholder="{{'assistants.searchString.placeholder' | translate}}"
            >
          </div>
        </form>
      </div>
      <div class="ms-2">
        <div class="btn btn-outline-info ps-0 pe-0 custom-button" style="border-color: white;" (click)="toggelSearch()">
          <i class="fa-regular fa-search" style="color: white"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <table class="table table-hover" *ngIf="!loading">
        <thead>
        <tr>
          <!--          <th scope="col">#</th>-->
          <th scope="col">{{"threads.table.reference" | translate}}</th>
          <th scope="col">{{"threads.table.threadName" | translate}}</th>
          <th scope="col">{{"threads.table.topic" | translate}}</th>
          <th scope="col">{{"threads.table.assistant" | translate}}</th>
          <th scope="col">{{"threads.table.created" | translate}}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let thread of threads">
          <!--          <th scope="row">{{assistant.assistant_id}}</th>-->
          <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.assistant.reference }}</td>
          <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.assistant.assistant_name }}</td>
          <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.topic }}</td>
          <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.assistant.assistant_type_name }}</td>
          <td class="cursor-pointer" (click)="goToThread(thread)">{{ formatTimeDMYHHMM(thread.created_at) }}</td>
          <td class="cursor-pointer"><i class="fa-regular fa-bucket" (click)="deleteThread(thread)"></i> </td>
        </tr>
        </tbody>
      </table>
      <!-- Pagination controls -->
      <div class="row d-flex justify-content-end">
        <nav aria-label="Page navigation">
          <ul class="pagination d-flex justify-content-center align-items-center mb-0">
            <li class="page-item cursor-pointer" [class.disabled]="page === 1" [class.active]="page !== 1">
              <a class="page-link" style="background-color: #5e656c; border-color: #5e656c" (click)="onPageChange(page - 1)"><i class="fa-regular fa-angle-left pe-2"></i>Prev</a>
            </li>
            <li class="page-item ms-3 me-3 disabled">
              <p class="mb-0" style="color: #8d8d8d;">Page {{page}} / {{totalPages}}</p>
            </li>
            <li class="page-item cursor-pointer" [class.disabled]="page === totalPages" [class.active]="page !== totalPages">
              <a class="page-link" style="background-color: #5e656c; border-color: #5e656c" (click)="onPageChange(page + 1)">Next<i class="fa-regular fa-angle-right ps-2"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="card-footer"  *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white"></div>
    </div>
  </div>
</app-card>
