<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"companySettings.editEmployee.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <img src="{{user.profile_image_url}}" alt="Profile Image" class="profile-image">
      </div>
    </div>
    <div class="row spacing-30">
      <div class="col-12">
        <h2 class="label-class">{{user.first_name}} {{user.last_name}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row spacing-30">
          <div class="col-12">
            <label class="f-16 f-bold label-class spacing-15">{{ "companySettings.editEmployee.phone" | translate }}</label>
            <p style="color: white">{{user.phone}}</p>
          </div>
        </div>
        <div class="row spacing-30">
          <div class="col-12">
            <label class="f-16 f-bold label-class spacing-15">{{ "companySettings.editEmployee.email" | translate }}</label>
            <p style="color: white">{{user.email}}</p>
          </div>
        </div>
        <div class="row spacing-30">
          <div class="col-12">
            <label class="f-16 f-bold label-class spacing-15">{{ "companySettings.editEmployee.role" | translate }}</label>
            <select class="form-select input-class" style="box-shadow: none" aria-label="Default select example" [(ngModel)]="selectedRoleId" (change)="editEmployeeRole()">
              <option value="1">{{"companySettings.inviteEmployees.role.admin" | translate}}</option>
              <option value="2">{{"companySettings.inviteEmployees.role.user" | translate}}</option>
            </select>
          </div>
        </div>
        <div class="row spacing-30">
          <div class="col-8">
            <label class="f-16 f-bold label-class spacing-15">{{"companySettings.inviteEmployees.access.label" | translate}}</label>
            <select class="form-select input-class" style="box-shadow: none" aria-label="Default select example" [(ngModel)]="user.component_access_id" (change)="editEmployeeRole()">
              <option value="" selected>{{'companySettings.inviteEmployees.access.placeholder' | translate}}</option>
              <option value="1">{{'companySettings.inviteEmployees.access.personal' | translate}}</option>
              <option value="2">{{'companySettings.inviteEmployees.access.master' | translate}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
