import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AssistantModelResponse, AssistantTypeResponse} from "../../../../../../@shared/models/response.module";
import {AiService} from "../../../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../../../../@core/services/toast.service";
import {Router} from "@angular/router";
import {COR_AII_26, COR_AII_27} from "../../../../../../@shared/models/input.module";

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrl: './edit-role.component.css'
})
export class EditRoleComponent {
  @Input() company_id: string
  @Input() assistantModel: AssistantModelResponse
  editRoleForm: FormGroup
  assistantModels: AssistantModelResponse[] = [];
  assistantTypes: AssistantTypeResponse[] = [];
  selectedFiles: File[] = [];
  loading: boolean = false;

  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService,
              private router: Router) {
    this.editRoleForm = new FormGroup({
      assistant_role_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_type_id: new FormControl('', [Validators.maxLength(200)]),
      model: new FormControl('', [Validators.maxLength(200)]),
      temperature: new FormControl({ value: 1, disabled: false }, [Validators.maxLength(200)]),
      instructions: new FormControl('', [Validators.maxLength(2000)] ),
      top_p: new FormControl({ value: 1, disabled: false }, [Validators.maxLength(200)]),
    });
  }

  ngOnInit() {
    this.getAssistantModels()
    this.getAssistantTypes()
    this.editRoleForm.patchValue({
      assistant_role_name: this.assistantModel.assistant_type_name,
      assistant_type_id: this.assistantModel.assistant_type_id,
      model: this.assistantModel.model,
      instructions: this.assistantModel.instructions,
      temperature: this.assistantModel.temperature ? this.assistantModel.temperature : 1,
      top_p: this.assistantModel.top_p ? this.assistantModel.top_p : 1,
    })
  }

  getAssistantTypes() {
    this.aiService.getAssistantTypes().subscribe((data: AssistantTypeResponse[]) => {
      this.assistantTypes = data;
    })
  }

  getAssistantModels() {
    this.aiService.getAssistantModels().subscribe((data: AssistantModelResponse[]) => {
      this.assistantModels = data;
    })
  }

  editRole() {
    this.loading = true;
    let payload: COR_AII_27 = {
      company_id: this.company_id,
      assistant_model_id: this.assistantModel.assistant_model_id,
      assistant_type_name: this.editRoleForm.get('assistant_role_name')!.value,
      assistant_type_id: this.editRoleForm.get('assistant_type_id')!.value,
      model: this.editRoleForm.get('model')!.value,
      instructions: this.editRoleForm.get('instructions')!.value,
      temperature: this.editRoleForm.get('temperature')!.value,
      top_p: this.editRoleForm.get('top_p')!.value
    }

    this.aiService.editAssistantModel(payload).subscribe((res) => {
      this.toastService.showSuccess('toast.assistantModel.edit.success', 'toast.success.title.success')
      this.modal.close(true);
    })
  }

  close() {
    this.modal.close();
  }

  onGroupChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const typeId = target.value;
    const assistantType = this.assistantModels.find(type => type.assistant_type_id == typeId);
    // this.filteredAssistants = selectedGroup!.assistants;
    // this.createThreadForm.get('assistant_id')!.setValue(this.filteredAssistants.find((assistant) => assistant.assistant_model_id = this.userOptions.preferred_assistant_type_id)?.assistant_id); // Reset the assistant dropdown
  }
}
