import { Component } from '@angular/core';
import {UserOptionsResponse} from "../../../../@shared/models/response.module";
import {UserService} from "../../../../@core/services/user.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {USM_USR_22} from "../../../../@shared/models/input.module";

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrl: './change-language.component.css'
})
export class ChangeLanguageComponent {
  userOptions: UserOptionsResponse
  selectedLanguage: string;

  constructor(private userService: UserService,
              private modal: NgbActiveModal) {
  }

  ngOnInit() {
    this.userService.getUserOptions().subscribe((data) => {
      this.userOptions = data;
      this.selectedLanguage = this.userOptions.language;
    });
  }

  changeLanguage() {
    let params: USM_USR_22 = {
      language: this.selectedLanguage,
      preferred_assistant_type_id: this.userOptions.preferred_assistant_type_id
    }
    this.userService.updateUserOptions(params).subscribe(() => {
      this.modal.close();
    });
  }

  close() {
    this.modal.close();
  }
}
