import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DefaultFileGroupResponse, DefaultFileResponse} from "../../../../@shared/models/response.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {_COR_AII_43, _COR_AII_44, _COR_AII_45, _COR_AII_47, _COR_AII_48} from "../../../../@shared/models/input.module";
import {formatTimeDMYHHMM} from "../../../../@core/utils/utils.service";

@Component({
  selector: 'app-default-files',
  templateUrl: './default-files.component.html',
  styleUrl: './default-files.component.css'
})
export class DefaultFilesComponent {
  defaultFileGroups: DefaultFileGroupResponse[] = []
  isSecondRowVisible: boolean = false;
  expandedIndex: number | null = null; // Track the expanded word index
  selectedFiles: File[] = [];
  uploadFiles: Boolean = false;

  constructor(private modal: NgbActiveModal,
              private aiService: AiService) { }


  ngOnInit() {
    this.getDefaultFileGroups();
  }

  getDefaultFileGroups() {
    let params: _COR_AII_43 = {}
    this.aiService.getDefaultFileGroups(params).subscribe((data: DefaultFileGroupResponse[]) => {
      this.defaultFileGroups = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });
  }

  toggleSecondRow(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  onFileSelected(event: any, defaultFileGroup: DefaultFileGroupResponse) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      for (let file of Array.from(files)) {
        this.uploadFile(file, defaultFileGroup);
      }
    }
  }

  uploadFile(file: File, defaultFileGroup: DefaultFileGroupResponse) {
    let params: _COR_AII_47 = {
      file: file,
      default_file_group_id: defaultFileGroup.default_file_group_id
    }
    this.aiService.addDefaultFileToGroup(params).subscribe((data) => {
      this.getDefaultFileGroups();
    });
  }

  removeFile(file: DefaultFileResponse) {
    let params: _COR_AII_48 = {
      default_file_id: file.file_id
    }
    this.aiService.deleteDefaultFileFromGroup(params).subscribe(() => {
      this.getDefaultFileGroups();
    });
  }

  addGroup(){
    let params: _COR_AII_44 = {
      default_file_group_name: '',
      default_file_group_description: ''
    }
    this.aiService.createDefaultFileGroup(params).subscribe((data) => {
      this.defaultFileGroups.push(data);
      this.toggleSecondRow(this.defaultFileGroups.length - 1); // Expand the newly added group
    });
  }

  onChange(event: DefaultFileGroupResponse){
    let params: _COR_AII_45 = {
      default_file_group_id: event.default_file_group_id,
      default_file_group_name: event.default_file_group_name,
      default_file_group_description: event.default_file_group_description
    }
    this.aiService.editDefaultFileGroup(params).subscribe((data) => {
      this.getDefaultFileGroups();
    });
  }

  close() {
    this.modal.close();
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
