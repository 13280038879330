<div class="box d-flex justify-content-center" style="padding-top: 150px">
  <div class="w-100">
    <div class="row d-flex align-items-center justify-content-start spacing-45">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 76px">
      </div>
    </div>
    <div class="row spacing-45">
      <div class="col-12">
        <h1 class="mb-0" style="color: white">{{ "login.header" | translate }}</h1>
      </div>
    </div>
    <form [formGroup]="loginForm">
      <div class="spacing-20">
        <label for="email" class="form-label f-16" style="color: white;">{{ "login.emailLabel" | translate }}</label>
        <div class="input-group">
          <input
            formControlName="email"
            required
            [placeholder]="'login.emailLabel' | translate"
            type="text"
            id="email"
            class="form-control"
          >
        </div>
        <span *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched"
              class="text-danger">{{ "login.invalidEmail" | translate }}</span>
      </div>

      <div class="spacing-30">
        <div class="form-group mt-3 position-relative">
          <div class="d-flex justify-content-between">
            <label for="email" class="form-label f-16" style="color: white;">{{ "login.passwordLabel" | translate }}</label>
            <a class="text-muted p-0" href="/auth/forgot-password" style="color: #707070; padding-top: 15px">
              <small style="color: #707070" href="/auth/forgot-password">{{ "login.forgotPasswordLabel" | translate }}</small>
            </a>
          </div>
          <input id="password" type="password" class="form-control" name="password" formControlName="password" required
                 minlength="2">
          <i *ngIf="!passwordVisible" class="fa fa-eye password-icon" style="color: #9C9AA5"
             (click)="togglePasswordVisibility('password')"></i>
          <i *ngIf="passwordVisible" class="fa fa-eye-slash password-icon" style="color: #9C9AA5"
             (click)="togglePasswordVisibility('password')"></i>
        </div>

        <span *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
              class="text-danger">{{ "login.invalidPassword" | translate }}</span>

        <div class="form-group mt-2">
          <div *ngIf="isLoginFailed" class="alert alert-danger" role="alert">
            {{ "login.loginFailedMessage" | translate }} {{ "login.loginFailedReason" | translate }}
          </div>
        </div>
      </div>

<!--      <div class="row spacing-8">-->
<!--        <div class="col-12">-->
<!--          <i class="fa fa-check me-2" style="color: #ED622E"></i>-->
<!--          <a style="color: white"> Info</a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row spacing-8">-->
<!--        <div class="col-12">-->
<!--          <i class="fa fa-check me-2" style="color: #ED622E"></i>-->
<!--          <a style="color: white"> Info</a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row spacing-8">-->
<!--        <div class="col-12">-->
<!--          <i class="fa fa-check me-2" style="color: #ED622E"></i>-->
<!--          <a style="color: white"> Info</a>-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-group mt-3 ">
        <div class="d-flex justify-content-between">
          <button class="btn btn-primary btn-block w-100" style="background-color: #5DA9D7; border-color: #5DA9D7; height: 48px" (click)="onSubmit()">
            {{ "login.loginButtonLabel" | translate }}
          </button>
        </div>
        <div class="d-flex justify-content-end">
        </div>
      </div>
    </form>
  </div> <!-- end card-body -->
</div>
