<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"assistants.createAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">The Assisant Name would typically be the name of the client or the project you are creating an assistant for. We recommend adding both a reference and a description to give everyone with access as much information as possible. You can add up to 200 documents per assistant. Documents can also be uploaded after the assistant has been created. You can only upload files in a PDF format. If you make the assistant private it will only be accessible to you.</p>
      </div>
    </div>
    <form [formGroup]="createAssistantForm">
      <div class="row">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.assistantName.label" | translate}}</label>
          <div class="spacing-30">
            <div class="input-group">
              <input
                type="text"
                class="form-control input-class"
                formControlName="assistant_name"
                placeholder="{{'assistants.createAssistant.assistantName' | translate}}"
              >
            </div>
            <span *ngIf="createAssistantForm.get('assistant_name')?.invalid && createAssistantForm.get('assistant_name')?.touched"
                  class="text-danger">{{ "global.requiredField" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.reference.label" | translate}}</label>
          <div class="input-group spacing-30">
            <input
              type="text"
              class="form-control input-class"
              formControlName="reference"
              placeholder="{{'assistants.createAssistant.reference.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"assistants.createAssistant.description.label" | translate}}</label>
          <div class="input-group spacing-30">
            <input
              type="text"
              class="form-control input-class"
              formControlName="assistant_group_description"
              placeholder="{{'assistants.createAssistant.description.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold label-class " for="flexSwitchCheckDefault">{{ "assistants.createAssistant.private.label" | translate }}</label>
          </div>
        </div>
      </div>

      <div class="row spacing-30" style="padding-top: 40px">
        <div class="col-8 dropzone" style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 40px;">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
          <div style="display: flex; flex-direction: column; align-items: center;">
            <i class="fa fa-regular fa-cloud-arrow-up fa-2x pb-2" style="color: #ED622E;"></i>
            <div style="display: flex; justify-content: center; align-items: center;">
              <p class="fw-semibold f-16 me-1 cursor-pointer" style="color: #ED622E; margin: 0;">Click to upload</p>
              <p class="f-16" style="color: #909699; margin: 0;">or drag and drop</p>
            </div>
            <p class="f-16" style="color: #909699; margin: 0;">pdf, PDF, docx</p>
          </div>
        </div>
        <div class="row pt-3" *ngFor="let file of selectedFiles">
          <div class="col-12 d-flex ms-2 align-items-center">
            <i style="color: #909699"  class="fa fa-file-pdf fa-2x me-2"></i>
            <h5 style="color: #909699"  class="m-0">{{file.name}}</h5>
          </div>
        </div>
      </div>
<!--      <div class="row pt-3">-->
<!--        <div class="col-8">-->
<!--          <label class="pb-1" style="font-weight: bold">{{"assistants.createAssistant.file.label" | translate}}</label>-->
<!--          <input type="file" (change)="onFileSelected($event)" accept="application/pdf" />-->
<!--        </div>-->
<!--      </div>-->
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createAssistant()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>

