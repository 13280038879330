<div class="container-fluid h-100">
  <div class="row d-flex align-items-stretch">
    <!-- Collapsible Sidebar -->
    <div id="sidebar" class="bg-dark p-0 custom-sidebar"
         [ngClass]="{'col-md-2 col-lg-2': !collapsed, 'd-none': collapsed}"
         style="background-color: #0D0F11; overflow-y: auto;">
      <ul class="list-group list-group-flush">
        <!-- Sidebar Header -->
        <li class="list-group-item text-white d-flex justify-content-between align-items-center mb-0" style="height: 50px;">
          <h5 class="m-0">{{ "personalAssistant.sidebarBtn" | translate }}</h5>
          <i class="fa-regular fa-pen-to-square fa-lg" (click)="createNewThread()"></i>
        </li>
        <hr class="m-0" style="color: white">
<!--        <li class="d-flex justify-content-center pt-2 pb-2">-->
<!--          <div class="btn btn-primary w-75" (click)="createNewThread()">{{"personalAssistant.sidebar.createNew" | translate}}</div>-->
<!--        </li>-->
        <li
          class="list-group-item text-white cursor-pointer"
          *ngFor="let thread of threads"
        >
          <div class="d-flex justify-content-between align-items-center" (click)="changeThread(thread)">
            <a href="javascript:void(0)" class="text-white text-decoration-none d-flex align-items-center w-100">
              <p class="mb-0 d-inline-block" *ngIf="thread.thread_id != selectedThread.thread_id">
                {{ thread.topic }}
              </p>
              <p class="mb-0 d-inline-block" *ngIf="thread.thread_id == selectedThread.thread_id" style="color: #ED622E">
                {{ thread.topic }}
              </p>
              <i class="fa fa-regular fa-xmark ms-auto" (click)="deleteThread(thread)"></i>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="col" [ngClass]="{'custom-offset pt-2': !collapsed, '': collapsed}" style="overflow-y: auto;">
      <div class="container-fluid">
        <div class="row p-0">
          <div class="col-12">
            <div class="card-header d-flex justify-content-between align-items-center pt-2 pe-3 pb-2 ps-0" *ngIf="selectedThread" [ngClass]="{'pb-3': !collapsed}">
              <div class="d-flex align-items-center">
                <button (click)="collapsed = !collapsed" class="btn btn-primary m-2 me-2" *ngIf="collapsed">
                  {{ "personalAssistant.sidebarBtn" | translate }}
                </button>
                <h5 class="mb-0" style=" color: white">{{selectedThread.topic}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center w-100">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="test card" id="chat2" style="border-radius: 15px;">
              <div class="card-body" *ngIf="selectedThread" #scrollContainer>
                <div>
                  <div class="d-flex flex-row justify-content-start">
                    <img src="../../favicon.ico../../favicon.ico"
                         alt="avatar 1" style="width: 40px; height: 100%;">
                    <div style="max-width: 70%">
                      <p class="small p-2 ms-3 mb-1 rounded-4 " style="background-color: #36363B; color: white">Welcome to your new dialogue, You can start the conversation by asking a question in the input blow or select a question set from the dropdown menu above</p>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of selectedThread.messages">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="d-flex flex-row justify-content-start position-relative">
                      <img src="../../favicon.ico" alt="avatar 1" style="width: 40px; height: 100%;">
                      <div style="max-width: 70%; position: relative;">
                        <div class="small p-2 ms-3 mb-1 rounded-4" style="background-color: #36363B; color: white; position: relative;">
                          <markdown data="{{message.content}}"></markdown>

                          <!-- Button in the bottom right corner -->
                          <button class="copy-btn btn btn-link p-0" (click)="copyMessage(message.content)"
                                  style="position: absolute; top: 5px; right: -55px;">
                            <i class="fa-solid fa-copy" style="color: white;"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div style="max-width: 70%">
                        <p class="small p-2 me-3 rounded-4"
                           style="background-color: #e3e3e3; color: black; white-space: break-spaces;">
                          <markdown data="{{message.content.trimEnd()}}"></markdown>
                        </p>
                      </div>
                      <img src="{{user.profile_image_url}}"
                           alt="avatar 1" style="width: 45px; height: 45px; border-radius: 50%;" *ngIf="user.profile_image_url">
                      <div *ngIf="!user.profile_image_url" class="profile-initials-circle me-2">
                        {{ initials }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pb-3 pt-2">
                <div class="" *ngIf="selectedFileAttachement" style="left: 0!important;">
                  <div class="rounded-2 d-flex p-2 align-items-center mb-2 justify-content-start" style="background-color: white">
                    <i class="fa fa-regular fa-file me-2"></i>
                    <p class="m-0 me-2" style="color: black;">{{selectedFileAttachement.name}}</p>
                    <i class="fa fa-regular fa-xmark" (click)="removeAttachment()"></i>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                                        <textarea
                                          class="form-control"
                                          id="exampleFormControlTextarea1"
                                          [(ngModel)]="messageInput"
                                          placeholder="{{ 'threads.viewThread.input.placeholder' | translate}}"
                                          (input)="autoResize($event)"
                                          (keydown)="handleKeyDown($event)"
                                          style="box-shadow: 0px 1px 2px rgba(200, 200, 200, 0.5); resize: none;"
                                          rows="1"
                                          [disabled]="loading"
                                        ></textarea>
                    <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
                      <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
                    </div>
                    <a class="ms-1 text-muted ms-3" (click)="fileInput.click()"><i class="fas fa-paperclip"  style="color: white"></i></a>
                    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    <a class="ms-3" (click)="addNewMessageToThread()"><i class="fas fa-paper-plane" style="color: #ED622E"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


