<div class="box d-flex align-items-center justify-content-center">
  <div>
    <div class="row d-flex align-items-center justify-content-start">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 80px">
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <!--        <h1 class="mb-0" style="color: #707070">{{ "login.header" | translate }}</h1>-->
      </div>
    </div>
    <div class="row pt-1">
      <div class="col-12">
        <p style="color: #707070">{{ "forgotPassword.description" | translate }}</p>
      </div>
    </div>
    <form [formGroup]="resetForm">
      <div class="input-group">
        <input
          formControlName="email"
          required
          [placeholder]="'login.emailLabel' | translate"
          type="text"
          id="email"
          class="form-control"
        >
      </div>
      <span *ngIf="resetForm.get('email')?.invalid && resetForm.get('email')?.touched"
            class="text-danger">{{ "login.invalidEmail" | translate }}</span>

      <div class="form-group mt-3 ">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-block" style="background-color: #5DA9D7; border-color: #5DA9D7" (click)="onSubmit()">
            {{ "forgotPassword.sendResetBtn" | translate }}
          </button>
        </div>
        <div class="d-flex justify-content-end">
        </div>
      </div>
    </form>
  </div> <!-- end card-body -->
</div>
