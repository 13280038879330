<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" (click)="activeTab = 'dashboard'">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" (click)="activeTab = 'companies'">Companies</a>
        </li>
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" routerLink="/reports" routerLinkActive="active">Reports</a>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</nav>

<div class="mt-4" *ngIf="activeTab =='companies'">
  <app-card
    [titleLocalisationStrong]="'superAdmin.companies.title'"
    [footerWithButton]="true"
    [buttonLocalisationString]="'superAdmin.companies.createCompany'"
    (buttonClick)="createCompany()"
  >
    <div class="row mb-2 me-1 d-flex justify-content-end">
      <div class="col pe-0 d-flex justify-content-end">
        <div class="" *ngIf="search">
          <form [formGroup]="searchForm" (focusout)="onSearch()" (change)="onSearch()">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                formControlName="searchString"
                style="background-color: white; border-color: white; width: 400px;"
                placeholder="{{'superAdmin.companies.searchPlaceholder' | translate}}"
              >
            </div>
          </form>
        </div>
        <div class="ms-2">
          <div class="btn btn-outline-info ps-0 pe-0 custom-button" style="border-color: white;" (click)="toggelSearch()">
            <i class="fa-regular fa-search" style="color: white"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!loading">
      <div class="col-12">
        <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col">{{"superAdmin.companies.table.companyId" | translate}}</th>
            <th scope="col">{{"superAdmin.companies.table.companyName" | translate}}</th>
            <th scope="col">{{"superAdmin.companies.table.orgNr" | translate}}</th>
            <th scope="col">{{"superAdmin.companies.table.createdAt" | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let company of companies" class="table-row-hover">
            <td class="cursor-pointer" (click)="goToCompanyAdministration(company)">{{ company.company_id }}</td>
            <td class="cursor-pointer" (click)="goToCompanyAdministration(company)">{{ company.company_name }}</td>
            <td class="cursor-pointer" (click)="goToCompanyAdministration(company)">{{ company.organisation_number }}</td>
            <td class="cursor-pointer" (click)="goToCompanyAdministration(company)">{{ formatTimeDMYHHMM(company.created_at)}}</td>
          </tr>
          </tbody>
        </table>
        <!-- Pagination controls -->
        <div class="row d-flex justify-content-end">
          <nav aria-label="Page navigation">
            <ul class="pagination d-flex justify-content-center align-items-center mb-0">
              <li class="page-item" [class.disabled]="page === 1" [class.active]="page !== 1">
                <a class="page-link cursor-pointer" style="background-color: #5e656c; border-color: #5e656c" (click)="onPageChange(page - 1)"><i class="fa-regular fa-angle-left pe-2"></i>Prev</a>
              </li>
              <li class="page-item ms-3 me-3 disabled">
                <p class="mb-0" style="color: #8d8d8d;">Page {{page}} / {{totalPages}}</p>
              </li>
              <li class="page-item" [class.disabled]="page === totalPages" [class.active]="page !== totalPages">
                <a class="page-link cursor-pointer" style="background-color: #5e656c; border-color: #5e656c" (click)="onPageChange(page + 1)">Next<i class="fa-regular fa-angle-right ps-2"></i></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="card-footer" *ngIf="loading">
      <div class="d-flex justify-content-center">
        <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white"></div>
      </div>
    </div>
  </app-card>
</div>

<div class="mt-4" *ngIf="activeTab =='dashboard'">
  <app-card
    [titleLocalisationStrong]="'superAdmin.dashboard.title'"
    [footerWithButton]="false"
  >
    <div class="row pb-3" *ngIf="messageStats">
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Messages Added</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{messageStats.total_num_questions}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white;">Total Last 7 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{messageStats.total_num_questions_last_7_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Last 30 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{messageStats.total_num_questions_last_30_days}}</h4>
          </div>
        </div>
      </div>
    </div>
    <hr style="color: white">
    <div class="row pt-3 pb-3" *ngIf="tokenStats">
      <h5 style="color: white">Total Token Usage</h5>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Token Usage</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{tokenStats.total_token_usage}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Input Token Usage</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{tokenStats.total_input_token_usage}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Output Token Usage</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{tokenStats.total_response_token_usage}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-3" *ngIf="tokenStats">
      <h5 style="color: white">Total Last 7 Days</h5>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Last 7 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{tokenStats.total_token_usage_last_7_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white;">Total Input Last 7 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{tokenStats.total_input_token_usage_last_7_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Output Last 7 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{tokenStats.total_response_token_usage_last_7_days}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3" *ngIf="tokenStats">
      <h5 style="color: white">Total Last 30 Days</h5>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Last 30 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{tokenStats.total_token_usage_last_30_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Input Last 30 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{tokenStats.total_input_token_usage_last_30_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Input Last 30 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{tokenStats.total_response_token_usage_last_30_days}}</h4>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</div>
