<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"superAdmin.createCompany.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <form [formGroup]="companyForm" (ngSubmit)="submit()">
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="company_name">{{"superAdmin.createCompany.companyName.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="company_name" formControlName="company_name">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="organization_number">{{"superAdmin.createCompany.orgNr.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="organization_number" formControlName="organization_number">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="address">{{"superAdmin.createCompany.address.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="address" formControlName="address">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="city">{{"superAdmin.createCompany.city.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="city" formControlName="city">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="postal_code">{{"superAdmin.createCompany.postalCode.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="postal_code" formControlName="postal_code">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="country">{{"superAdmin.createCompany.country.label" | translate}}</label>
            <input type="text" class="form-control input-class" id="country" formControlName="country">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="email">{{"superAdmin.createCompany.email.label" | translate}}</label>
            <input type="email" class="form-control input-class" id="email" formControlName="email">
          </div>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold label-class spacing-15" for="phone">{{"superAdmin.createCompany.phone.label" | translate}}</label>
            <div class="input-group">
              <input type="tel"
                     class="form-control input-class"
                     id="phone"
                     formControlName="phone"
                     placeholder="Enter your phone number">
            </div>
            <div *ngIf="companyForm.get('phone')!.invalid && companyForm.get('phone')!.touched" class="text-danger">
              Please enter exactly 8 digits.
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end spacing-30">
        <div class="col-12">
          <button type="submit" class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">{{"profile.changePassword.submit" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
