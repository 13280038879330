<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{"templates.edit.title" | translate}}</h5>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="editTemplateForm" (change)="editTemplate()">
      <div class="row">
        <div class="col-8">
          <label class="form-check-label">{{'templates.edit.templateNameLabel' | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="templateName"
              placeholder="{{'templates.edit.templateName' | translate}}"
            >
          </div>
          <span *ngIf="editTemplateForm.get('templateName')?.invalid && editTemplateForm.get('templateName')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-8">
          <label class="form-check-label">{{'templates.edit.templateDescriptionLabel' | translate}}</label>
          <input
            type="text"
            class="form-control"
            formControlName="templateDescription"
            placeholder="{{'templates.edit.templateDescriptionPlaceholder' | translate}}"
          >
        </div>
      </div>
      <div class="row pt-3" *ngIf="userId && template.created_by?.user_id == userId">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label" for="flexSwitchCheckDefault">{{ "templates.createNew.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
    <hr *ngIf="template.private">
    <div class="row" *ngIf="template.private">
      <div class="col-12">
        <h5 class="form-check-label">{{'templates.accessRelation.label' | translate}}</h5>
        <div class="box">
          <div class="row" *ngFor="let relation of template.access_relations">
            <div class="col-4">
              <span style="color: #5e656c">{{getUserData(relation.user_id)?.full_name}}</span>
            </div>
            <div class="col-2">
              <span>{{relation.access_level.access_level_name}}</span>
            </div>
            <div class="col-4" *ngIf="canEdit">
              <i class="fa-regular fa-bucket" style="color: #dc3545" (click)="removeRelation(relation.relation_id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-3" *ngIf="template.private && canEdit" >
      <div class="col-8 d-flex justify-content-between">
        <div class="dropdown me-3">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span *ngIf="selectedOption">{{ selectedOption.full_name }}</span>
            <span *ngIf="!selectedOption">{{'assistants.details.share.select' | translate }}</span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              [(ngModel)]="searchTerm"
            />
            <li *ngFor="let option of filteredOptions()" style="cursor: pointer">
              <a
                class="dropdown-item"
                (click)="selectOption(option)"
              >{{ option.full_name }}</a
              >
            </li>
          </ul>
        </div>
        <select class="form-select" *ngIf="selectedOption" aria-label="Default select example" [(ngModel)]="selectedAccessLevel">
          <option value="99" selected>{{'assistants.details.share.accessLevel.placeholder' | translate}}</option>
          <option value="0">{{"assistants.details.share.accessLevel.owner" | translate}}</option>
          <option value="1">{{"assistants.details.share.accessLevel.edit" | translate}}</option>
          <option value="2">{{"assistants.details.share.accessLevel.view" | translate}}</option>
        </select>
        <div class="btn btn-primary ms-3" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" *ngIf="selectedOption && selectedAccessLevel" (click)="addRelation()">
          {{ "assistants.details.share.save" | translate }}
        </div>
      </div>
    </div>
    <hr>
    <h5>{{ "questions.title" | translate }}</h5>
<!--    <div *ngFor="let question of questions; let i = index" class="pb-2">-->
<!--      <app-question [question]="question" [questionAnswerTypes]="questionAnswerTypes" (questionChange)="handleQuestionChange($event)" (questionDelete)="handleQuestionDelete($event)"></app-question>-->
<!--    </div>-->
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      @for (question of questions; track question) {
        <div class="example-box" cdkDrag>
          <app-question
            [question]="question"
            [questionAnswerTypes]="questionAnswerTypes"
            (questionChange)="handleQuestionChange($event)"
            (questionDelete)="handleQuestionDelete($event)">
          </app-question>
        </div>
      }
    </div>
    <div class="btn" style="color: blue" (click)="addQuestion()" *ngIf="canEdit">Add question</div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" (click)="save()">{{"global.save" | translate}}</div>
  </div>
</div>
