<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"threads.createThread.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can create new dialogues with any of your Master Assistants. You need to select an assistant to create a dialogue. You also need to select an assistant role. The assistant roles have different instructions and they behave and respond differently. Try to select the assistant role that best suits your task. We recommend adding a Topic in order to easier keep track of your dialogues. </p>
      </div>
    </div>
    <form [formGroup]="createThreadForm">
      <div class="row spacing-30" *ngIf="!details">
        <div class="col">
          <label class="f-16 f-bold label-class spacing-15">{{"threads.createThread.assistantLabel" | translate}}</label>
          <select class="form-select input-class" aria-label="Default select example" formControlName="assistant_group_id"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'threads.createThread.assistantLabel' | translate}}</option>
            <option *ngFor="let group of assistantsGroups" [value]="group.assistant_group_id">
              ({{ group.reference }}) {{ group.assistant_group_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row spacing-30">
        <div class="col">
          <label class="f-16 f-bold label-class spacing-15">{{"threads.createThread.assistantRoleLabel" | translate}}</label>
          <select class="form-select input-class" aria-label="Default select example" formControlName="assistant_id">
            <option value="" selected>{{'threads.createThread.assistantPlaceholder' | translate}}</option>
            <option *ngFor="let assistant of filteredAssistants" [value]="assistant.assistant_id">
              {{ assistant.assistant_type_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col">
          <label class="f-16 f-bold label-class spacing-15">{{"threads.createThread.topicLabel" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="topic"
              placeholder="{{'threads.createThread.topic' | translate}}"
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
    <div class="btn btn-primary m-0" style="background-color: #5e656c; border-color: #5e656c" (click)="createThread()">
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>

