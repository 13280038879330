<app-card
  [titleLocalisationStrong]="'threads.title'"
  [footerWithButton]="false"
>
  <div class="row spacing-30">
    <div class="col-10">
      <p style="color: #909699">Here is a list of all the dialogues that you have created with the Master Assistants. Dialogues are private, and they are only accessible to you. You can search the list of dialogues, and you can also create a dialogue from this page. </p>
    </div>
  </div>
  <div class="row mb-2 me-1 d-flex justify-content-end">
    <div class="col pe-0 d-flex justify-content-between spacing-30">
      <div class="">
        <form [formGroup]="searchForm" (focusout)="onSearch()" (change)="onSearch()">
          <div class="input-group">
            <input
              type="text"
              class="form-control f-14"
              formControlName="searchString"
              style="background-color: #202020; border-color: #A7A7A7; width: 330px; color: white; height: 40px;"
              placeholder="{{'assistants.searchString.placeholder' | translate}}"
            >
          </div>
        </form>
      </div>
      <button class="btn btn-primary f-14" (click)="createThread()">{{"threads.createThreadBtn" | translate}}</button>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <table class="table rounded-table table-hover" *ngIf="!loading">
        <thead>
        <tr>
          <!--          <th scope="col">#</th>-->
          <th scope="col" class="f-12 ps-3">{{"threads.table.reference" | translate}}</th>
          <th scope="col" class="f-12">{{"threads.table.threadName" | translate}}</th>
          <th scope="col" class="f-12">{{"threads.table.topic" | translate}}</th>
          <th scope="col" class="f-12">{{"threads.table.assistant" | translate}}</th>
          <th scope="col" class="f-12">{{"threads.table.created" | translate}}</th>
          <th scope="col" class="pe-3"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="threads.length === 0">
          <td colspan="12" class="text-center" style="color: white; font-weight: bold;">
            {{ "threads.noThreads" | translate }}
          </td>
        </tr>
        <tr *ngFor="let thread of threads">
          <!--          <th scope="row">{{assistant.assistant_id}}</th>-->
          <td class="cursor-pointer f-14" (click)="goToThread(thread)">
            <div class="ps-3">
              {{ thread.assistant.reference }}
            </div>
          </td>
          <td class="cursor-pointer f-14" (click)="goToThread(thread)">{{ thread.assistant.assistant_name }}</td>
          <td class="cursor-pointer f-14" (click)="goToThread(thread)">{{ thread.topic }}</td>
          <td class="cursor-pointer f-14" (click)="goToThread(thread)">{{ thread.assistant.assistant_type_name }}</td>
          <td class="cursor-pointer f-14" (click)="goToThread(thread)">{{ formatTimeDMYHHMM(thread.created_at) }}</td>
          <td class="cursor-pointer f-14">
            <div class="pe-3 d-flex justify-content-end">
              <i class="fa-regular fa-file fa-lg pe-3" (click)="viewThreadFiles(thread)"></i>
              <i class="fa-regular fa-trash-can fa-lg" (click)="deleteThread(thread)"></i>
              <i class="fa-regular fa-pen fa-lg ms-3" ></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="row d-flex justify-content-center">
        <div class="pagination col d-flex justify-content-center">
          <button *ngFor="let page1 of displayedPages"
                  (click)="selectPage(page1)"
                  [class.active]="page1 === page"
                  class="pagination-button me-2"
          >
            {{ page1 }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer"  *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white"></div>
    </div>
  </div>
</app-card>
