import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  QuestionAnswerTypeResponse,
  QuestionResponse,
  TemplateResponse, UserEntityRelationWithUserDataResponse
} from "../../../../@shared/models/response.module";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../../../@shared/services/template.service";
import {question} from "../create-template/create-template.component";
import {
  _COR_AII_21,
  _COR_AII_23, _COR_TMP_10, _COR_TMP_12,
  _COR_TMP_2,
  _COR_TMP_4,
  _COR_TMP_5, COR_AII_29,
  COR_TMP_4
} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";
import {CompanyService} from "../../../../@shared/services/company.service";
import {StorageService} from "../../../../@core/services/storage.service";
import {AiService} from "../../../../@shared/services/ai.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrl: './edit-template.component.css'
})
export class EditTemplateComponent implements OnInit, OnChanges {
  @Input() template!: TemplateResponse;
  editTemplateForm: FormGroup
  questionAnswerTypes: QuestionAnswerTypeResponse[] = [];
  questions: QuestionResponse[] = [];
  options: UserEntityRelationWithUserDataResponse[] = [];
  searchTerm: string = '';
  selectedOption: UserEntityRelationWithUserDataResponse | null
  usersWithRelation: UserEntityRelationWithUserDataResponse[] = [];
  selectedAccessLevel: number = 99;
  updatedQuestions: _COR_TMP_5[] = [];
  canEdit: boolean = false;
  userId: string = '';

  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private toastService: ToastService,
              private companyService: CompanyService,
              private storageService: StorageService,
              private aiService: AiService) {
    this.editTemplateForm = new FormGroup({
      templateName: new FormControl('', {validators: [Validators.required]}),
      templateDescription: new FormControl('', [Validators.maxLength(200)]),
      private: new FormControl(false)
    });
  }

  ngOnInit() {
    this.editTemplateForm.get('templateName')?.setValue(this.template.template_name);
    this.editTemplateForm.get('templateDescription')?.setValue(this.template.description);
    this.editTemplateForm.get('private')?.setValue(this.template.private);
    this.questions = this.template.questions.sort((a, b) => a.index - b.index);
    this.userId = this.storageService.getUser().user_id;
    if (this.template.created_by?.user_id == this.storageService.getUser().user_id || !this.template.private || this.template.access_relations.find((relation) => relation.user_id == this.storageService.getUser().user_id && relation.access_level.access_level_id in [0, 1])) {
      this.canEdit = true;
    }
    this.templateService.getQuestionAnswerTypes().subscribe((questionAnswerTypes: QuestionAnswerTypeResponse[]) => {
      this.questionAnswerTypes = questionAnswerTypes;
    });

    this.companyService.getCompanyEmployees().subscribe((data) => {
      this.options = data;
      for (let option of this.options) {
        option.full_name = option.first_name + ' ' + option.last_name;
      }
      for (let relation of this.template.access_relations) {
        this.usersWithRelation.push(this.getUserData(relation.user_id)!)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.editTemplateForm.get('templateName')?.setValue(this.template.template_name);
    this.editTemplateForm.get('templateDescription')?.setValue(this.template.description);
    this.questions = this.template.questions;
  }

  getUserData(user_id: string){
    return this.options.find((option) => option.user_id == user_id)
  }

  addQuestion() {
    let newQuestion: _COR_TMP_4 = {
      template_id: this.template.template_id,
      display_name: '',
      question: '',
      context: '',
      question_answer_type_id: 0,
      index: this.questions.length
    }
    this.templateService.addQuestionToTemplate(newQuestion).subscribe((res: TemplateResponse) => {
      if (res) {
        this.template = res;
        this.questions = res.questions.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        if (this.updatedQuestions.length > 0) {
          for (let question of this.updatedQuestions) {
            this.questions.find((q) => {
              if (q.question_id == question.question_id) {
                q.display_name = question.display_name
                q.question = question.question
                q.context = question.context ? question.context : ''
              }
            })
          }
        }
        this.templateService.templatesUpdatedSubject.next(true);
        this.toastService.showSuccess('toast.questionAdded.success', 'toast.success.title.success');
      }
    })
  }

  handleQuestionChange(question: QuestionResponse) {
    if (this.updatedQuestions.find((q) => q.question_id == question.question_id)) {
      this.updatedQuestions = this.updatedQuestions.filter((q) => q.question_id != question.question_id);

    }
    this.questions.forEach((exQuestion) => {
      if (exQuestion.question_id === question.question_id) {
        exQuestion.display_name = question.display_name;
        exQuestion.question = question.question;
      }
    });
    let payload: _COR_TMP_5 = {
      question_id: question.question_id,
      display_name: question.display_name,
      question: question.question,
      context: question.context ? question.context : "",
      question_answer_type_id: question.question_answer_type_id,
      index: question.index
    }
    this.updatedQuestions.push(payload);
  }

  handleQuestionDelete(question_id: number) {
    this.questions = this.questions.filter((question) => question.question_id !== question_id);
  }

  close() {
    this.modal.close();
  }

  editTemplate() {
    let payload: _COR_TMP_2 = {
      template_id: this.template.template_id,
      template_name: this.editTemplateForm.get('templateName')?.value,
      template_description: this.editTemplateForm.get('templateDescription')?.value,
      private: this.editTemplateForm.get('private')?.value
    }

    this.templateService.editTemplate(payload).subscribe((res: TemplateResponse) => {
      if (res) {
        this.template = res;
        this.templateService.templatesUpdatedSubject.next(true);
        this.toastService.showSuccess('toast.templateUpdated.success', 'toast.success.title.success');
      }
    })
  }

  filteredOptions(): UserEntityRelationWithUserDataResponse[] {
    return this.options.filter(option =>
      option.full_name!.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectOption(option: UserEntityRelationWithUserDataResponse): void {
    this.selectedOption = option;
    this.searchTerm = ''; // Clear search term after selection
  }

  addRelation() {
    if(this.selectedAccessLevel != 99 && this.selectedOption){
      let payload: _COR_TMP_10 = {
        template_id: this.template.template_id,
        user_id: this.selectedOption.user_id,
        access_level_id: this.selectedAccessLevel
      }
      this.templateService.createTemplateAccessRelation(payload).subscribe((data) => {
        this.template.access_relations.push(data.data);
        this.selectedOption = null;
        this.selectedAccessLevel = 99;
        this.toastService.showSuccess('toast.templateRelation.add.success', 'toast.success.title.success')
      })
    }
  }

  removeRelation(relation_id: number) {
    let payload: _COR_TMP_12 = {
      relation_id: relation_id
    }
    this.templateService.deleteTemplateAccessRelation(payload).subscribe((data) => {
      this.template.access_relations = this.template.access_relations.filter((relation) => relation.relation_id != relation_id);
      this.toastService.showSuccess('toast.templateRelation.success', 'toast.success.title.success')
    })
  }

  save(){
    for(let question of this.updatedQuestions){
      this.templateService.editQuestion(question).subscribe((res: TemplateResponse) => {
        if (res) {
          this.template = res;
          this.questions = res.questions.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          this.templateService.templatesUpdatedSubject.next(true);
          this.toastService.showSuccess('toast.questionUpdated.success', 'toast.success.title.success');
          this.modal.close();
        }
      })
    }
  }

  drop(event: CdkDragDrop<any[]>): void {
    // Step 1: Move the item to the new position in the array
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);

    // Step 2: Update the index values for each question based on the new order
    this.questions.forEach((item, idx) => (item.index = idx));

    // Optional: Log the updated order for debugging
    console.log('Updated questions:', this.questions);

    for (let question of this.questions) {
      let payload: _COR_TMP_5 = {
        question_id: question.question_id,
        display_name: question.display_name,
        question: question.question,
        context: question.context,
        question_answer_type_id: question.question_answer_type_id,
        index: question.index
      }
      this.templateService.editQuestion(payload).subscribe((res: TemplateResponse) => {
        // this.questions = res.questions.sort((a, b) => a.index - b.index);
      })
    }
  }
}
