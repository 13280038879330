import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./pages/auth/login/login.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {AuthGuard} from "./@core/guards/auth.guard";
import {LayoutComponent} from "./pages/auth/layout/layout.component";
import {QnaComponent} from "./pages/qna/qna.component";
import {QuestionTemplatesComponent} from "./pages/question-templates/question-templates.component";
import {AssistantsComponent} from "./pages/assistants/assistants.component";
import {ThreadsComponent} from "./pages/threads/threads.component";
import {ViewThreadComponent} from "./pages/threads/components/view-thread/view-thread.component";
import {HomeComponent} from "./pages/home/home.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {AssistantDetailsComponent} from "./pages/assistant-details/assistant-details.component";
import {RegisterUserComponent} from "./pages/auth/register-user/register-user.component";
import {ForgotPassordComponent} from "./pages/auth/forgot-passord/forgot-passord.component";
import {ResetPasswordComponent} from "./pages/auth/reset-password/reset-password.component";
import {SuperAdminComponent} from "./pages/super-admin/super-admin.component";
import {
  CompanyAdministrationComponent
} from "./pages/super-admin/components/company-administration/company-administration.component";
import {PersonalAssistantComponent} from "./pages/personal-assistant/personal-assistant.component";

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent},
  { path: 'auth', component: LayoutComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: 'register-user', component: RegisterUserComponent},
      { path: 'forgot-password', component: ForgotPassordComponent},
      { path: 'reset-password', component: ResetPasswordComponent}
    ]
  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'qna', component: QnaComponent, canActivate: [AuthGuard] },
  { path: 'personal-assistant', component: PersonalAssistantComponent, canActivate: [AuthGuard] },
  { path: 'assistants', component: AssistantsComponent, canActivate: [AuthGuard] },
  { path: 'threads', component: ThreadsComponent, canActivate: [AuthGuard] },
  { path: 'templates', component: QuestionTemplatesComponent, canActivate: [AuthGuard] },
  { path: 'threads/view/:id', component: ViewThreadComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'super-admin', component: SuperAdminComponent, canActivate: [AuthGuard] },
  { path: 'super-admin/company-administration/:id', component: CompanyAdministrationComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'assistants/details/:id', component: AssistantDetailsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
