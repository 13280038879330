<div class="box d-flex justify-content-center" style="padding-top: 150px">
  <div>
    <div class="row d-flex align-items-center justify-content-start spacing-45">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 76px">
      </div>
    </div>
    <div class="row spacing-15">
      <div class="col-12">
        <h1 class="mb-0" style="color: white">{{ "login.forgotPasswordLabel" | translate }}</h1>
      </div>
    </div>
    <div class="row pt-1 spacing-45">
      <div class="col-12">
        <p style="color: white">{{ "forgotPassword.description" | translate }}</p>
      </div>
    </div>
    <form [formGroup]="resetForm">
      <div class="spacing-30">
        <label for="email" class="form-label f-16" style="color: white;">{{ "login.emailLabel" | translate }}</label>
        <div class="input-group">
          <input
            formControlName="email"
            required
            [placeholder]="'login.emailLabel' | translate"
            type="text"
            id="email"
            class="form-control"
          >
        </div>
        <span *ngIf="resetForm.get('email')?.invalid && resetForm.get('email')?.touched"
              class="text-danger">{{ "login.invalidEmail" | translate }}</span>
      </div>

      <div class="form-group mt-3 ">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-block w-100" style="background-color: #5DA9D7; border-color: #5DA9D7; height: 48px" (click)="onSubmit()">
            {{ "forgotPassword.sendResetBtn" | translate }}
          </button>
        </div>
        <div class="d-flex justify-content-end">
        </div>
      </div>
    </form>
  </div> <!-- end card-body -->
</div>
