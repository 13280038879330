import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AssistantGroupResponse, AssistantModelResponse, DefaultFileGroupResponse, fileObjectResponse, FileResponse,
  QuestionAnswerTypeResponse
} from "../../../../@shared/models/response.module";
import {question} from "../../../question-templates/components/create-template/create-template.component";
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {_COR_AII_1, _COR_AII_10, _COR_AII_18, _COR_AII_24, _COR_AII_43} from "../../../../@shared/models/input.module";
import { firstValueFrom } from 'rxjs';
import {ToastService} from "../../../../@core/services/toast.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-create-assistant',
  templateUrl: './create-assistant.component.html',
  styleUrl: './create-assistant.component.css'
})
export class CreateAssistantComponent {
  createAssistantForm: FormGroup
  assistantTypes: AssistantModelResponse[] = [];
  selectedFiles: File[] = [];
  loading: boolean = false;
  defaultFileGroups: DefaultFileGroupResponse[] = [];
  showCommonFiles: boolean = false;
  selectedCommonFiles: any[] = [];
  fileSyncStatus: { [key: number]: boolean } = {};

  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService,
              private router: Router,
              private http: HttpClient) {
    this.createAssistantForm = new FormGroup({
      assistant_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      assistant_type_id: new FormControl('', [Validators.maxLength(200)]),
      reference: new FormControl('', [Validators.maxLength(200)]),
      assistant_group_description: new FormControl('', [Validators.maxLength(200)]),
      private: new FormControl(false),
    });
  }

  ngOnInit() {
    this.getAssistantTypes()
    this.getDefaultFileGroups()
  }

  getDefaultFileGroups() {
    let params: _COR_AII_43 = {}
    this.aiService.getDefaultFileGroups(params).subscribe((data: DefaultFileGroupResponse[]) => {
      this.defaultFileGroups = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      for (let group of this.defaultFileGroups) {
        for (let file of group.files){
          this.fileSyncStatus[file.file_id] = false;
        }
      }
    });
  }

  createAssistant() {
    this.loading = true;
    let payload: _COR_AII_18 = {
      assistant_group_name: this.createAssistantForm.get('assistant_name')!.value,
      reference: this.createAssistantForm.get('reference')!.value,
      assistant_group_description: this.createAssistantForm.get('assistant_group_description')!.value,
      private: this.createAssistantForm.get('private')!.value,
    }

    this.aiService.createAssistantGroup(payload).subscribe((res) => {
      this.toastService.showSuccess('toast.createAssistantGroup.success', 'toast.success.title.success')
      if (this.selectedFiles) {
        this.uploadFilesSequentially(res.assistant_group_id);
        if (this.selectedCommonFiles.length > 0) {
          for (let file of this.selectedCommonFiles) {
            console.log(file);
            let params: _COR_AII_24 = {
              file_id: file.file_id,
              org: 0,
              default: 1
            }
            this.aiService.getFileObject(params).subscribe((data: any) => {
              console.log("data", data);
              this.http.get(data.data.file_url, {responseType: 'blob'}).subscribe((data_obj) => {
                console.log(data_obj);
                const downloadedFile = new File([data_obj], file.file_name, {
                  type: data.type,
                  lastModified: new Date().getTime(), // Set current timestamp as lastModified
                });
                let params: _COR_AII_10 = {
                  assistant_group_id: res.assistant_group_id,
                  file: downloadedFile,
                  default_file_id: file.file_id,
                  default_file_sync: this.fileSyncStatus[file.file_id]
                };
                this.aiService.uploadFile(params).subscribe();
              });
            });
          }
        }
      }
      else {
        this.aiService.assistantsUpdatedSubject.next(true);
        this.loading = false;
        this.modal.close();
        this.router.navigate(['/assistants/details/' + res.assistant_group_id]);
      }
    })
  }

  getAssistantTypes() {
    this.aiService.getAssistantModels().subscribe((data: AssistantModelResponse[]) => {
      this.assistantTypes = data;
    })
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files: FileList = input.files;
      for (let file of Array.from(files)) {
        this.selectedFiles!.push(file);
      }
    }
  }

  removeFile() {
    this.selectedFiles = [];
  }


  close() {
    this.modal.close();
  }


  async uploadFilesSequentially(assistantId: string) {
    this.loading = true;
    if (this.selectedFiles.length === 0) {
      this.loading = false;
      this.toastService.showSuccess('toast.success.title.success', 'toast.uploadFile.success');
      this.modal.close(true);
      await this.router.navigate(['/assistants/details/' + assistantId]);
      return;
    }
    let params: _COR_AII_10 = {
      assistant_group_id: assistantId,
      file: this.selectedFiles[0]
    };
    this.aiService.uploadFile(params).subscribe((res) => {
      let files = this.selectedFiles!.slice(1);
      setTimeout(() => {
        for (let file of this.selectedFiles.slice(1)!) {
          let params: _COR_AII_10 = {
            assistant_group_id: assistantId,
            file: file
          };
          this.aiService.uploadFile(params).subscribe();
        }
        this.loading = false;
        this.toastService.showSuccess('toast.success.title.success', 'toast.uploadFile.success');
        this.modal.close(true);
        this.router.navigate(['/assistants/details/' + assistantId]);
      }, 2000)
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleCommonFiles(){
    this.showCommonFiles = !this.showCommonFiles;
  }

  goToAssistantDetails(assistantGroup: AssistantGroupResponse) {
    this.router.navigate([`/assistants/details/${assistantGroup.assistant_group_id}`]);
  }

  isSelected(file: any): boolean {
    return this.selectedCommonFiles.some((f) => f.file_id === file.file_id);
  }

  onFileSelectionChange(file: any, event: any): void {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the file to the selected list
      this.selectedCommonFiles.push({
        file_id: file.file_id,
        file_name: file.file_name,
        sync_status: false,
      });

      // Initialize sync status for this file if not already initialized
      if (this.fileSyncStatus[file.file_id] === undefined) {
        this.fileSyncStatus[file.file_id] = true; // Default to true or any preferred initial value
      }
    } else {
      // Remove the file from the selected list
      this.selectedCommonFiles = this.selectedCommonFiles.filter(
        (f) => f.file_id !== file.file_id
      );
    }
  }

  onSyncStatusChange(file: any, event: Event): void {
    const toggle = event.target as HTMLInputElement;

    // Ensure the sync status is updated correctly
    const selectedFile = this.selectedCommonFiles.find(
      (f) => f.file_id === file.file_id
    );
    if (selectedFile) {
      selectedFile.sync_status = toggle.checked;
    }
    this.fileSyncStatus[file.file_id] = toggle.checked;
  }
}
