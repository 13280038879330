<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.details.viewFile.title" | translate}} - {{file_name}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="view h-100 w-100">
      <app-view-pdf [file_id]="file_id"></app-view-pdf>
    </div>
  </div>
</div>
