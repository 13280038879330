import { Component } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AssistantModelResponse, UserOptionsResponse, UserResponse} from "../../@shared/models/response.module";
import {StorageService} from "../../@core/services/storage.service";
import {UserService} from "../../@core/services/user.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangeLanguageComponent} from "./components/change-language/change-language.component";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {
  ChangePreferredAssistantComponent
} from "./components/change-preferred-assistant/change-preferred-assistant.component";
import {EditProfileComponent} from "./components/edit-profile/edit-profile.component";
import {getInitials} from "../../@core/utils/utils.service";
import {USM_USR_16, USM_USR_22} from "../../@shared/models/input.module";
import {Toast} from "ngx-toastr";
import {ToastService} from "../../@core/services/toast.service";
import {AiService} from "../../@shared/services/ai.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {
  employeeForm: FormGroup;
  userData: UserResponse;
  initialsKeys: string[];
  initials: string;
  randomPassword = Math.random().toString(36).slice();
  selectedFile: File;
  selectedLanguage: string;
  userOptions: UserOptionsResponse
  assistantModels: AssistantModelResponse[] = [];
  selectedAssistantTypeId: string;



  constructor(private storageService: StorageService,
              private userService: UserService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private aiService: AiService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((data) => {
      this.userData = data.data;
      if (!this.userData.profile_image_url) {
        this.initialsKeys = [this.userData.first_name, this.userData.last_name];
        this.initials = getInitials(this.initialsKeys)
      }
    });

    this.userService.getUserOptions().subscribe((data) => {
      this.userOptions = data;
      this.selectedLanguage = this.userOptions.language;
    });

    this.getAssistantModels();
  }

  changePassword() {
    let modalRef = this.modalService.open(ChangePasswordComponent, {size: 'lg', centered: true, keyboard: false});
  }

  editProfile() {
    let modalRef = this.modalService.open(EditProfileComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.user = this.userData;
    modalRef.result.then((result) => {
      if (result) {
        this.userData = result;
      }
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    let params: USM_USR_16 = {
      image: this.selectedFile
    }
    this.userService.updateProfileImage(params).subscribe((data) => {
      this.userData = data;
      this.toastService.showSuccess('toast.success.profile.profileImage', 'toast.success.title.success')
    })
  }

  changeLanguage() {
    let params: USM_USR_22 = {
      language: this.selectedLanguage,
      preferred_assistant_type_id: this.userOptions.preferred_assistant_type_id
    }
    this.userService.updateUserOptions(params).subscribe(() => {

    });
  }

  getUserOptions() {
    this.userService.getUserOptions().subscribe((data) => {
      this.userOptions = data;
      this.selectedAssistantTypeId = this.assistantModels.find((assistantType) => assistantType.assistant_model_id == this.userOptions.preferred_assistant_type_id)!.assistant_model_id;
    })

  }
  getAssistantModels() {
    this.aiService.getAssistantModels().subscribe((data) => {
      this.assistantModels = data;
      this.getUserOptions();
    })
  }

  changePreferredAssistantType() {
    let params: USM_USR_22 = {
      language: this.userOptions.language,
      preferred_assistant_type_id: this.selectedAssistantTypeId
    }
    this.userService.updateUserOptions(params).subscribe(() => {
      this.toastService.showSuccess('toast.success.profile.preferredAssistant', 'toast.success.title.success');
    });
  }
}
