<div class="container-fluid">
  <div class="row d-flex align-items-stretch">
    <!-- Collapsible Sidebar -->
    <div id="sidebar" class="bg-dark p-0 custom-sidebar"
         [ngClass]="{'col-md-2 col-lg-2': !collapsed, 'd-none': collapsed}"
         style="background-color: #0D0F11; overflow-y: auto; max-height: 85%">
      <ul class="list-group list-group-flush">
        <!-- Sidebar Header -->
        <li class="list-group-item text-white d-flex justify-content-between align-items-center mb-0" style="height: 50px;">
          <h5 class="m-0">Question Sets</h5>
          <i class="fa fa-regular fa-x fa-bold cursor-pointer" (click)="collapsed = !collapsed"></i>
        </li>
        <hr class="m-0" style="color: white">

        <!-- Sidebar Content for Templates -->
        <li
          class="list-group-item text-white cursor-pointer"
          *ngFor="let template of templates"
          (click)="toggleSubmenu(template.template_id)"
        >
          <div class="d-flex justify-content-between align-items-center">
            <a href="javascript:void(0)" class="text-white text-decoration-none">
              {{ template.template_name }}
            </a>
            <i class="fas" [ngClass]="submenuState[template.template_id] ? 'fa-caret-up' : 'fa-caret-down'"></i>
          </div>

          <!-- Nested Submenu for Questions -->
          <div *ngIf="submenuState[template.template_id]" class="mt-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item text-white cursor-pointer submenu-item"
                  style="border: 1px solid #ED622E; border-radius: 5px; margin: 5px;"
                  *ngFor="let question of template.questions"
                  (click)="addNewTemplateMessageToThread(question); $event.stopPropagation();">
                {{ question.display_name }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="col" [ngClass]="{'custom-offset pt-2': !collapsed, '': collapsed}" style="overflow-y: auto;">
      <div class="container-fluid">
        <div class="row p-0">
          <div class="col-12">
            <div class="card-header d-flex justify-content-between align-items-center pt-2 pe-3 pb-2 ps-0" *ngIf="thread" [ngClass]="{'pb-3': !collapsed}">
              <div class="d-flex align-items-center">
                <button (click)="collapsed = !collapsed" class="btn btn-primary m-2 me-2" *ngIf="collapsed">
                  {{ 'threads.viewThread.expandSidebar' | translate }}
                </button>
                <h5 class="mb-0" style=" color: white">({{thread.assistant.reference}}) {{thread.assistant.assistant_name}} - {{thread.topic}}</h5>
              </div>
              <div class="d-flex align-items-center">
                <div class="ps-2 me-2" style="width: 300px">
                  <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedPdfFileToView" (change)="onChangeFileSelect($event)">
                    <option value="9999" selected>{{'threads.viewThread.selectPdf.placeholder' | translate}}</option>
                    <option *ngFor="let file of thread.assistant.files" value="{{file.file_id}}">{{file.file_name}}</option>
                  </select>
                </div>
                <div *ngIf="viewPdf">
                  <i class="fa fa-solid fa-x ms-3 me-2 fa-bold" style="color: white;" (click)="xOutViewPdf()"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center w-100">
          <div class="col-md-12 col-lg-12 col-xl-12" *ngIf="!viewPdf">
            <div class="test card" id="chat2" style="border-radius: 15px; flex-grow: 1; padding-bottom: 0;">
              <div class="card-body" *ngIf="thread" #scrollContainer>
                <div>
                  <div class="d-flex flex-row justify-content-start">
                    <img src="../../favicon.ico../../favicon.ico"
                         alt="avatar 1" style="width: 40px; height: 100%;">
                    <div style="max-width: 70%">
                      <p class="small p-2 ms-3 mb-1 rounded-4 " style="background-color: #36363B; color: white">Welcome to your new dialogue, You can start the conversation by asking a question in the input blow or select a question set from the dropdown menu above</p>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of thread.messages; let i = index">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="d-flex flex-row justify-content-start position-relative">
                      <img src="../../favicon.ico"
                           alt="avatar 1" style="width: 40px; height: 100%;">
                      <div style="max-width: 70%; position: relative" >
                        <div class="small p-2 ms-3 mb-1 rounded-4 " style="background-color: #36363B; color: white">
                          <markdown data="{{message.content}}"></markdown>
                          <!-- Display grouped citations -->
                          <div *ngFor="let citation of message.groupedCitations">
                            <a class="link cursor-pointer" (click)="openCitedFile(citation)">
                              [{{citation.indexes.join('] [')}}] {{citation.cited_file_name}}
                            </a>
                          </div>
                          <!-- Button in the bottom right corner -->
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="copyMessage(message.content)"
                                  style="position: absolute; top: 15px; right: -95px;">
                            <i class="fa-solid fa-copy fa-lg" style="color: white;" title="Copy"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="getMoreInformation(thread.messages[i - 1].content)"
                                  *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                                  style="position: absolute; top: 15px; right: -125px;">
                            <i class="fa-solid fa-memo-circle-info fa-lg" style="color: white;" title="Get more information"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="showAnswerAsBulletPointList(thread.messages[i - 1].content)"
                                  *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                                  style="position: absolute; top: 15px; right: -155px;">
                            <i class="fa-solid fa-list-ul fa-lg" style="color: white;" title="Answer as bullet point list"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="showAnswerAsTable(thread.messages[i - 1].content)"
                                  *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                                  style="position: absolute; top: 15px; right: -185px;">
                            <i class="fa-solid fa-table fa-lg" style="color: white;" title="Answer as a table"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div style="max-width: 70%">
                        <p class="small p-2 me-3 rounded-4"
                           style="background-color: #e3e3e3; color: black; white-space: break-spaces;">
                          <markdown data="{{message.content.trimEnd()}}"></markdown>
                        </p>
                      </div>
                      <img src="{{user.profile_image_url}}"
                           alt="avatar 1" style="width: 45px; height: 45px; border-radius: 50%;" *ngIf="user.profile_image_url">
                      <div *ngIf="!user.profile_image_url" class="profile-initials-circle me-2">
                        {{ initials }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pb-3 pt-2"
                   style="margin-top: 0;">
                <div class="row w-100" *ngIf="selectedFileAttachement">
                  <div class="col-12 d-flex align-items-center mb-2">
                    <i class="fa fa-regular fa-file pe-3" style="color: white"></i>
                    <p class="mb-0" style="color: white">{{selectedFileAttachement.name}}</p>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      [(ngModel)]="messageInput"
                      placeholder="{{ 'threads.viewThread.input.placeholder' | translate}}"
                      (input)="autoResize($event)"
                      (keydown)="handleKeyDown($event)"
                      style="box-shadow: 0px 1px 2px rgba(200, 200, 200, 0.5); resize: none;"
                      rows="1"
                    ></textarea>
                    <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
                      <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
                    </div>
                    <a class="ms-1 text-muted ms-3" (click)="fileInput.click()"><i class="fas fa-paperclip"  style="color: white"></i></a>
                    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    <a class="ms-3" (click)="addNewMessageToThread()"><i class="fas fa-paper-plane" style="color: #ED622E"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6" *ngIf="viewPdf">
            <div class="card" id="chat2">
              <div class="card-body" *ngIf="thread" #scrollContainer>
                <div>
                  <div class="d-flex flex-row justify-content-start">
                    <img src="../../favicon.ico../../favicon.ico"
                         alt="avatar 1" style="width: 45px; height: 100%;">
                    <div style="max-width: 70%">
                      <p class="small p-2 ms-3 mb-1 rounded-4 " style="background-color: #36363B; color: white">Welcome to your new dialogue, You can start the conversation by asking a question in the input blow or select a question set from the dropdown menu above</p>
                    </div>
                  </div>
                </div>

                <div class="" *ngFor="let message of thread.messages; let i = index">
                  <div *ngIf="message.role == 'assistant'">
                    <div class="d-flex flex-row justify-content-start position-relative">
                      <img src="../../favicon.ico"
                           alt="avatar 1" style="width: 40px; height: 100%;">
                      <div style="max-width: 70%; position: relative">
                        <div class="small p-2 ms-3 mb-1 rounded-4 " style="background-color: #36363B; color: white">
                          <markdown data="{{message.content}}"></markdown>
                          <div *ngFor="let citation of message.groupedCitations">
                            <a class="link cursor-pointer" (click)="openCitedFile(citation)">
                              [{{citation.indexes.join('] [')}}] {{citation.cited_file_name}}
                            </a>
                          </div>
                          <!-- Button in the bottom right corner -->
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="copyMessage(message.content)"
                               style="position: absolute; top: 15px; right: -95px;">
                            <i class="fa-solid fa-copy fa-lg" style="color: white;" title="Copy"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="getMoreInformation(thread.messages[i - 1].content)"
                               *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                               style="position: absolute; top: 15px; right: -125px;">
                            <i class="fa-solid fa-memo-circle-info fa-lg" style="color: white;" title="Get more information"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="showAnswerAsBulletPointList(thread.messages[i - 1].content)"
                               *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                               style="position: absolute; top: 15px; right: -155px;">
                            <i class="fa-solid fa-list-ul fa-lg" style="color: white;" title="Answer as bullet point list"></i>
                          </div>
                          <div class="copy-btn btn btn-link d-flex p-0" (click)="showAnswerAsTable(thread.messages[i - 1].content)"
                               *ngIf="thread.messages[i - 1].role == 'user' && thread.messages[i - 1].display_message == 1"
                               style="position: absolute; top: 15px; right: -185px;">
                            <i class="fa-solid fa-table fa-lg" style="color: white;" title="Answer as a table"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="message.role == 'user' && message.display_message == 1">
                    <div class="d-flex flex-row justify-content-end mb-4 pt-1">
                      <div style="max-width: 70%">
                        <p class="small p-2 me-3 rounded-4"
                           style="background-color: #e3e3e3; color: black; white-space: break-spaces;">
                          <markdown data="{{message.content.trimEnd()}}"></markdown>
                        </p>
                        <!-- Uncomment the following line if you want to display the timestamp -->
                        <!-- <p class="small me-3 mb-3 rounded-3 d-flex justify-content-end" style="color: white">{{ formatTimeDMYHHMM(message.created_at) }}</p>-->
                      </div>
                      <img src="{{user.profile_image_url}}"
                           alt="avatar 1" style="width: 45px; height: 45px; border-radius: 50%;" *ngIf="user.profile_image_url">
                      <div *ngIf="!user.profile_image_url" class="profile-initials-circle me-2">
                        {{ initials }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: white;" *ngIf="loading"></div>
                </div>
              </div>
              <div class="card-footer text-muted d-flex justify-content-start align-items-center flex-column pb-3 pt-2"
                   style="margin-top: 0;">
                <div class="row w-100" *ngIf="selectedFileAttachement">
                  <div class="col-12 d-flex align-items-center mb-2">
                    <i class="fa fa-regular fa-file pe-3" style="color: white"></i>
                    <p class="mb-0" style="color: white">{{selectedFileAttachement.name}}</p>
                  </div>
                </div>
                <div class="d-flex w-100 justify-content-start align-items-center" style="bottom: 0;">
                  <div class="d-flex align-items-center" style="position: relative; flex-grow: 1">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      [(ngModel)]="messageInput"
                      placeholder="{{ 'threads.viewThread.input.placeholder' | translate}}"
                      (input)="autoResize($event)"
                      (keydown)="handleKeyDown($event)"
                      style="box-shadow: 0px 1px 2px rgba(200, 200, 200, 0.5); resize: none;"
                      rows="1"
                    ></textarea>
                    <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
                      <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
                    </div>
                    <a class="ms-1 text-muted ms-3" (click)="fileInput.click()"><i class="fas fa-paperclip"  style="color: white"></i></a>
                    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    <a class="ms-3" (click)="addNewMessageToThread()"><i class="fas fa-paper-plane" style="color: #ED622E"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6" *ngIf="viewPdf && thread">
            <div class="h-100">
              <app-view-pdf [thread]="thread" [file_id]="selectedPdfFileToView" *ngIf="selectedPdfFileToView != 9999"></app-view-pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


