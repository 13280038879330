import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {
  CitationResponse,
  FileResponse, MessageResponse,
  QuestionResponse,
  TemplateResponse,
  ThreadResponse,
  UserResponse,
  GroupedCitation
} from "../../../../@shared/models/response.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {ActivatedRoute} from "@angular/router";
import {
  _COR_AII_10,
  _COR_AII_12,
  _COR_AII_13,
  _COR_AII_14, _COR_AII_38,
  _COR_AII_5,
  _COR_TMP_6, COR_AII_29, COR_AII_35, COR_AII_37, COR_AII_38
} from "../../../../@shared/models/input.module";
import {formatTimeDMYHHMM, getInitials} from "../../../../@core/utils/utils.service";
import {UserService} from "../../../../@core/services/user.service";
import {StorageService} from "../../../../@core/services/storage.service";
import {TemplateService} from "../../../../@shared/services/template.service";
import {debounceTime, firstValueFrom, forkJoin, fromEvent} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CompareComponent} from "./components/compare/compare.component";
import {PaginationResponse} from "../../../../@shared/models/global/response-wrapper.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../../../@core/services/toast.service";

@Component({
  selector: 'app-view-thread',
  templateUrl: './view-thread.component.html',
  styleUrl: './view-thread.component.css'
})

export class ViewThreadComponent implements AfterViewInit{
  threadId: string = ""
  thread: ThreadResponse;
  messageInput: string = "";
  loading: boolean = false;
  selectedFileAttachement: File | null = null;
  user: UserResponse;
  templates: TemplateResponse[] = [];
  selectedTemplateId: number = 9999;
  viewPdf: boolean = false;
  selectedPdfFileToView: number = 9999;
  improvedQuestion: boolean = false;
  initialsKeys: string[];
  initials: string;
  isImprovingQuestion: boolean = false;
  collapsed = true;
  submenuState: { [key: number]: boolean } = {};
  init: boolean = true


  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  @ViewChild('inputField', { static: true }) inputField!: ElementRef;


  constructor(private aiService: AiService,
              private route: ActivatedRoute,
              private userService: UserService,
              private storageService: StorageService,
              private templateService: TemplateService,
              private modalService: NgbModal,
              public translate: TranslateService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.threadId = String(this.route.snapshot.paramMap.get('id'));
    this.getThread();
    this.getTemplates();
    this.user = this.storageService.getUser();
    if (!this.user.profile_image_url) {
      this.initialsKeys = [this.user.first_name, this.user.last_name];
      this.initials = getInitials(this.initialsKeys)
    }

    setTimeout(() => {
      this.init = false
    }, 2000)
  }

  autoScrollEnabled = true;
  scrollAtBottom = false;

  ngAfterViewInit() {
    // this.scrollToBottom()
  }
  ngAfterViewChecked() {
    if(this.init) {
      setTimeout(() => {
        this.scrollToBottom()
      }, 500)
    }
  }

  toggleSubmenu(templateId: number): void {
    this.submenuState[templateId] = !this.submenuState[templateId];
  }

  private scrollToBottom(): void {
    try {
      if (this.scrollContainer){
        const scrollContainer = this.scrollContainer.nativeElement;
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    } catch (err) {
      console.error(err);
    }
  }

  getThread() {
    this.loading = true;
    let params: _COR_AII_5 = {
      thread_id: this.threadId,
      qna: 0
    }
    this.aiService.getThreads(params).subscribe((data: PaginationResponse<ThreadResponse[]>) => {
      this.thread = data.data[0];
      this.thread.messages.forEach((message) => {
        if (message && message.citations) {
          // Group citations for each message
          message.groupedCitations = this.groupCitationsByFileId(message.citations);
        }
      });
      this.loading = false;
    })
  }

  getTemplates() {
    let params: _COR_TMP_6 = {}
    this.templateService.getAllTemplates(params).subscribe((data) => {
      this.templates = data.data;
      for(let template of this.templates) {
        template.questions = template.questions.sort((a, b) => a.index - b.index);
      }
    })
  }

  // async runThread(): Promise<void> {
  //   this.loading = true;
  //   this.init = false
  //
  //   try {
  //     let params: _COR_AII_12 = {
  //       thread_id: this.threadId
  //     };
  //
  //     // Wait for runThread to complete
  //     await firstValueFrom(this.aiService.runThread(params)); // Use toPromise() if on RxJS 6.x
  //
  //     let messageParams: _COR_AII_13 = {
  //       thread_id: this.threadId
  //     };
  //
  //     // Wait for getMessages to complete
  //     this.thread.messages = await firstValueFrom(this.aiService.getMessages(messageParams)); // Use toPromise() if on RxJS 6.x
  //     this.thread.messages.forEach((message) => {
  //       if (message && message.citations) {
  //         // Group citations for each message
  //         message.groupedCitations = this.groupCitationsByFileId(message.citations);
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Error in runThread:', error);
  //   } finally {
  //     this.loading = false;
  //   }
  // }


  runThread() {
    // this.loading = true;
    let params: _COR_AII_38 = {
      thread_id: this.threadId
    }

    this.aiService.runAssistantThreadWithStreaming(params).subscribe({
      next: (chunk: string) => {
        if (!this.thread.messages.find((m) => m.message_id === "test")) {
          this.thread.messages.push({
            message_id: "test",
            content: "",
            role: "assistant",
            display_message: 1
          })
        }
        this.thread.messages.find((m) => m.message_id === "test")!.content += chunk;
        console.log('Received chunk', chunk);
        this.scrollToBottom();
        // this.responseText += chunk; // Append each chunk to responseText
      },
      error: (error) => {
        console.error('Error receiving streamed response', error);
      },
      complete: () => {
        console.log('Streaming complete');
        this.getThread();
        this.loading = false;
      }
    })
    // this.aiService.runPersonalAssistantThread(params).subscribe((res) => {
    //   this.selectedThread.messages.push(res);
    //   this.loading = false;
    // })
  }

  addNewMessageToThread() {
    this.improvedQuestion = false;
    if (this.selectedFileAttachement) {
      let file_params: _COR_AII_10 = {
        file: this.selectedFileAttachement
      }
      this.aiService.uploadFile(file_params).subscribe((res) => {
        let message_params: _COR_AII_14 = {
          thread_id: this.threadId,
          input: this.messageInput,
          file_id: res.ai_file_id,
          display_message: 1
        }
        this.aiService.addMessageToThread(message_params).subscribe((res) => {
          this.runThread();
          this.thread.messages.push(res);
          this.messageInput = "";
          this.selectedFileAttachement = null;
        })
      })
    }
    else {
      let params: _COR_AII_14 = {
        thread_id: this.threadId,
        input: this.messageInput,
        display_message: 1
      }
      this.aiService.addMessageToThread(params).subscribe((res) => {
        this.runThread();
        this.thread.messages.push(res);
        this.messageInput = "";
        setTimeout(() => {
          this.scrollToBottom()
        }, 500)
        // this.scrollToBottom()
      })
    }
  }


  addNewTemplateMessageToThread(question: QuestionResponse) {
    let params: _COR_AII_14 = {
      thread_id: this.threadId,
      input: question.question,
      display_message: 1
    }
    this.aiService.addMessageToThread(params).subscribe((res) => {
      this.scrollToBottom()
      this.runThread();
      this.thread.messages.push(res);
    })
  }


  addTemplateQuestionsToThread() {
    if (this.selectedTemplateId !== 9999) {
      this.loading = true;
      this.processQuestions().then(() => {
        this.loading = false;
      });
    }
  }

  async processQuestions() {
    let template = this.templates.find((template) => template.template_id == this.selectedTemplateId);
    if (template && template.questions.length > 0) {
      for (let question of template.questions){
        let params: _COR_AII_14 = {
          thread_id: this.threadId,
          input: question.question,
          display_message: 1
        };

        try {
          // Wait for the addMessageToThread observable to complete and return the response
          const res = await firstValueFrom(this.aiService.addMessageToThread(params));
          this.thread.messages.push(res);

          // Wait for runThread to finish before proceeding
          await this.runThread();
        } catch (error) {
          console.error('Error processing question:', error);
        }
      }
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.selectedFileAttachement = file;
    if (file) {
      // Your logic to handle the file upload
    }
  }

  compareDocuments() {
    let modalRef = this.modalService.open(CompareComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.thread = this.thread;
    modalRef.result.then((result) => {
      if (result) {
        this.thread.messages.push(result)
        this.runThread();
      }
    })
  }

  onChangeFileSelect(file: Event) {
    if (this.selectedPdfFileToView == 9999) {
      this.viewPdf = false;
    }
    else {
      this.viewPdf = true;}
  }

  toggleViewPdf() {
    this.viewPdf = !this.viewPdf;
  }

  improveQuestion(input_question: string) {
    // if (!this.improvedQuestion) {
    //   this.improvedQuestion = true;
    //   this.isImprovingQuestion = true; // Start showing the spinner
    //   console.log('isImprovingQuestion', this.isImprovingQuestion);
    //
    //   let params: COR_AII_29 = {
    //     company_id: this.storageService.getSelectedCompanyId(),
    //     question: input_question
    //   };
    //
    //   this.aiService.improveQuestion(params).subscribe((res) => {
    //     if (this.improvedQuestion) {
    //       this.messageInput = res.data.improved_question;
    //       this.isImprovingQuestion = false; // Hide the spinner after response
    //     }
    //     this.isImprovingQuestion = false; // Hide the spinner after response
    //   }, () => {
    //     this.isImprovingQuestion = false; // Hide spinner on error
    //   });
    // } else {
      this.addNewMessageToThread();
    // }
  }

  xOutViewPdf(){
    this.selectedPdfFileToView = 9999
    this.viewPdf = false
  }

  openCitedFile(file: GroupedCitation) {
    this.selectedPdfFileToView = this.thread.assistant.files.find((f) => f.ai_file_id == file.cited_file_id)!.file_id
    this.viewPdf = true
  }

  groupCitationsByFileId(citations: CitationResponse[]): GroupedCitation[] {
    const groupedCitations: { [key: string]: GroupedCitation } = {};

    citations.forEach((citation: CitationResponse) => {
      if (!groupedCitations[citation.cited_file_id]) {
        groupedCitations[citation.cited_file_id] = {
          cited_file_id: citation.cited_file_id,
          cited_file_name: citation.cited_file_name,
          indexes: []  // Initialize an empty array for indexes
        };
      }
      // Push citation indexes for the same file
      groupedCitations[citation.cited_file_id].indexes.push(citation.index);
    });

    // Convert the object to an array
    return Object.values(groupedCitations);
  }

  copyMessage(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Message copied to clipboard!');
      this.toastService.showSuccess('toast.success.massageCopiedToClipboard', 'toast.success.title.success')
    }).catch(err => {
      console.error('Failed to copy message: ', err);
    });
  }

  getMoreInformation(question: string) {
    this.improvedQuestion = false;
    let params: _COR_AII_14 = {
      thread_id: this.threadId,
      input: "Can you answer this question:" + "'" + question + "'" + "again, but with all the details and depth you can possibly give me? NB! remember to answer with the same language as the original question.",
      display_message: 0
    }
    this.aiService.addMessageToThread(params).subscribe((res) => {
      this.runThread();
      this.thread.messages.push(res);
      this.messageInput = "";
      this.scrollToBottom()

    })
  }

  showAnswerAsBulletPointList(question: string) {
    this.improvedQuestion = false;
    let params: _COR_AII_14 = {
      thread_id: this.threadId,
      input: "Can you answer this question:" + "'" + question + "'" + "again, but give the answer as a bullet point list with headers for better overview? NB! remember to answer with the same language as the original question.",
      display_message: 0
    }
    this.aiService.addMessageToThread(params).subscribe((res) => {
      this.runThread();
      this.thread.messages.push(res);
      this.messageInput = "";
      this.scrollToBottom()
    })
  }

  showAnswerAsTable(question: string) {
    this.improvedQuestion = false;
    let params: _COR_AII_14 = {
      thread_id: this.threadId,
      input: "Can you answer this question:" + "'" + question + "'" + "again, but give in a table with logical columns and information? NB! remember to answer with the same language as the original question.",
      display_message: 0
    }
    this.aiService.addMessageToThread(params).subscribe((res) => {
      this.runThread();
      this.thread.messages.push(res);
      this.messageInput = "";
      this.scrollToBottom()
    })
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents creating a new line
      this.improveQuestion(this.messageInput); // Calls your function to handle Enter key
    }
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset the height to auto to shrink back when necessary
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on the content
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
