import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AssistantGroupResponse, AssistantModelResponse,
  FileResponse
} from "../../../../@shared/models/response.module";
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {_COR_AII_10, _COR_AII_2, _COR_AII_11, _COR_AII_19} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";

@Component({
  selector: 'app-edit-assistant',
  templateUrl: './edit-assistant.component.html',
  styleUrl: './edit-assistant.component.css'
})
export class EditAssistantComponent {
  @Input() assistantGroup: AssistantGroupResponse;
  editAssistantForm: FormGroup
  assistantModels: AssistantModelResponse[] = [];
  selectedFile: File | null = null;
  files: FileResponse[] = [];

  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService)
  {
    this.editAssistantForm = new FormGroup({
      assistant_name: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      reference: new FormControl('', {validators: [], updateOn: 'blur'}),
      assistant_group_description: new FormControl('', {validators: [], updateOn: 'blur'}),
      private: new FormControl(false),
    });
  }

  ngOnInit() {
    this.files = this.assistantGroup.assistants[0].files;
    this.getAssistantTypes()
    this.editAssistantForm.patchValue({
      assistant_name: this.assistantGroup.assistant_group_name,
      reference: this.assistantGroup.reference,
      assistant_group_description: this.assistantGroup.assistant_group_description,
      private: this.assistantGroup.private
    })
  }

  getAssistantTypes() {
    this.aiService.getAssistantModels().subscribe((data: AssistantModelResponse[]) => {
      this.assistantModels = data;
    })
  }

  updateAssistant(){
    let payload: _COR_AII_19 = {
      assistant_group_id: this.assistantGroup.assistant_group_id,
      assistant_group_name: this.editAssistantForm.get('assistant_name')!.value,
      reference: this.editAssistantForm.get('reference')!.value,
      assistant_group_description: this.editAssistantForm.get('assistant_group_description')!.value,
      private: this.editAssistantForm.get('private')!.value,
    }


    this.aiService.editAssistantGroup(payload).subscribe(() => {
      if (this.selectedFile) {
        this.onUpload();
      }
      else {
        this.aiService.assistantsUpdatedSubject.next(true);
      }
      this.modal.close(true);
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    if (this.selectedFile) {
      let params: _COR_AII_10 = {
        assistant_group_id: this.assistantGroup.assistant_group_id,
        file: this.selectedFile
      }
      this.aiService.uploadFile(params).subscribe((response) => {
        this.aiService.assistantsUpdatedSubject.next(true);
      },
      (error) => {
      });
    }
  }

  removeFile(file: FileResponse) {
    this.selectedFile = null;
    this.files = this.files.filter((f) => f.file_id !== file.file_id);
    let params: _COR_AII_11 = {
      file_id: file.file_id
    }
    this.aiService.deleteFile(params).subscribe(() => {
      this.toastService.showSuccess('toast.success.title.success', 'toast.deleteFile.success');
      this.aiService.assistantsUpdatedSubject.next(true);
    })
  }

  close() {
    this.modal.close();
  }
}
