<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"threads.createThread.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Here you can establish a new dialogue with your Personal Assistant. Your dialogues will apperar in the list next to the assistant dialogue, and the topic you choose for a dialogue will be shown in the dialogue list. All your dialogues here are private, visible only to you.</p>
      </div>
    </div>
    <form [formGroup]="createThreadForm">
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"threads.createThread.topicLabel" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class w-100"
              formControlName="topic"
              placeholder="{{'threads.createThread.topic' | translate}}"
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createThread()">
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>

