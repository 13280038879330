<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.chooseAssistant.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <div class="row" *ngIf="threads.length > 0">
            <div class="col-12">
              <table class="table table-hover">
                <thead>
                <tr>
            <!--          <th scope="col">#</th>-->
            <th scope="col" >{{"assistants.chooseAssistant.table.reference" | translate}}</th>
            <th scope="col" >{{"assistants.table.assistantName" | translate}}</th>
            <th scope="col" >{{"assistants.chooseAssistant.table.topic" | translate}}</th>
            <th scope="col" >{{"assistants.table.created" | translate}}</th>
<!--            <th scope="col" >{{"assistants.table.updated" | translate}}</th>-->
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let thread of threads" (click)="goToThread(thread)">
            <td>{{ thread.assistant.reference }}</td>
            <td>{{ thread.assistant.assistant_name }}</td>
            <td>{{ thread.topic }}</td>
            <td>{{ formatTimeDMYHHMM(thread.created_at) }}</td>
<!--            <td>{{ formatTimeDMYHHMM(thread.updated_at) }}</td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row pt-2"  *ngIf="threads.length == 0">
      <div class="d-flex justify-content-center">
        <div class="fa fa-regular fa-spinner-third fa-spin fa-5x"></div>
      </div>
    </div>
<!--    <div class="row" *ngFor="let assistant of assistantGroup.assistants; let i = index">-->
<!--      <div class="col-12 d-flex justify-content-center mb-3">-->
<!--        <div *ngIf="i % 2 === 0" class="d-flex justify-content-center gap-2 w-100">-->
<!--          <div class="col-6 d-flex justify-content-center">-->
<!--            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createAndGoToThread(assistant)">{{ assistant.assistant_type_name }}</div>-->
<!--          </div>-->
<!--          <div class="col-6 d-flex justify-content-center" *ngIf="assistantGroup.assistants[i + 1]">-->
<!--            <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createAndGoToThread(assistant)">{{ assistantGroup.assistants[i + 1].assistant_type_name }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
