<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"templates.createNew.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">A Question Set is a group of ready made questions that are available to you. Each question has a display name, which explains what the question is about, and the full question. You can also add a context field to let the assistant better understand the context for your question. The question set is available to all your colleagues, but if you make the question set private, it will only be accessible to you. </p>
      </div>
    </div>
    <form [formGroup]="createTemplateForm">
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"templates.createNew.templateName" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control input-class"
              formControlName="templateName"
              placeholder="{{'templates.createNew.templateName' | translate}}"
            >
          </div>
          <span *ngIf="createTemplateForm.get('templateName')?.invalid && createTemplateForm.get('templateName')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
      </div>
      <div class="row spacing-30">
        <div class="col-8">
          <label class="f-16 f-bold label-class spacing-15">{{"templates.createNew.templateDescription" | translate}}</label>
          <input
            type="text"
            class="form-control input-class"
            formControlName="templateDescription"
            placeholder="{{'templates.createNew.templateDescription' | translate}}"
          >
        </div>
      </div>

      <div class="row spacing-30">
        <div class="col-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" formControlName="private">
            <label class="form-check-label f-16 f-bold label-class" for="flexSwitchCheckDefault">{{ "templates.createNew.private.label" | translate }}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 35px">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createTemplate()">{{ "templates.createNew.createBtn" | translate }}</div>
  </div>
</div>
