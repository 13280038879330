<div class="box d-flex justify-content-center" style="padding-top: 150px">
  <div>
    <div class="row d-flex align-items-center justify-content-start spacing-45">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 76px">
      </div>
    </div>
    <div class="row spacing-15">
      <div class="col-12">
        <h1 class="mb-0" style="color: white">{{ "resetPassword.title" | translate }}</h1>
      </div>
    </div>
    <div class="row spacing-45">
      <div class="col-12">
        <p style="color: #707070">{{ "resetPassword.description" | translate }}</p>
      </div>
    </div>
    <form [formGroup]="resetForm">
      <div class="spacing-20">
        <label for="password" class="form-label f-16" style="color: white;">{{ "login.passwordLabel" | translate }}</label>
        <div class="input-group">
          <input
            formControlName="password"
            required
            [placeholder]="'resetPassword.passwordLabel' | translate"
            type="password"
            id="password"
            class="form-control"
          >
        </div>
        <p class="pt-2" style="color: #8d8d8d">{{"resetPassword.passwordRequirements" | translate}}</p>
      </div>
      <div class="spacing-30">
        <label for="password" class="form-label f-16" style="color: white;">{{ "login.passwordLabel" | translate }}</label>
        <div class="input-group">
          <input
            formControlName="confirmPassword"
            required
            [placeholder]="'resetPassword.confirmPasswordLabel' | translate"
            type="password"
            id="confirmPassword"
            class="form-control"
          >
        </div>
      </div>

      <div class="form-group spacing-30">
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-block w-100" style="background-color: #5DA9D7; border-color: #5DA9D7; height: 48px" (click)="onSubmit()">
            {{ "resetPassword.resetBtn" | translate }}
          </button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <i class="fa fa-arrow-left me-3" style="color: #8d8d8d"></i>
        <p class="m-0 f-14" style="color: #8d8d8d" routerLink="/auth/login">{{"resetPassword.backToLogin" | translate}}</p>
      </div>
    </div>
  </div>
</div>
