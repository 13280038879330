import { Component } from '@angular/core';
import {AuthService} from "../../@core/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  isLoggedIn: boolean = false;


  constructor(private authService: AuthService,
              private router: Router) {
    this.authService.isAuthenticated.subscribe((data) => {
      this.isLoggedIn = data
    })
  }

  navigateToLogin() {
    this.router.navigate(['/auth/login']);
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
