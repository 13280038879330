import {Injectable} from "@angular/core";
import {StorageService} from "../../@core/services/storage.service";
import {
  _COR_AII_0,
  _COR_AII_1,
  _COR_AII_10,
  _COR_AII_11,
  _COR_AII_12,
  _COR_AII_13,
  _COR_AII_14,
  _COR_AII_16,
  _COR_AII_17,
  _COR_AII_18,
  _COR_AII_19,
  _COR_AII_2,
  _COR_AII_20,
  _COR_AII_21,
  _COR_AII_22,
  _COR_AII_23,
  _COR_AII_24,
  _COR_AII_25,
  _COR_AII_26,
  _COR_AII_27,
  _COR_AII_28,
  _COR_AII_3, _COR_AII_38,
  _COR_AII_5,
  _COR_AII_6,
  _COR_AII_7,
  _COR_AII_8,
  _COR_AII_9,
  COR_AII_0,
  COR_AII_1,
  COR_AII_10,
  COR_AII_11,
  COR_AII_12,
  COR_AII_13,
  COR_AII_14,
  COR_AII_16,
  COR_AII_17,
  COR_AII_18,
  COR_AII_19,
  COR_AII_2,
  COR_AII_20,
  COR_AII_21,
  COR_AII_22,
  COR_AII_23,
  COR_AII_24,
  COR_AII_25,
  COR_AII_26,
  COR_AII_27, COR_AII_28, COR_AII_29,
  COR_AII_3, COR_AII_31, COR_AII_32, COR_AII_33, COR_AII_34, COR_AII_35, COR_AII_36, COR_AII_37, COR_AII_38,
  COR_AII_4,
  COR_AII_5,
  COR_AII_6,
  COR_AII_7,
  COR_AII_8,
  COR_AII_9
} from "../models/input.module";
import {EndpointService} from "../endpoints.service";
import {distinctUntilChanged, map} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AiService {

  public assistantsUpdatedSubject = new BehaviorSubject<Boolean>(false);
  public assistantsUpdated = this.assistantsUpdatedSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private storageService: StorageService,
              private endpointService: EndpointService) {
  }

  getAssistantModels() {
    let params: COR_AII_4 = {
      company_id: this.storageService.getSelectedCompanyId()
    }
    return this.endpointService.cor_aii_4(params).pipe(map((data => {
      return data.data;
    })))
  }

  getAssistant(params: _COR_AII_0) {
    let payload: COR_AII_0 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_0(payload).pipe(map((data => {
      return data.data;
    })))
  }

  createAssistant(params: _COR_AII_1) {
    let payload: COR_AII_1 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_1(payload).pipe(map((data => {
      return data.data;
    })))
  }

  editAssistant(params: _COR_AII_2) {
    let payload: COR_AII_2 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_2(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deleteAssistant(params: _COR_AII_3) {
    let payload: COR_AII_3 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_3(payload).pipe(map((data => {
      return data;
    })))
  }

  getThreads(params: _COR_AII_5) {
    let payload: COR_AII_5 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_5(payload).pipe(map((data => {
      return data;
    })))
  }

  createThread(params: _COR_AII_6) {
    let payload: COR_AII_6 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_6(payload).pipe(map((data => {
      return data.data;
    })))
  }

  editThread(params: _COR_AII_7) {
    let payload: COR_AII_7 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_7(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deleteThread(params: _COR_AII_8) {
    let payload: COR_AII_8 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_8(payload).pipe(map((data => {
      return data;
    }))
    )
  }

  getFiles(params: _COR_AII_9) {
    let payload: COR_AII_9 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_9(payload).pipe(map((data => {
      return data.data;
    })))
  }

  uploadFile(params: _COR_AII_10){
    let payload: COR_AII_10 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_10(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deleteFile(params: _COR_AII_11){
    let payload: COR_AII_11 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_11(payload).pipe(map((data => {
      return data;
    })))
  }

  runThread(params: _COR_AII_12) {
    let payload: COR_AII_12 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_12(payload).pipe(map((data => {
      return data.data;
    })))
  }

  getMessages(params: _COR_AII_13) {
    let payload: COR_AII_13 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_13(payload).pipe(map((data => {
      return data.data;
    })))
  }

  addMessageToThread(params: _COR_AII_14) {
    let payload: COR_AII_14 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_14(payload).pipe(map((data => {
      return data.data;
    })))
  }

  getQnaAssistants(params: _COR_AII_16) {
    let payload: COR_AII_16 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_16(payload).pipe(map((data => {
      return data.data;
    })))
  }

  getAssistantGroups(params: _COR_AII_17) {
    let payload: COR_AII_17 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_17(payload).pipe(map((data => {
      return data;
    })))
  }

  createAssistantGroup(params: _COR_AII_18) {
    let payload: COR_AII_18 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_18(payload).pipe(map((data => {
      return data.data;
    })))
  }

  editAssistantGroup(params: _COR_AII_19) {
    let payload: COR_AII_19 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_19(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deleteAssistantGroup(params: _COR_AII_20) {
    let payload: COR_AII_20 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_20(payload).pipe(map((data => {
      return data.data;
    })))
  }

  addAssistantGroupAccessRelation(params: _COR_AII_21) {
    let payload: COR_AII_21 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }

    return this.endpointService.cor_aii_21(payload).pipe(map((data => {
      return data.data;
    })))
  }

  editAssistantGroupAccessRelation(params: _COR_AII_22) {
    let payload: COR_AII_22 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }

    return this.endpointService.cor_aii_22(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deleteAssistantGroupAccessRelation(params: _COR_AII_23) {
    let payload: COR_AII_23 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }

    return this.endpointService.cor_aii_23(payload).pipe(map((data => {
      return data;
    })))
  }

  getFileObject(params: _COR_AII_24) {
    let payload: COR_AII_24 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_24(payload).pipe(map((data => {
      return data;
    })))
  }

  getAssistantModel(params: COR_AII_25) {
    return this.endpointService.cor_aii_25(params).pipe(map((data => {
      return data;
    })))
  }

  createAssistantModel(params: _COR_AII_26) {
    let payload: COR_AII_26 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_26(payload).pipe(map((data => {
      return data;
    })))
  }

  editAssistantModel(params: _COR_AII_27) {
    let payload: COR_AII_27 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_27(payload).pipe(map((data => {
      return data;
    })))
  }

  deleteAssistantModel(params: _COR_AII_28) {
    let payload: COR_AII_28 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...params
    }
    return this.endpointService.cor_aii_28(payload).pipe(map((data => {
      return data;
    })))
  }

  improveQuestion(params: COR_AII_29) {
    return this.endpointService.cor_aii_29(params).pipe(map((data => {
      return data;
    })))
  }

  getAssistantTypes() {
    return this.endpointService.cor_aii_30().pipe(map((data => {
      return data.data;
    }))
    )
  }

  getPersonalAssistantThreads(){
    let payload: COR_AII_31 = {}
    return this.endpointService.cor_aii_31(payload).pipe(map((data => {
      return data.data;
    })))
  }

  createPersonalAssistantThread(payload: COR_AII_32){
    return this.endpointService.cor_aii_32(payload).pipe(map((data => {
      return data.data;
    })))
  }

  editPersonalAssistantThread(payload: COR_AII_33){
    return this.endpointService.cor_aii_33(payload).pipe(map((data => {
      return data.data;
    })))
  }

  addMessageToPersonalAssistantThread(payload: COR_AII_34){
    return this.endpointService.cor_aii_34(payload).pipe(map((data => {
      return data.data;
    })))
  }

  runPersonalAssistantThread(payload: COR_AII_35){
    return this.endpointService.cor_aii_35(payload).pipe(map((data => {
      return data.data;
    })))
  }

  deletePersonalAssistantThread(payload: COR_AII_36){
    return this.endpointService.cor_aii_36(payload).pipe(map((data => {
      return data.data;
    })))
  }

  addAssistantMessageToPersonalAssistantThread(payload: COR_AII_37){
    return this.endpointService.cor_aii_37(payload).pipe(map((data => {
      return data.data;
    })))
  }

  runAssistantThreadWithStreaming(payload: _COR_AII_38){
    let params: COR_AII_38 = {
      company_id: this.storageService.getSelectedCompanyId(),
      ...payload
    }
    return this.endpointService.getAssistantStreamedResponse(params).pipe(map((data => {
      return data;
    })))
  }
}
