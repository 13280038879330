<div class="row pb-4" style="max-width: 1400px" *ngIf="company">
  <div class="col-6">
    <div class="card" style="background-color: #0D0F11">
      <div class="card-body" style="padding: 50px">
        <div class="row" style="height: 150px">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <h2 style="color: white">{{company.company_name}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row pb-3">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">{{ "companySettings.info.orgNr" | translate }}</label>
                <p style="color: white">{{company.organisation_number}}</p>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">{{ "companySettings.info.address" | translate }}</label>
                <p style="color: white">{{company.address}}, {{company.postal_code}} {{company.city}}</p>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">{{ "companySettings.info.phone" | translate }}</label>
                <p style="color: white">{{company.phone}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="f-18 f-bold f-secondary">{{ "companySettings.info.email" | translate }}</label>
                <p style="color: white">{{company.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card p-3" style="background-color: #0D0F11">
      <div class="card-body"  style=" min-height: 480px; overflow: hidden">
        <div class="row">
          <div class="col-12">
            <div class="d-flex">
              <h2 style="color: white">{{ "superAdmin.companies.models.title" | translate }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table rounded-table table-hover">
              <thead>
              <tr>
                <th scope="col">{{ "superAdmin.companies.models.table.roleName" | translate }}</th>
                <th scope="col">{{"superAdmin.companies.models.table.model" | translate}}</th>
<!--                <th scope="col">{{"assistants.table.created" | translate}}</th>-->
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let model of assistantModels">
                <td class="cursor-pointer" (click)="editRole(model)">{{ model.assistant_type_name }}</td>
                <td class="cursor-pointer" (click)="editRole(model)">{{ model.model }}</td>
                <td class="cursor-pointer"><i class="fa-regular fa-bucket text-danger" (click)="removeRole(model)"></i> </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <div class="btn btn-primary" (click)="createRole()"><i class="fa fa-regular fa-plus me-2"></i>{{ "superAdmin.companies.models.addRole" | translate }}</div>
      </div>
    </div>
  </div>
</div>
<div class="row" style="max-width: 1400px">
  <app-card
    [titleLocalisationStrong]="'superAdmin.companies.stats.title'"
    [footerWithButton]="false"
  >
    <div class="row d-flex justify-content-center">
      <div class="col d-flex justify-content-center">
        <div class="fa fa-regular fa-spinner fa-spin fa-5x" style="color: white" *ngIf="!messageStats"></div>
      </div>
    </div>

    <div class="row pb-3" *ngIf="messageStats">
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Messages Added</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{messageStats.total_num_questions}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white;">Total Last 7 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white">{{messageStats.total_num_questions_last_7_days}}</h4>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card" style="background-color: #1f2023">
          <div class="card-header">
            <h5 style="color: white">Total Last 30 Days</h5>
          </div>
          <div class="card-body d-flex justify-content-center">
            <h4 style="color: white;">{{messageStats.total_num_questions_last_30_days}}</h4>
          </div>
        </div>
      </div>
    </div>
  </app-card>
</div>

<div class="row pt-3" style="max-width: 1400px">
  <div class="col-12">
    <div class="card p-3" style="background-color: #0D0F11">
      <div class="card-header d-flex justify-content-between">
        <h2 style="color: white">{{ "companySettings.employees.title" | translate }}</h2>
        <div class="btn btn-primary d-flex align-items-center" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; text-align: center" (click)="inviteEmployees()"><i class="fa fa-regular fa-plus me-2"></i>{{ "companySettings.employees.inviteBtn" | translate }}</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <table class="table rounded-table table-hover">
              <thead>
              <tr>
                <th scope="col">{{"companySettings.employees.name" | translate}}</th>
                <th scope="col">{{"companySettings.employees.phone" | translate}}</th>
                <th scope="col">{{"companySettings.employees.email" | translate}}</th>
                <th scope="col">{{"companySettings.employees.role" | translate}}</th>
                <th scope="col">{{"companySettings.employees.updated" | translate}}</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let employee of employees" class="table-row-hover">
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.first_name }} {{ employee.last_name}}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.phone }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.email }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">{{ employee.role_name }}</td>
                <td class="cursor-pointer" (click)="editEmployee(employee)">
                  {{ formatTimeDMYHHMM(employee.updated_at) ? formatTimeDMYHHMM(employee.updated_at) : formatTimeDMYHHMM(employee.created_at)}}
                </td>
                <td>
                  <i class="fa-regular fa-bucket" (click)="removeEmployeeFromEntity(employee)"></i>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
