import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TemplateService} from "../../../../@shared/services/template.service";
import {QuestionAnswerTypeResponse} from "../../../../@shared/models/response.module";
import {_COR_TMP_1, COR_TMP_4} from "../../../../@shared/models/input.module";
import {ToastService} from "../../../../@core/services/toast.service";

export interface question {
  question: string;
  question_answer_type_id: number;
}

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrl: './create-template.component.css'
})

export class CreateTemplateComponent implements OnInit{
  createTemplateForm: FormGroup
  questionAnswerTypes: QuestionAnswerTypeResponse[] = [];
  questions: question[] = [];

  constructor(private modal: NgbActiveModal,
              private templateService: TemplateService,
              private toastService: ToastService) {
    this.createTemplateForm = new FormGroup({
      templateName: new FormControl('', {validators: [Validators.required], updateOn: 'blur'}),
      templateDescription: new FormControl('', [Validators.maxLength(200)]),
      private: new FormControl(false)
    });
  }

  ngOnInit() {
    this.templateService.getQuestionAnswerTypes().subscribe((questionAnswerTypes: QuestionAnswerTypeResponse[]) => {
      this.questionAnswerTypes = questionAnswerTypes;
    });
  }

  close() {
    this.modal.close();
  }

  createTemplate() {
    let payload: _COR_TMP_1 = {
      template_name: this.createTemplateForm.get('templateName')?.value,
      template_description: this.createTemplateForm.get('templateDescription')?.value,
      private: this.createTemplateForm.get('private')?.value
    }
    this.templateService.createTemplate(payload).subscribe((res) => {
      if (res) {
        this.templateService.templatesUpdatedSubject.next(true);
        this.toastService.showSuccess('toast.templateCreated.success', 'toast.success.title.success');
      }
      this.modal.close(res);
    })
  }


}
