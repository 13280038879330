import {Component, OnInit} from '@angular/core';
import {AiService} from "../../@shared/services/ai.service";
import {
  AssistantGroupResponse,
  AssistantModelResponse,
  AssistantResponse, UserAssistantGroupFavoriteResponse,
} from "../../@shared/models/response.module";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {
  _COR_AII_0,
  _COR_AII_17,
  _COR_AII_20,
  _COR_AII_3,
  _USM_USR_27,
  _USM_USR_28
} from "../../@shared/models/input.module";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateAssistantComponent} from "./components/create-assistant/create-assistant.component";
import {EditAssistantComponent} from "./components/edit-assistant/edit-assistant.component";
import {ChooseAssistantComponent} from "./components/choose-assistant/choose-assistant.component";
import {DeleteModalComponent} from "../../@shared/components/delete-modal/delete-modal.component";
import {Router} from "@angular/router";
import {PaginationResponse} from "../../@shared/models/global/response-wrapper.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {User} from "@sentry/angular";
import {UserService} from "../../@core/services/user.service";
import {th} from "date-fns/locale";

@Component({
  selector: 'app-assistants',
  templateUrl: './assistants.component.html',
  styleUrl: './assistants.component.css'
})
export class AssistantsComponent implements OnInit {
  assistants: AssistantResponse[]
  assistantGroups: AssistantGroupResponse[]
  loading: boolean = false;
  searchForm: FormGroup;
  page: number = 1;
  limit: number = 10;
  totalItems: number = 0; // This should be fetched from the backend if available
  totalPages: number = 0; // This should be fetched from the backend if available
  searchString: string = '';
  search: boolean = false;
  userFavorites: UserAssistantGroupFavoriteResponse[]
  userFavoritesIds: string[] = []
  constructor(private aiService: AiService,
              private modal: NgbModal,
              private router: Router,
              private userService: UserService) {
  }

  ngOnInit() {
    this.getAllAssistantGroups();
    this.getUserFavorites()
    this.aiService.assistantsUpdated.subscribe((res) => {
      if (res == true) {
        this.getAllAssistantGroups();
        this.aiService.assistantsUpdatedSubject.next(false);
      }
    })
    this.searchForm = new FormGroup({
      searchString: new FormControl('', [Validators.maxLength(200)])
    })
  }


  getAllAssistantGroups() {
    this.loading = true;
    let params: _COR_AII_17 = {
      paginate: 1,
      page: this.page,
      limit: this.limit,
      search_string: this.searchString,
      search_string_columns: ["reference", "assistant_group_name"],
    }
    this.aiService.getAssistantGroups(params).subscribe((data: PaginationResponse<AssistantGroupResponse[]>) => {
      this.assistantGroups = data.data;
      this.totalPages = data.total_pages;
      this.loading = false;
    })
  }

  getAllAssistants() {
    let params: _COR_AII_0 = {}
    this.aiService.getAssistant(params).subscribe((data: AssistantResponse[]) => {
      this.assistants = data;
    })
  }

  createAssistant() {
    let modalRef = this.modal.open(CreateAssistantComponent, {size: 'lg', centered: true, keyboard: false});
  }

  editAssistant(assistantGroup: AssistantGroupResponse) {
    let modalRef = this.modal.open(EditAssistantComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroup = assistantGroup;
  }

  chooseAssistant(assistantGroup: AssistantGroupResponse) {
    let modalRef = this.modal.open(ChooseAssistantComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.assistantGroup = assistantGroup;
  }

  deleteAssistant(assistant: AssistantResponse) {
    let payload: _COR_AII_3 = {
      assistant_id: assistant.assistant_id
    }

    this.aiService.deleteAssistant(payload).subscribe((res) => {
      if (res) {
        this.aiService.assistantsUpdatedSubject.next(true);
      }
    })
  }

  deleteAssistantGroup(assistantGroup: AssistantGroupResponse) {
    let modalRef = this.modal.open(DeleteModalComponent, {size: 'lg', centered: true, keyboard: false});
    modalRef.componentInstance.titleLocalisationString = "assistants.deleteAssistantGroup.title";
    modalRef.result.then((res) => {
      if (res == true) {
        let payload: _COR_AII_20 = {
          assistant_group_id: assistantGroup.assistant_group_id
        }
        this.aiService.deleteAssistantGroup(payload).subscribe((res: any) => {
            this.aiService.assistantsUpdatedSubject.next(true);
        })
      }
      else {
        return
      }
    })
  }

  getUserFavorites() {
    this.userService.getAssistantGroupFavorites().subscribe((res) => {
      this.userFavorites = res
      if (this.userFavorites.length > 0) {
        for (let favorite of this.userFavorites) {
          this.userFavoritesIds.push(favorite.assistant_group.assistant_group_id)
        }
      }
    })
  }

  goToAssistantDetails(assistantGroup: AssistantGroupResponse) {
    this.router.navigate([`/assistants/details/${assistantGroup.assistant_group_id}`]);
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.getAllAssistantGroups();
  }

  onSearch() {
    this.searchString = this.searchForm.get('searchString')!.value;
    this.getAllAssistantGroups();
  }

  toggelSearch() {
    this.search = !this.search;
    if (!this.search) {
      this.searchString = '';
      this.searchForm.patchValue({searchString: ''});
      this.getAllAssistantGroups();
    }
  }

  addToFavorites(assistantGroup: AssistantGroupResponse) {
    let payload: _USM_USR_27 = {
      assistant_group_id: assistantGroup.assistant_group_id
    }

    this.userService.addAssistantGroupToFavorites(payload).subscribe((res) => {
      // Check if the item already exists to avoid duplicates
      if (!this.userFavorites.some(fav => fav.assistant_group.assistant_group_id === res.assistant_group.assistant_group_id)) {
        this.userFavorites.push(res); // Add to userFavorites
      }

      if (!this.userFavoritesIds.includes(res.assistant_group.assistant_group_id)) {
        this.userFavoritesIds.push(res.assistant_group.assistant_group_id); // Add to userFavoritesIds
      }

      // Emit the updated favorites list
      this.userService.userFavoritesSubject.next(this.userFavorites);
    });
  }

  removeFromFavorites(assistantGroup: AssistantGroupResponse) {
    let payload: _USM_USR_28 = {
      assistant_group_id: assistantGroup.assistant_group_id
    }

    this.userService.removeAssistantGroupFromFavorites(payload).subscribe((res) => {
      this.userFavorites = this.userFavorites.filter((favorite) => favorite.assistant_group.assistant_group_id !== assistantGroup.assistant_group_id)
      this.userFavoritesIds = this.userFavoritesIds.filter((id) => id !== assistantGroup.assistant_group_id)
      this.userService.userFavoritesSubject.next(this.userFavorites)
    })
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
  protected readonly Math = Math;
}
