import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyService} from "../../../../@shared/services/company.service";
import {WordBankResponse} from "../../../../@shared/models/response.module";
import {_COR_COY_5, _COR_COY_6, _COR_COY_7, _COR_COY_8} from "../../../../@shared/models/input.module";

export interface tempWord {
  description: string;
  word: string;
  explanation: string;
}


@Component({
  selector: 'app-word-bank',
  templateUrl: './word-bank.component.html',
  styleUrl: './word-bank.component.css'
})

export class WordBankComponent {
  wordBank: (WordBankResponse | tempWord)[];
  isSecondRowVisible: boolean = false;
  expandedIndex: number | null = null; // Track the expanded word index
  constructor(private modal: NgbActiveModal,
              private companyService: CompanyService) { }

  ngOnInit() {
    this.getWordBank();
  }

  getWordBank() {
    let payload: _COR_COY_5 = {}
    this.companyService.getCompanyWordBank(payload).subscribe((data: WordBankResponse[]) => {
      this.wordBank = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });
  }

  addNewWord() {
    let temp: tempWord = {
      description: '',
      word: '',
      explanation: ''
    }
    this.wordBank.push(temp);
    this.expandedIndex = this.wordBank.length - 1;
    console.log(this.wordBank);
  }

  addWordToWordBank(params: tempWord) {
    let payload: _COR_COY_6 = {
      description: params.description,
      word: params.word,
      explanation: params.explanation
    }

    this.companyService.addWordToCompanyWordBank(payload).subscribe((data) => {
      this.wordBank = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });
  }

  editWordInWordBank(params: WordBankResponse) {
    let payload: _COR_COY_7 = {
      entry_id: params.entry_id,
      description: params.description,
      word: params.word,
      explanation: params.explanation
    }

    this.companyService.editWordInCompanyWordBank(payload).subscribe((data) => {
      this.wordBank = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    });
  }

  deleteWordFromWordBank(params: WordBankResponse | tempWord) {
    // Check if params has the entry_id property before proceeding
    if ('entry_id' in params) {
      let payload: _COR_COY_8 = {
        entry_id: params.entry_id
      };

      // Call the service to delete the word from the word bank
      this.companyService.deleteWordFromCompanyWordBank(payload).subscribe((data: WordBankResponse[]) => {
        // Assuming data returns the updated word bank array
        this.wordBank = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        this.expandedIndex = null;
      });
    } else {
      this.wordBank = this.wordBank.filter((word) => word !== params);
      return;
    }
  }

  onChange(){

  }

  toggleSecondRow(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  saveWordBank() {
    for (let word of this.wordBank) {
      if ('entry_id' in word) {
        this.editWordInWordBank(word);
      } else {
        this.addWordToWordBank(word);
      }
    }
    this.expandedIndex = null;
    this.close();
  }

  close() {
    this.modal.close();
  }
}
