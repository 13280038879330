import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.css'
})
export class CardComponent {
  @Input() titleLocalisationStrong: string = "";
  @Input() footerWithButton: boolean = false;
  @Input() buttonLocalisationString: string = "";
  @Output() buttonClick = new EventEmitter<void>();


  onButtonClick() {
    this.buttonClick.emit();
  }
}
