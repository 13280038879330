<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{titleLocalisationString | translate}}</h5>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body mt-3">
    <p>Are you sure you would like to delete this item? This action can to be taken back!</p>
  </div>
  <div class="modal-footer">
    <div class="btn btn-secondary" (click)="close()">{{ "global.cancel" | translate }}</div>
    <div class="btn btn-primary" style="background-color: #c70012; border-color: #c70012" (click)="accept()">{{ "global.delete" | translate }}</div>
  </div>
</div>
