<div class="test pb-2">
  <div class="row pb-1 cursor-pointer">
    <div class="col-6 d-flex align-items-center">
      <i class="fa-solid fa-bars me-2" *ngIf="!isSecondRowVisible"></i>
      <div class="w-100">
        <label class="f-16 f-bold me-2" *ngIf="isSecondRowVisible">{{'questions.displayName.label' | translate}}</label>
        <input
          class="form-control flex-grow-1"
          type="text"
          placeholder="{{'questions.displayName.placeholder' | translate}}"
          [(ngModel)]="question.display_name"
          (change)="onChange()"
          [title]="question.display_name"
        >
      </div>
    </div>
    <!-- Add an expand/collapse icon in the first row -->
    <div class="col-6 d-flex align-items-center justify-content-end" (click)="toggleSecondRow()" >
      <i
        class="fa"
        [ngClass]="{'fa-chevron-down': !isSecondRowVisible, 'fa-chevron-up': isSecondRowVisible}"
        style="cursor: pointer;">
      </i>
    </div>
  </div>

  <!-- Second row that is initially hidden -->
  <div class="row" *ngIf="isSecondRowVisible">
    <label class="f-16 f-bold">{{'questions.question.label' | translate}}</label>
    <div class="col-11">
      <textarea
        class="form-control"
        rows="5"
        placeholder="{{'questions.questionPlaceholder' | translate}}"
        [(ngModel)]="question.question"
        (change)="onChange()"
        [title]="question.question">
      </textarea>
    </div>
    <div class="" style="position: absolute; right: 90px; top: 50%; transform: translateY(-50%);" *ngIf="isImprovingQuestion">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-2x" style="color: black"></div>
    </div>
    <div class="col-1 d-flex align-items-center">
      <i class="fa fa-regular fa-wand-magic-sparkles me-2 cursor-pointer" (click)="improveQuestion(question)"></i>
      <i class="fa-regular fa-bucket cursor-pointer" (click)="deleteQuestion()"></i>
    </div>
  </div>
</div>
