<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"superAdmin.companies.models.createRole.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="createRoleForm">
      <div class="row">
        <div class="col-6">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.roleName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="assistant_role_name"
              placeholder="{{'superAdmin.companies.models.createRole.roleName.placeholder' | translate}}"
            >
          </div>
          <span *ngIf="createRoleForm.get('assistant_name')?.invalid && createRoleForm.get('assistant_name')?.touched"
                class="text-danger">{{ "global.requiredField" | translate }}</span>
        </div>
        <div class="col-6">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.roleType.label" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="assistant_type_id"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'superAdmin.companies.models.createRole.roleType.placeholder' | translate}}</option>
            <option *ngFor="let type of assistantTypes" value="{{type.assistant_type_id}}">{{ type.assistant_type_name }}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-6">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.model.label" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="model"
                  (change)="onGroupChange($event)">
            <option value="" selected>{{'superAdmin.companies.models.createRole.model.placeholder' | translate}}</option>
            <option value="gpt-4o-mini">GPT-4o Mini</option>
            <option value="gpt-4o">GPT-4o</option>
          </select>
        </div>
        <div class="col-6">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.temperature.label" | translate}}</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              formControlName="temperature"
              placeholder="{{'superAdmin.companies.models.createRole.temperature.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-6">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.top_p.label" | translate}}</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              formControlName="top_p"
              placeholder="{{'superAdmin.companies.models.createRole.top_p.placeholder' | translate}}"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12">
          <label class="f-16 f-bold">{{"superAdmin.companies.models.createRole.instructions.label" | translate}}</label>
          <div class="input-group">
            <textarea
              class="form-control"
              formControlName="instructions"
              rows="20"
              placeholder="{{'assistants.createAssistant.description.placeholder' | translate}}"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white" (click)="createRole()">
      <div *ngIf="loading" class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      {{ "assistants.createAssistant.createBtn" | translate }}
    </div>
  </div>
</div>

