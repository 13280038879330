<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{"companySettings.inviteEmployees.title" | translate}}</h5>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="inviteForm">
      <div class="row">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.firstName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="first_name"
            >
          </div>
<!--          <span *ngIf="inviteForm.get('first_name')?.invalid && inviteForm.get('first_name')?.touched"-->
<!--                class="text-danger">{{ "global.requiredField" | translate }}</span>-->
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.lastName.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="last_name"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.email.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="email"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.phone.label" | translate}}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="phone"
            >
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.role.label" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="role_id">
            <option value="" selected>{{'companySettings.inviteEmployees.role.placeholder' | translate}}</option>
            <option value="1">{{'companySettings.inviteEmployees.role.admin' | translate}}</option>
            <option value="2">{{'companySettings.inviteEmployees.role.user' | translate}}</option>
          </select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-8">
          <label style="font-weight: bold">{{"companySettings.inviteEmployees.access.label" | translate}}</label>
          <select class="form-select" aria-label="Default select example" formControlName="component_id">
            <option value="" selected>{{'companySettings.inviteEmployees.access.placeholder' | translate}}</option>
            <option value="1">{{'companySettings.inviteEmployees.access.personal' | translate}}</option>
            <option value="2">{{'companySettings.inviteEmployees.access.master' | translate}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="inviteEmployee()">
      {{ "companySettings.inviteEmployees.inviteBtn" | translate }}
    </div>
  </div>
</div>
