<div class="box d-flex align-items-center justify-content-center">
  <div>
    <div class="row d-flex align-items-center justify-content-start">
      <div class="col-12 d-flex align-items-center">
        <img src="assets/brand/MASTER-EMPLOYEE_LOGO-04.png" style="height: 80px">
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
<!--        <h1 class="mb-0" style="color: #707070">{{ "login.header" | translate }}</h1>-->
      </div>
    </div>
    <div class="row pt-1">
      <div class="col-12">
        <p style="color: #707070">{{ "login.description" | translate }}</p>
      </div>
    </div>
    <form [formGroup]="loginForm">
      <div class="input-group">
        <input
          formControlName="email"
          required
          [placeholder]="'login.emailLabel' | translate"
          type="text"
          id="email"
          class="form-control"
        >
      </div>
      <span *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched"
            class="text-danger">{{ "login.invalidEmail" | translate }}</span>

      <div class="form-group mt-3 position-relative">
        <input id="password" type="password" class="form-control" name="password" formControlName="password" required
               minlength="2">
        <i *ngIf="!passwordVisible" class="fa fa-eye password-icon"
           (click)="togglePasswordVisibility('password')"></i>
        <i *ngIf="passwordVisible" class="fa fa-eye-slash password-icon"
           (click)="togglePasswordVisibility('password')"></i>
      </div>

      <span *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
            class="text-danger">{{ "login.invalidPassword" | translate }}</span>

      <div class="form-group mt-2">
        <div *ngIf="isLoginFailed" class="alert alert-danger" role="alert">
          {{ "login.loginFailedMessage" | translate }} {{ "login.loginFailedReason" | translate }}
        </div>
      </div>

      <div class="form-group mt-3 ">
        <div class="d-flex justify-content-between">
          <button class="btn btn-primary btn-block" style="background-color: #5DA9D7; border-color: #5DA9D7" (click)="onSubmit()">
            {{ "login.loginButtonLabel" | translate }}
          </button>
          <a class="text-muted float-end" href="/auth/forgot-password" style="color: #707070; padding-top: 15px">
            <small style="color: #707070" href="/auth/forgot-password">{{ "login.forgotPasswordLabel" | translate }}</small>
          </a>
        </div>
        <div class="d-flex justify-content-end">
        </div>
      </div>
    </form>
  </div> <!-- end card-body -->
</div>
