<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"assistants.details.viewFile.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0 mb-3" style="margin-left: 35px; margin-right: 35px">
    <div class="view h-100 w-100">
      <app-view-pdf [file_id]="file_id"></app-view-pdf>
    </div>
  </div>
</div>
