<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"profile.editProfile.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body mt-3">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <img src="{{user.profile_image_url}}" alt="Profile Image" class="profile-image me-2">
      </div>
    </div>
    <div class="row d-flex justify-content-center pt-2">
      <div class="col-4 d-flex justify-content-center" *ngIf="!editImage">
        <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">
          <label for="file-upload">{{"profile.editProfile.updateProfileImageBtn" | translate }}</label>
          <input id="file-upload" type="file" (change)="onFileSelected($event)"/>
        </div>
      </div>
    </div>
    <form [formGroup]="editUser" (ngSubmit)="submit()">
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="firstName">{{"profile.editProfile.firstName.label" | translate}}</label>
            <input type="text" class="form-control" id="firstName" formControlName="firstName">
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="lastName">{{"profile.editProfile.lastName.label" | translate}}</label>
            <input type="text" class="form-control" id="lastName" formControlName="lastName">
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-12">
          <div class="form-group">
            <label class="f-16 f-bold" for="phone">{{"profile.editProfile.phone.label" | translate}}</label>
            <div class="input-group">
              <input type="tel"
                     class="form-control"
                     id="phone"
                     formControlName="phone"
                     placeholder="Enter your phone number">
            </div>
            <div *ngIf="editUser.get('phone')!.invalid && editUser.get('phone')!.touched" class="text-danger">
              Please enter exactly 8 digits.
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end">
        <div class="col-12">
          <button type="submit" class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">{{"profile.changePassword.submit" | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
