<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{titleLocalisationString | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <p style="color: #909699" >Are you sure you would like to delete this item? This action can to be taken back!</p>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-secondary" (click)="close()">{{ "global.cancel" | translate }}</div>
    <div class="btn btn-primary" (click)="accept()">{{ "global.delete" | translate }}</div>
  </div>
</div>
