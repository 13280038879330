<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h5 class="modal-title" id="exampleModalLabel">{{"threads.viewThread.compare.title" | translate}}</h5>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body mt-2">
    <div class="row" *ngIf="!loading">
      <div class="col d-flex justify-content-center" style="text-align: center">
        <p>{{ "threads.viewThread.compare.description" | translate }}</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center pt-2" *ngIf="!loading">
      <div class="col-4 d-flex justify-content-center">
        <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; width: 100%">
          <label for="file-upload">{{"threads.viewThread.compare.uploadDocumentBtn" | translate }}</label>
          <input id="file-upload" type="file" (change)="onFileSelected($event)"/>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="loading">
      <div class="fa fa-regular fa-spinner-third fa-spin fa-5x" style="color: black;"></div>
    </div>
  </div>
</div>
