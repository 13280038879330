<div class="row pb-4" style="max-width: 1400px" *ngIf="assistantGroup">
  <div class="col-4">
    <div class="card" style="background-color: #0D0F11">
      <div class="card-content">
        <div class="card-body" style="padding: 50px; height: 447px">
          <div class="row">
            <div class="col-12 d-flex">
              <h2 style="color: white">Assistant Details</h2>
              <i class="fa fa-star fa-2x ms-2 cursor-pointer"
                 style="color: #ED622E"
                 *ngIf="userFavoritesIds.includes(assistantGroup.assistant_group_id)"
                 (click)="removeFromFavorites(assistantGroup)"></i>
              <i class="fa fa-regular fa-star fa-2x ms-2 cursor-pointer"
                 style="color: white"
                 *ngIf="!userFavoritesIds.includes(assistantGroup.assistant_group_id)"
                 (click)="addToFavorites(assistantGroup)"></i>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <label class="f-18 f-bold f-secondary">Assistant name</label>
              <p style="color: white">{{assistantGroup.assistant_group_name}}</p>
            </div>
          </div>

          <div class="row pt-2 pb-2">
            <div class="col-12">
              <label class="f-18 f-bold f-secondary" style="width: 100%">Accessibility</label>
              <span class=" f-14" style="color: white" *ngIf="assistantGroup.private"><i class="fa fa-regular fa-lock me-2" *ngIf="assistantGroup.private"></i>{{ "assistants.table.label.private" | translate }}</span>
              <span class=" f-14" style="color: white" *ngIf="!assistantGroup.private"><i class="fa fa-regular fa-lock-open me-2" *ngIf="!assistantGroup.private"></i>{{ "assistants.table.label.public" | translate }}</span>
            </div>
          </div>

          <div class="row pt-3" *ngIf="assistantGroup.reference">
            <div class="col-12">
              <label class="f-18 f-bold f-secondary">Reference</label>
              <p style="color: white">{{assistantGroup.reference}}</p>
            </div>
          </div>

          <div class="row" *ngIf="assistantGroup.assistant_group_description">
            <div class="col-12">
              <label class="f-18 f-bold f-secondary">Description</label>
              <p style="color: white">{{assistantGroup.assistant_group_description}}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer mb-3" style="margin-right: 50px; min-height: 40px">
          <div class="btn btn-primary me-3" style="background-color: #5e656c; border-color: #5e656c" (click)="shareAssistantGroup()" *ngIf="canEdit && assistantGroup.private">{{ "assistants.details.share.assistant" | translate }}</div>
          <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="editAssistantGroup()" *ngIf="canEdit">{{ "assistants.details.edit.assistant" | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="card" style="background-color: #0D0F11">
      <div class="card-body" style="padding: 50px; height: 447px">
        <div class="row pb-3">
          <div class="col-12 d-flex">
            <h2 style="color: white">Dialogues</h2>
          </div>
        </div>
        <div class="row" *ngIf="!loadingThreads">
          <div class="col-12">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Assistant type</th>
                <th scope="col">{{"assistants.chooseAssistant.table.topic" | translate}}</th>
                <th scope="col">{{"assistants.table.created" | translate}}</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let thread of threads">
                <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.assistant.assistant_type_name }}</td>
                <td class="cursor-pointer" (click)="goToThread(thread)">{{ thread.topic }}</td>
                <td class="cursor-pointer" (click)="goToThread(thread)">{{ formatTimeDMYHHMM(thread.created_at) }}</td>
                <td class="cursor-pointer" (click)="deleteThread(thread)"><i class="fa-regular fa-bucket text-danger" ></i> </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row pt-2"  *ngIf="loadingThreads">
          <div class="d-flex justify-content-center">
            <div class="fa fa-regular fa-spinner-third fa-spin fa-5x"></div>
          </div>
        </div>
      </div>
      <div class="modal-footer mb-3" style="margin-right: 50px">
        <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="createThread()">{{ "assistants.details.createThreadBtn" | translate }}</div>
      </div>
    </div>
  </div>
</div>


<div class="row" style="max-width: 1400px" *ngIf="assistantGroup">
  <div class="col-12">
    <div class="card" style="background-color: #0D0F11">
      <div class="card-body" style="padding-top: 50px; padding-left: 50px; padding-right: 50px">
        <div class="row">
          <div class="col-12 d-flex">
            <h2 style="color: white">Documents</h2>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <div class="row pb-3">
              <div class="col-12">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">{{ "assistants.details.files.table.fileName" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.fileSize" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.ocr" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.vector_store" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.s3" | translate }}</th>
                    <th scope="col">{{ "assistants.details.files.table.created" | translate }}</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let file of assistantGroup.assistants[0].files">
                    <td class="cursor-pointer" [title]="file.file_name" style="max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ file.file_name }}</td>
                    <td class="cursor-pointer">
                      {{ file.file_size / (1024 * 1024) | number: '1.2-2' }} MB
                    </td>
                    <td class="cursor-pointer">
                      <i class="fa fa-regular fa-check ms-2" *ngIf="file.ocr"></i>
                      <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.ocr"></i>
                    </td>
                    <td class="cursor-pointer">
                      <i class="fa fa-regular fa-check ms-2" *ngIf="file.vector_store"></i>
                      <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.vector_store"></i>
                    </td>
                    <td class="cursor-pointer">
                      <i class="fa fa-regular fa-check ms-2" *ngIf="file.s3"></i>
                      <i class="fa fa-regular fa-spinner fa-spin ms-2" *ngIf="!file.s3"></i>
                    </td>
                    <td class="cursor-pointer">{{ formatTimeDMYHHMM(file.created_at) }}</td>
                    <td class="cursor-pointer" style="text-align: right;">
                      <i class="fa fa-regular fa-eye pe-3" (click)="viewFile(file)"></i>
                      <i class="fa fa-regular fa-download pe-3" (click)="downloadFileObject(file)"></i>
                      <i class="fa-regular fa-bucket text-danger" (click)="removeFile(file)"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mb-3" style="margin-right: 50px" *ngIf="canEdit">
        <div class="btn btn-primary" style="background-color: #5e656c; border-color: #5e656c" (click)="addDocuments()">{{ "assistants.details.addDocumentBtn" | translate }}</div>
      </div>
    </div>
  </div>
</div>
