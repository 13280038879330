import {Component, Input} from '@angular/core';
import {AiService} from "../../../../@shared/services/ai.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../../@core/services/toast.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FileResponse, TemplateMissingDataFieldResponse} from "../../../../@shared/models/response.module";
import {
  _COR_AII_10,
  _COR_AII_11,
  _COR_AII_51,
  _COR_AII_52, _COR_AII_53, _COR_AII_55,
  data_fields_post_input
} from "../../../../@shared/models/input.module";

@Component({
  selector: 'app-template-missing-data-fields',
  templateUrl: './template-missing-data-fields.component.html',
  styleUrl: './template-missing-data-fields.component.css'
})
export class TemplateMissingDataFieldsComponent {
  @Input() assistantGroupId: string;
  @Input() files: FileResponse[];
  templateMissingDataFields: TemplateMissingDataFieldResponse[];
  loading: boolean = false;
  showAddField: boolean = false;
  addFieldDisplayName: string = '';
  addFieldQuestion: string = '';
  addFieldValue: string = '';
  expandedIndex: number | null = null; // Stores the index of the expanded row


  constructor(private aiService: AiService,
              private modal: NgbActiveModal,
              private toastService: ToastService,
              private router: Router,
              private http: HttpClient) {
  }

  ngOnInit() {
    // Sort the data fields by created_at
    let params: _COR_AII_55 = {
      assistant_group_id: this.assistantGroupId
    }
    this.aiService.getMissingDataFields(params).subscribe((res) => {
      this.templateMissingDataFields = res.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    })
  }

  downloadSupplementaryData() {
    let params: _COR_AII_51 = {
      data_fields: this.templateMissingDataFields
    }
    this.aiService.editTemplateMissingDataFields(params).subscribe((res) => {
      let content = "Supplementary data\n\n";
      this.templateMissingDataFields.forEach(field => {
        content += `question:\n${field.question.question}\n`;
        content += `answer:\n${field.value || 'N/A'}\n\n`; // Default to 'N/A' if no answer is provided
      });

      const blob = new Blob([content], { type: 'text/plain' });

      if (this.files.some(file => file.supplementary_data)) {
        console.log("file exists");
        console.log("Deleting file")
        let payloadDelete: _COR_AII_11 = {
          file_id: this.files.find(file => file.supplementary_data)!.file_id
        }
        this.aiService.deleteFile(payloadDelete).subscribe((res) => {
          let payloadPost: _COR_AII_10 = {
            assistant_group_id: this.assistantGroupId,
            supplementary_data: 1,
            file: new File([blob], 'supplementary_data.txt')
          }
          this.aiService.uploadFile(payloadPost).subscribe((res) => {
            console.log(res);
            this.modal.close(true);
          });
        });
      }
      else {
        console.log("file does not exist");
        console.log("Uploading file")
        let payloadPost: _COR_AII_10 = {
          assistant_group_id: this.assistantGroupId,
          supplementary_data: 1,
          file: new File([blob], 'supplementary_data.txt')
        }
        this.aiService.uploadFile(payloadPost).subscribe((res) => {
          console.log(res);
          this.modal.close(true);
        });
      }
    })
  }

  addField() {
    // let field: data_fields_post_input = {
    //   question_display_name: this.addFieldDisplayName,
    //   question_question: this.addFieldQuestion,
    //   value: this.addFieldValue
    // }

    let params: _COR_AII_52 = {
      assistant_group_id: this.assistantGroupId,
      data_fields: [
        {
          question_display_name: this.addFieldQuestion,
          question_question: this.addFieldQuestion,
          value: this.addFieldValue
        }
      ]
    }
    this.aiService.addTemplateMissingDataFields(params).subscribe((res) => {
      this.templateMissingDataFields.push(res[0]);
      this.templateMissingDataFields.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      this.showAddField = false;
      this.addFieldDisplayName = '';
      this.addFieldQuestion = '';
      this.addFieldValue = '';
    })
  }

  toggleField(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index; // Collapse if clicked again
  }

  removeField(field: TemplateMissingDataFieldResponse) {
    let params: _COR_AII_53 = {
      template_missing_data_field_id: field.field_id
    }
    this.aiService.deleteTemplateMissingDataFields(params).subscribe((res) => {
      this.templateMissingDataFields = this.templateMissingDataFields.filter(f => f.field_id !== field.field_id);
    })
  }

  close() {
    this.modal.close(true);
  }
}
