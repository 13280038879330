import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../@shared/services/company.service";
import {ToastService} from "../../@core/services/toast.service";
import {
  AssistantGroupResponse,
  CompanyResponse,
  MessagesStatsResponse,
  TokenStatsResponse
} from "../../@shared/models/response.module";
import {COR_COY_4, COR_SER_1} from "../../@shared/models/input.module";
import {PaginationResponse} from "../../@shared/models/global/response-wrapper.service";
import {formatTimeDMYHHMM} from "../../@core/utils/utils.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CreateCompanyComponent} from "./components/create-company/create-company.component";

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrl: './super-admin.component.css'
})
export class SuperAdminComponent {
  companies: CompanyResponse[]
  searchForm: FormGroup;
  page: number = 1;
  limit: number = 10;
  totalItems: number = 0; // This should be fetched from the backend if available
  totalPages: number = 0; // This should be fetched from the backend if available
  searchString: string = '';
  search: boolean = false;
  loading: boolean = false;
  activeTab: string = 'dashboard';
  messageStats: MessagesStatsResponse;
  tokenStats: TokenStatsResponse

  constructor(private companyService: CompanyService,
              private toastService: ToastService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getAllCompanies();
    this.searchForm = new FormGroup({
      searchString: new FormControl('', [Validators.maxLength(200)])
    })
    this.getMessageStats();
    this.getTokenStats();
  }

  getMessageStats(){
    let payload: COR_SER_1 = {}
    this.companyService.getMessagesStats(payload).subscribe((data: MessagesStatsResponse) => {
      this.messageStats = data;
    })
  }

  getTokenStats(){
    this.companyService.getTokenStats().subscribe((data: TokenStatsResponse) => {
      console.log(data);
      this.tokenStats = data;
    })
  }

  getAllCompanies() {
    this.loading = true;
    let payload: COR_COY_4 = {
      paginate: 1,
      page: this.page,
      limit: this.limit,
      search_string: this.searchString,
      search_string_columns: ["company_id","company_name","organisation_number"],
      order_by: "company_id",
      order_direction: "asc"
    }

    this.companyService.getAllCompanies(payload).subscribe((data: PaginationResponse<CompanyResponse[]>) => {
      this.companies = data.data;
      this.totalPages = data.total_pages;
      this.totalItems = data.total_items;
      this.loading = false;
    })
  }

  goToCompanyAdministration(company: CompanyResponse) {
    this.router.navigate([`/super-admin/company-administration/${company.company_id}`]);
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.getAllCompanies();
  }

  onSearch() {
    this.searchString = this.searchForm.get('searchString')!.value;
    this.getAllCompanies();
  }

  toggelSearch() {
    this.search = !this.search;
    if (!this.search) {
      this.searchString = '';
      this.searchForm.patchValue({searchString: ''});
      this.getAllCompanies();
    }
  }

  createCompany() {
    let modalRef = this.modalService.open(CreateCompanyComponent, {size: 'lg', centered: true, keyboard: false, backdrop: 'static'});
    modalRef.result.then((result) => {
      if (result) {
        this.getAllCompanies();
      }
    });
  }

  protected readonly formatTimeDMYHHMM = formatTimeDMYHHMM;
}
