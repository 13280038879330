<div class="modal-content">
  <div class="modal-header p-0" style="border-bottom: none">
    <div class="d-flex justify-content-between w-100 spacing-30" style="margin-top: 35px; margin-left: 35px">
      <h4 class="modal-title" style="color: white" id="exampleModalLabel">{{"assistants.details.share.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x" style="color: #909699"></i></div>
    </div>
  </div>
  <div class="modal-body p-0" style="margin-left: 35px; margin-right: 35px">
    <div class="row spacing-30">
      <div class="col-10">
        <p style="color: #909699">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend urna erat, nec maximus arcu lobortis sed. Duis in lorem gravida, aliquam nisi a, molestie ex. Quisque congue quam sit amet vehicula dapibus. </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="box">
          <div class="row" *ngFor="let relation of assistantGroup.access_relations">
            <div class="col-4">
              <span class="label-class">{{getUserData(relation.user_id)?.full_name}}</span>
            </div>
            <div class="col-2">
              <span class="label-class">{{relation.access_level.access_level_name}}</span>
            </div>
            <div class="col-4">
              <i class="fa-regular fa-bucket" style="color: #dc3545" (click)="removeRelation(relation.relation_id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-3">
      <div class="col-8 d-flex justify-content-between">
        <div class="dropdown me-3">
          <div
            class="btn btn-primary dropdown-toggle label-class"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span *ngIf="selectedOption">{{ selectedOption.full_name }}</span>
            <span *ngIf="!selectedOption">{{'assistants.details.share.select' | translate }}</span>
          </div>
          <ul class="dropdown-menu input-class-full" aria-labelledby="dropdownMenuButton">
            <input
              type="text"
              class="form-control input-class"
              placeholder="Search..."
              [(ngModel)]="searchTerm"
            />
            <li *ngFor="let option of filteredOptions()" style="cursor: pointer">
              <a
                class="dropdown-item label-class"
                (click)="selectOption(option)"
              >{{ option.full_name }}</a
              >
            </li>
          </ul>
        </div>
        <select class="form-select input-class" *ngIf="selectedOption" aria-label="Default select example" [(ngModel)]="selectedAccessLevel">
          <option value="99" selected>{{'assistants.details.share.accessLevel.placeholder' | translate}}</option>
          <option value="0">{{"assistants.details.share.accessLevel.owner" | translate}}</option>
          <option value="1">{{"assistants.details.share.accessLevel.edit" | translate}}</option>
          <option value="2">{{"assistants.details.share.accessLevel.view" | translate}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer p-0" style="border-top: none; margin-bottom: 35px; margin-right: 16px">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" *ngIf="selectedOption && selectedAccessLevel != 99" (click)="addRelation()">
      {{ "assistants.details.share.save" | translate }}
    </div>
  </div>
</div>
