<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between w-100">
      <h4 class="modal-title" id="exampleModalLabel">{{"assistants.details.share.title" | translate}}</h4>
      <div class="btn" (click)="close()"><i class="fa fa-x"></i></div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="box">
          <div class="row" *ngFor="let relation of assistantGroup.access_relations">
            <div class="col-4">
              <span>{{getUserData(relation.user_id)?.full_name}}</span>
            </div>
            <div class="col-2">
              <span>{{relation.access_level.access_level_name}}</span>
            </div>
            <div class="col-4">
              <i class="fa-regular fa-bucket" style="color: #dc3545" (click)="removeRelation(relation.relation_id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 pb-3">
      <div class="col-8 d-flex justify-content-between">
        <div class="dropdown me-3">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="background-color: transparent!important; border-color: #d2d2d2!important; color: black!important"
          >
            <span *ngIf="selectedOption">{{ selectedOption.full_name }}</span>
            <span *ngIf="!selectedOption">{{'assistants.details.share.select' | translate }}</span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              [(ngModel)]="searchTerm"
            />
            <li *ngFor="let option of filteredOptions()" style="cursor: pointer">
              <a
                class="dropdown-item"
                (click)="selectOption(option)"
              >{{ option.full_name }}</a
              >
            </li>
          </ul>
        </div>
        <select class="form-select" *ngIf="selectedOption" aria-label="Default select example" [(ngModel)]="selectedAccessLevel">
          <option value="99" selected>{{'assistants.details.share.accessLevel.placeholder' | translate}}</option>
          <option value="0">{{"assistants.details.share.accessLevel.owner" | translate}}</option>
          <option value="1">{{"assistants.details.share.accessLevel.edit" | translate}}</option>
          <option value="2">{{"assistants.details.share.accessLevel.view" | translate}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" style="background-color: #5DA9D7; border-color: #5DA9D7; color: white; min-width: 100px" *ngIf="selectedOption && selectedAccessLevel" (click)="addRelation()">
      {{ "assistants.details.share.save" | translate }}
    </div>
  </div>
</div>
